import React from "react";
import "./common.css";
import { Link } from "react-router-dom";
import labels from "../../../labels.json";

const Breadcrumbs = (props) => {
  const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

  return (
    <div className="container">
      <div className="row w-100">
        <div className="col-md-12 col-md-12 col-sm-12 mt-5 mx-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/coach/my-courses">
                  {lbl ? lbl.myCourse : "My course"}
                </Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to="#">{lbl ? lbl.courseDetails : "Course details"}</Link>
              </li>
            </ol>
          </nav>
          {/* <ul className='breadcrumb-custom'>
            <li>
              <a href='!#'>My course</a>
            </li>
            <li>
              <a href='!#' className='active'>
                <strong>Course details</strong>
              </a>
            </li>
          </ul> */}
        </div>
      </div>
    </div>
  );
};

Breadcrumbs.propTypes = {};

export default Breadcrumbs;
