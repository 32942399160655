import React, { Fragment, useState } from "react";
import { PulseLoader } from "react-spinners";
import PaymentModal from "../../CommonComponents/PaymentPopUpModal/PaymentModal";
import "./courseDetails.css";
import labels from "../../../labels.json";
import { Modal, ModalBody } from "reactstrap";
import { cancelBooking } from "../../../actions/studentCourseAction";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const BuyCourseComponent = (props) => {
    const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

    const { coachPackage, course_id, boughtCourse, message, cancelBooking, getStudentCourse } = props;
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [package_id, setpackage_id] = useState(null);
    const [enrollModal, setEnrollModal] = useState({ open: false, id: '' });

    // ----------- close password modal -----------
    const savePaymentModal = () => {
        setShowPaymentModal(false);
    };

    const onBuyNowClick = (_id) => {
        setShowPaymentModal(true);
        setpackage_id(_id);
    };

    const onCancelClick = (id) => {
        setEnrollModal({ open: true, id })
    }

    const onHideEnrollModal = () => {
        setEnrollModal({ open: false, id: '' })
    }

    const onConfirmCancelEnroll = () => {
        if (enrollModal.id) {
            cancelBooking({ _id: enrollModal.id })
            getStudentCourse()
            onHideEnrollModal()
        }
    }

    return (
        <Fragment>
            <div className="row w-100 mt-4 px-2 px-md-5">
                {message && message !== "" ? (
                    <div className="col-xl-12 col-lg-12 col-md-12 mt-5">
                        <div className="col tg-display">{message}</div>
                    </div>
                ) : coachPackage !== undefined && coachPackage.length > 0 ? (
                    coachPackage.map((class_mode, i) => {
                        return (
                            <div
                                className="col-md-4  text-center mt-1 mt-sm-4 mt-md-0 mb-4"
                                key={i}
                            >
                                <div className="package-details  position-right">
                                    <p className="package-title">{class_mode.title}</p>
                                    <div className="title color_gray">
                                        {lbl ? lbl.classes : "Classes"}
                                    </div>
                                    <div className="details color_gray">
                                        {class_mode.no_of_classes}
                                    </div>
                                    <hr className="divider" />
                                    <div className="title color_gray mt-3">
                                        {lbl ? lbl.leaves : "Leaves"}
                                    </div>
                                    <div className="details color_gray">
                                        {class_mode.no_of_leaves}
                                    </div>
                                    <hr className="divider" />
                                    <div className="title color_gray mt-4">
                                        {lbl ? lbl.feesAmount : "Fees Amount"}
                                    </div>
                                    <div className="details color_gray">
                                        {class_mode.amount}
                                        {lbl ? lbl.hkd : "HKD"}
                                    </div>
                                    <hr className="divider" />
                                    <div className="title color_gray mt-4">
                                        {lbl ? lbl.freeCertificate : "免費官方證書"}
                                    </div>
                                    <div className="details color_gray">
                                        {class_mode.certificate === 1 ? (
                                            <span>{lbl ? lbl.yes : "Yes"}</span>
                                        ) : (
                                            <span>{lbl ? lbl.no : "No"}</span>
                                        )}
                                    </div>
                                    <hr className="divider" />
                                    <div className="title color_gray mt-4">
                                        {lbl ? lbl.freeReport : "免費官方詳細評核報告"}
                                    </div>
                                    <div className="details color_gray">
                                        {class_mode.report === 1 ? (
                                            <span>{lbl ? lbl.yes : "Yes"}</span>
                                        ) : (
                                            <span>{lbl ? lbl.no : "No"}</span>
                                        )}
                                    </div>

                                    {
                                        // boughtCourse[0]?.package == class_mode._id
                                        boughtCourse.find(item => class_mode._id == item?.package) ? (
                                            <div>
                                                <button
                                                    style={{ marginRight: '20px' }}
                                                    className="btn blue_fill_btn btn-sm mt-3 mb-4"
                                                    onClick={(e) => onBuyNowClick(class_mode._id)}
                                                >
                                                    {lbl ? lbl.buyAgain : "Buy again"}
                                                </button>
                                                {
                                                    boughtCourse[boughtCourse.findIndex(item => class_mode._id == item?.package)].enrolled !== 2 ?
                                                    <button
                                                        style={{ color: 'red' }}
                                                        className="btn  btn-sm mt-3 mb-4"
                                                        onClick={(e) => onCancelClick(boughtCourse[boughtCourse.findIndex(item => class_mode._id == item?.package)]._id)}
                                                    >
                                                        {lbl ? lbl.cancelEnroll : "Cancel enrollment"}
                                                    </button>
                                                    : null
                                                }
                                            </div>
                                        ) : (
                                            <button
                                                className="btn blue_fill_btn  buy-now-btn btn-sm mt-3 mb-4"
                                                onClick={(e) => onBuyNowClick(class_mode._id)}
                                            >
                                                {lbl ? lbl.buyNow : "Buy Now"}
                                            </button>
                                        )}
                                </div>
                            </div>
                        );
                    })
                ) : coachPackage && coachPackage.length === 0 ? (
                    <div className="col-xl-12 col-lg-12 col-md-12 mt-5">
                        <div className="col tg-display">
                            {lbl ? lbl.noPackageFound : "No package found"}
                        </div>
                    </div>
                ) : (
                    <div className="col-xl-12 col-lg-12 col-md-12 mt-5">
                        <div className="col tg-display">
                            <PulseLoader color="#005da1" size="15" />
                        </div>
                    </div>
                )}
            </div>
            <div className="payment_modal">
                <PaymentModal
                    showModal={showPaymentModal}
                    closeModal={savePaymentModal}
                    course_id={course_id}
                    package_id={package_id}
                />
            </div>

            <Modal
                isOpen={enrollModal.open}
                style={{ marginTop: 300 }}
            >
                <ModalBody>
                    <span className="reset_title">
                        <strong>{lbl ? lbl.cancelEnroll : "Cancel enrollment"}</strong>
                    </span>
                    <span className="close_btn" onClick={onHideEnrollModal}>
                        x
                    </span>
                    <div className="w-100 tg_payment">
                        {lbl ? lbl.sureCancelEnrollment : "Are you sure to cancel your enrollment?"}
                        <div className="mt-3">
                            <button
                                style={{ marginRight: '20px' }}
                                className="btn  btn-sm mt-3 mb-4"
                                onClick={onHideEnrollModal}
                            >
                                {lbl ? lbl.cancelled : "Cancel"}
                            </button>
                            <button
                                className="btn blue_fill_btn btn-sm mt-3 mb-4"
                                onClick={onConfirmCancelEnroll}
                            >
                                {lbl ? lbl.ok : "OK"}
                            </button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    );
};

BuyCourseComponent.propTypes = {
    cancelBooking: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, { cancelBooking })(
    BuyCourseComponent
);

