import React, { Fragment } from "react";
import { CardElement } from "@stripe/react-stripe-js";

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: "#000000",
      fontSize: "16px",
      fontFamily: "sans-serif",
      fontSmoothing: "antialiased",
      iconColor: "#000000",
      lineHeight: "45px",
      border: "1px solid black",
      "::placeholder": {
        color: "#000000",
      },
    },
    invalid: {
      color: "#e5424d",
      ":focus": {
        color: "#303238",
      },
    },
  },
};

const CardSection = () => {
  return (
    <Fragment>
      <label className="card_box">
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </label>
    </Fragment>
  );
};

export default CardSection;
