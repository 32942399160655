import React, { Fragment, useState } from "react";
import { checkEmailValidation } from "../../../Helpers/Validation";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { forgotPassword } from "../../../actions/authActions";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import ToastifyContainer from "../../../components/Toastify/ToastContainer";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import InputField from "../../../components/InputField/InputField";
import labels from "../../../labels.json";

const StudentForgotPasswordInput = (props) => {
  const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

  const { forgotPassword, auth } = props;
  const [email, setEmail] = useState("");
  const [error_email, seterror_email] = useState("");
  const [validationFlag, setValidationFlag] = useState(true);
  const [loader, setLoader] = useState(false);

  const onHandleChange = (e) => {
    setEmail(e.target.value);
  };

  const sendEmailOnClick = (e) => {
    let errEmail = null;
    let emailLbl = "Email ";
    if (lbl && lbl.oEmail) emailLbl = lbl.oEmail;

    errEmail = checkEmailValidation(email, emailLbl);
    if (errEmail) {
      setValidationFlag(false);
      seterror_email(errEmail);
    } else {
      seterror_email("");
    }
    const email_entered = {
      email: email,
    };
    if (validationFlag === true && email !== "") {
      setLoader(true);
      forgotPassword(email_entered)
        .then((res) => {
          setEmail("");
          toast.success(res.data.message);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          setValidationFlag(false);
          seterror_email(err.response.data.message);
          toast.error(err.response.data.message);
        });
    } else {
      setValidationFlag(true);
    }
  };

  const sendEmailOnEnter = (e) => {
    if (e.key === "Enter") {
      sendEmailOnClick();
    }
  };

  const { isAuthenticated, loading, user } = auth;

  if (isAuthenticated && !loading && user.role === 1) {
    return <Redirect to="/" />;
  }
  if (isAuthenticated && !loading && user.role === 2) {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      <div className="col-lg-5 col-md-6 mt-3">
        <div className="row">
          <ToastifyContainer />
          <div className="col-md-12">
            <h2 className="main_title">
              {lbl ? lbl.forgotPassword : "Forgot password?"}
            </h2>
            <p className="title_para">
              {lbl
                ? lbl.forgotPasswordDesc
                : " It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."}
            </p>
          </div>
          <div className="col-md-12 mt-4">
            <h3 className="secnd_title" />
          </div>
          <div className="col-md-12 mt-4">
            <InputField
              inputType="email"
              inputName="email"
              placeHolder={lbl ? lbl.enterEmail : "Enter your email"}
              errorValue={error_email}
              disable={loader}
              onInputChange={(e) => onHandleChange(e)}
              value={email}
              keyDown={(e) => sendEmailOnEnter(e)}
            />
          </div>
          <div className="col-md-12 mt-4">
            <ButtonComponent
              loading={loader}
              title={lbl ? lbl.submit : "Submit"}
              onBtnClick={(e) => sendEmailOnClick(e)}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

StudentForgotPasswordInput.propTypes = {
  auth: PropTypes.object.isRequired,
  forgotPassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  forgotPassword,
})(StudentForgotPasswordInput);
