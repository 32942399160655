import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { checkRequiredValidationWithMinMax } from "../../../Helpers/Validation";
import { toast } from "react-toastify";
import ToastifyContainer from "../../../components/Toastify/ToastContainer";
import {
  resetPassword,
  verifyResetPassword,
} from "../../../actions/authActions";
import InputField from "../../../components/InputField/InputField";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import labels from "../../../labels.json";

const ResetPasswordInput = (props) => {
  const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

  const history = useHistory();
  const { code } = useParams();
  const { resetPassword, verifyResetPassword, auth } = props;
  const [verify, setVerify] = useState(null);
  const [message, setMessage] = useState(null);
  const [loader, setLoader] = useState(false);
  const [resetPasswordData, setResetPasswordData] = useState({
    new_password: "",
    confirm_password: "",
  });

  useEffect(() => {
    verifyResetPassword({ reset_password_code: code })
      .then((res) => {
        setMessage(res.data.message);
        setVerify(true);
      })
      .catch((err) => {
        setMessage(err.response.data.message);
        setVerify(false);
      });
  }, [verifyResetPassword, code]);

  const [errors, setErrors] = useState({
    error_new_password: "",
    error_confirm_password: "",
  });

  const onHandleChange = (e) => {
    setResetPasswordData({
      ...resetPasswordData,
      [e.target.name]: e.target.value,
    });
  };
  const onResetPassword = (e) => {
    const { new_password, confirm_password } = resetPasswordData;
    let errPassword = null;
    let errConfPassword = null;
    let validationFlag = true;

    let newPasswordLbl = "New password ";
    let confPasswordLbl = "Confirm password ";
    let errConfPasswordLbl = "Confirm password doesn't match with password ";

    if (lbl && lbl.newPassword) newPasswordLbl = lbl.newPassword;
    if (lbl && lbl.confPassword) confPasswordLbl = lbl.confPassword;
    if (lbl && lbl.errConfPasswordLbl)
      errConfPasswordLbl = lbl.errConfPasswordLbl;

    errPassword = checkRequiredValidationWithMinMax(
      new_password,
      newPasswordLbl,
      6,
      12
    );
    errConfPassword = checkRequiredValidationWithMinMax(
      confirm_password,
      confPasswordLbl,
      6,
      12
    );

    if (new_password !== confirm_password) {
      errConfPassword = errConfPasswordLbl;
    }
    if (errPassword || errConfPassword) {
      validationFlag = false;
      setErrors({
        ...errors,
        error_new_password: errPassword,
        error_confirm_password: errConfPassword,
      });
    } else {
      validationFlag = true;
      setErrors({
        ...errors,
        error_new_password: "",
        error_confirm_password: errConfPassword,
      });
    }

    if (validationFlag) {
      setLoader(true);
      const resetObj = {
        reset_password_code: code,
        password: new_password,
        confirm_password: confirm_password,
      };
      resetPassword(resetObj)
        .then((res) => {
          toast.success(res.data.message);
          setLoader(false);
          setTimeout(() => {
            history.push("/student/login");
          }, 1500);
        })
        .catch((error) => {
          setLoader(false);
          toast.error(error.response.data.message);
        });
    }
  };

  const { isAuthenticated } = auth;
  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      {verify === true ? (
        <div className="col-lg-5 col-md-6 mt-3">
          <div className="row">
            <ToastifyContainer />
            <div className="col-md-12">
              <h2 className="main_title">
                {lbl ? lbl.resetYourPassword : "Reset your password"}
              </h2>
              <p className="title_para">
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p>
            </div>
            <div className="col-md-12 mt-4">
              <h3 className="secnd_title" />
            </div>
            <div className="col-md-12 mt-4">
              <InputField
                inputType="password"
                inputName="new_password"
                placeHolder={lbl ? lbl.enterNewPassword : "Enter New password"}
                errorValue={errors.error_new_password}
                disable={loader}
                onInputChange={(e) => onHandleChange(e)}
                value={resetPasswordData.new_password}
              />
            </div>
            <div className="col-md-12 mt-4">
              <InputField
                inputType="password"
                inputName="confirm_password"
                placeHolder={lbl ? lbl.confNewPassword : "Confirm New password"}
                errorValue={errors.error_confirm_password}
                disable={loader}
                onInputChange={(e) => onHandleChange(e)}
                value={resetPasswordData.confirm_password}
              />
            </div>
            <div className="col-md-12 mt-4">
              <ButtonComponent
                loading={loader}
                title={lbl ? lbl.submit : "Submit"}
                onBtnClick={(e) => onResetPassword(e)}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="col-lg-5 col-md-6 mt-3 tg-display">
          <div className="col-md-12">
            <p className="title_para">Password reset {message}</p>
          </div>
        </div>
      )}
    </Fragment>
  );
};

ResetPasswordInput.propTypes = {
  auth: PropTypes.object.isRequired,
  resetPassword: PropTypes.func.isRequired,
  verifyResetPassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({ auth: state.auth });

export default connect(mapStateToProps, {
  resetPassword,
  verifyResetPassword,
})(ResetPasswordInput);
