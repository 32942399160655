export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_LOADING_SUCCESS = "USER_LOADING_SUCCESS";
export const USER_LOADING_ERROR = "USER_LOADING_ERROR";
export const USER_LOGOUT = "USER_LOGOUT";

// -------- Get Payment History  --------
export const GET_PAYMENT_HISTORY = "GET_PAYMENT_HISTORY";

// -------- Get My Enrollments  --------
export const GET_MY_ENROLLMENTS = "GET_MY_ENROLLMENTS";
export const GET_MY_ATTENDENCE = "GET_MY_ATTENDENCE";

// -------- Leaves  --------
export const GET_MY_LEAVE_HISTORY = "GET_MY_LEAVE_HISTORY";
export const GET_AVAILABLE_LEAVE_BALANCE = "GET_AVAILABLE_LEAVE_BALANCE";
export const GET_CLASSES_FROM_COURSE = "GET_CLASSES_FROM_COURSE";

// --------- Gifts ----------
export const COURSE_STUDENT_GIFT = "COURSE_STUDENT_GIFT";
export const GET_COACH_GIFTS = "GET_COACH_GIFTS";

// -------- course student --------
export const ALL_COURSE_LIST = "ALL_COURSE_LIST";
export const GET_ALL_COURSES = "GET_ALL_COURSES";
export const ALL_COURSE_DETAILS = "ALL_COURSE_DETAILS";
export const CLASS_SCHEDULED = "CLASS_SCHEDULED";
export const CLASS_TIME_SCHEDULE = "CLASS_TIME_SCHEDULE";
export const COURSE_STUDENT_REQ_LIST = "COURSE_STUDENT_REQ_LIST";

// --------- Notifications ----------
export const GET_NOTIFICATION_LIST = "GET_NOTIFICATION_LIST";
