import React, { Fragment, useEffect } from "react";
import DefaultHeader from "../../../DefaultLayout/DefaultHeader";
import LoginInput from "../../CommonComponents/AuthenticationComponents/LoginInput";
import DefaultFooter from "../../../DefaultLayout/DefaultFooter";
import LeftSideImage from "../../CommonComponents/AuthenticationComponents/LeftSideImage";
import { scrollToTop } from "../../../Helpers/ScrollToTop";

const CoachLogin = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <Fragment>
      <DefaultHeader />
      <section className="stdnt_login_registr_main sec_mar_top">
        <div className="container">
          <div className="row">
            <LeftSideImage />
            <LoginInput userRole={2} />
          </div>
        </div>
      </section>
      <DefaultFooter />
    </Fragment>
  );
};
export default CoachLogin;
