import React, { Fragment, useEffect, useRef, useState } from "react";
import noProfile from "../../../assets/images/no-profile.png";
import editicon from "../../../assets/images/edit.png";
import ResetPasswordModal from "../../coach/CommonComponents/ResetPasswordModal";
import "./Profile.css";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import ToastifyContainer from "../../../components/Toastify/ToastContainer";
import InputField from "../../../components/InputField/InputField";
import { loadUser, updateUserProfile } from "../../../actions/authActions";
import {
  checkEmailValidation,
  checkEmptyValidation,
  checkRequiredValidationWithMinMax,
} from "../../../Helpers/Validation";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import classnames from "classnames";
import { scrollToTop } from "../../../Helpers/ScrollToTop";
import UpdateMobileModal from "../../../components/UpdateMobileModal/UpdateMobileModal";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import { findDOMNode } from "react-dom";
import labels from "../../../labels.json";

const UpdateProfile = (routerProps) => {
  const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

  const { auth, updateUserProfile, loadUser } = routerProps;

  const history = useHistory();

  const { user } = auth;

  const updateRef = useRef(null);
  // --------- Common input field for both Student and coach ---------
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [profile_pic, setProfilePic] = useState("");
  const [profile_pic_preview, setProfilePicPreview] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showUpdateMobileModal, setShowUpdateMobileModal] = useState(false);
  const [regexp] = useState(/^[0-9\b]+$/);
  const [loader, setLoader] = useState(false);

  // --------- Input fields for Student ---------
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [emergency_name, setEmergencyName] = useState("");
  const [emergency_email, setEmergencyEmail] = useState("");
  const [emergency_phone_number, setEmergencyPhoneNumber] = useState("");
  const [uniform_size, setUniformSize] = useState("");
  const [ag_blank, setAg_blank] = useState("");

  // --------- Input fields for Coach ---------
  const [name, setName] = useState("");

  // --------- Error fields ---------
  const [error_first_name, setErrorFirstName] = useState("");
  const [error_last_name, setErrorLastName] = useState("");
  const [error_ag_blank, setErrorAgblank] = useState("");
  const [error_name, setErrorName] = useState("");
  const [error_gender, setErrorGender] = useState("");
  const [error_profile_pic, setErrorProfilePic] = useState("");
  const [error_emergency_name, setErrorEmergencyName] = useState("");
  const [error_emergency_phone, setErrorEmergencyPhone] = useState("");
  const [error_emergency_email, setErrorEmergencyEmail] = useState("");
  const [error_uniform_size, setErrorUniformSize] = useState("");

  useEffect(() => {
    loadUserDetails();
    scrollToTop();
  }, []);

  useEffect(() => {
    if (user.phone_number !== phone_number) {
      setPhoneNumber(user.phone_number);
    }
  }, [phone_number, user.phone_number]);

  // ------- Load Profile Details to all fields -------
  const loadUserDetails = () => {
    if (auth.user) {
      setEmail(user.email);
      setPhoneNumber(user.phone_number);
      setProfilePic(user.profile_pic ? user.profile_pic : "");
      setProfilePicPreview(user.profile_pic);

      if (user.role === 1) {
        setFirstName(user.first_name);
        setLastName(user.last_name);
        setGender(user.gender === 0 ? "" : user.gender);
        setAg_blank(user.ag_blank);
        setEmergencyName(user.emergency_name);
        setEmergencyPhoneNumber(user.emergency_phone_number);
        setEmergencyEmail(user.emergency_email);
        setUniformSize(user.size);
      }
      if (user.role === 2) {
        setName(user.name);
      }
    }
  };

  // ---------- open Image Upload ------------
  const openImageUpload = (e, type) => {
    document.getElementById("photo").click();
  };

  // ---------- Mobile Input change ------------
  const onMobileNumberChange = (e, type) => {
    let mobileNo = e.target.value;

    // if value is not blank, then test the regex
    if (mobileNo === "" || regexp.test(mobileNo)) {
      if (type === 1) {
        setPhoneNumber(mobileNo);
      } else {
        setEmergencyPhoneNumber(mobileNo);
      }
    }
  };

  // ----------- Handle image change -----------
  const onFileChange = async (e) => {
    let validateImage = true;
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const imageSize = e.target.files[0].size / 1024 / 1024;

      if (!file) {
        validateImage = false;
        let selectLbl = "Please select image.";
        if (lbl && lbl.selectImage) selectLbl = lbl.selectImage;
        setErrorProfilePic(selectLbl);
      } else if (!file.name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/)) {
        validateImage = false;
        let selectValidLbl = "Please select valid image.";
        if (lbl && lbl.selectValidImage) selectValidLbl = lbl.selectValidImage;
        setErrorProfilePic(selectValidLbl);
      } else if (imageSize > 2) {
        validateImage = false;
        let selectSizeLbl = "Please select image size less then 2 MB.";
        if (lbl && lbl.selectImageLessThan2)
          selectSizeLbl = lbl.selectImageLessThan2;

        setErrorProfilePic(selectSizeLbl);
      } else {
        validateImage = true;

        const preview = URL.createObjectURL(file);
        setProfilePicPreview(preview);
        setProfilePic(file);
        setErrorProfilePic("");
      }
    }
  };

  // ----------- close password modal -----------
  const savePasswordModal = () => {
    setShowResetPasswordModal(false);
  };

  // ----------- close password modal -----------
  const closeUpdateMobileModal = () => {
    setShowUpdateMobileModal(false);
    loadUser().then((res) => {
      setPhoneNumber(res.data.user.phone_number);
    });
  };

  // ----------- Save student profile function role :1  -----------
  const saveStudentProfile = (e) => {
    let validationFlag = true;
    let errProfilePic = "";
    let errFName = "";
    let errLName = "";
    let errGender = "";
    let errAgBlank = "";
    let errEmrName = "";
    let errEmrEmail = "";
    let errEmrPhone = "";
    let errUniformSize = "";

    let fNameLbl = "First name";
    let lNameLbl = "Last name";
    let profileLbl = "Profile Pic ";
    let emrNameLbl = "Emergency name ";
    let emrEmailLbl = "Emergency email ";
    let emrPhoneLbl = "Emergency phone number ";
    let genderLbl = "Gender ";
    let uniformLbl = "Uniform Size ";
    let shirtLbl = "Shirt name ";

    if (lbl && lbl.fName) fNameLbl = lbl.fName;
    if (lbl && lbl.lName) lNameLbl = lbl.lName;
    if (lbl && lbl.profilePic) profileLbl = lbl.profilePic;
    if (lbl && lbl.emergencyName) emrNameLbl = lbl.emergencyName;
    if (lbl && lbl.emergencyEmail) emrEmailLbl = lbl.emergencyEmail;
    if (lbl && lbl.emergencyPhone) emrPhoneLbl = lbl.emergencyPhone;
    if (lbl && lbl.gender) genderLbl = lbl.gender;
    if (lbl && lbl.uniformSize) uniformLbl = lbl.uniformSize;
    if (lbl && lbl.shirtName) shirtLbl = lbl.shirtName;

    errProfilePic = checkEmptyValidation(profile_pic, profileLbl);
    errFName = checkRequiredValidationWithMinMax(first_name, fNameLbl, 1, 1000);
    errLName = checkRequiredValidationWithMinMax(last_name, lNameLbl, 1, 1000);
    errEmrName = checkRequiredValidationWithMinMax(
      emergency_name,
      emrNameLbl,
      1,
      1000
    );
    errEmrEmail = checkEmailValidation(emergency_email, emrEmailLbl);
    errEmrPhone = checkRequiredValidationWithMinMax(
      emergency_phone_number,
      emrPhoneLbl,
      8,
      11
    );
    errGender = checkEmptyValidation(gender, genderLbl);
    errAgBlank = checkEmptyValidation(ag_blank, shirtLbl);
    errUniformSize = checkEmptyValidation(uniform_size, uniformLbl);

    console.log(
      "🚀 ~ file: UpdateProfile.js ~ line 228 ~ saveStudentProfile ~ errUniformSize",
      errUniformSize
    );
    if (
      errUniformSize === "Uniform size is required" ||
      errUniformSize === "球衣尺寸需要"
    ) {
      errUniformSize = lbl.selectUniform;
    }
    if (
      errFName ||
      errLName ||
      errGender ||
      errAgBlank ||
      errProfilePic ||
      errEmrName ||
      errEmrEmail ||
      errEmrPhone ||
      errUniformSize
    ) {
      validationFlag = false;
      setErrorFirstName(errFName);
      setErrorLastName(errLName);
      setErrorGender(errGender);
      setErrorAgblank(errAgBlank);
      setErrorProfilePic(errProfilePic);
      setErrorEmergencyName(errEmrName);
      setErrorEmergencyPhone(errEmrPhone);
      setErrorEmergencyEmail(errEmrEmail);
      setErrorUniformSize(errUniformSize);
    } else {
      validationFlag = true;
      clearAllErrorFields();
    }

    if (validationFlag) {
      setLoader(true);
      const studentData = new FormData();
      studentData.append("first_name", first_name);
      studentData.append("last_name", last_name);
      studentData.append("gender", gender);
      studentData.append("ag_blank", ag_blank);
      studentData.append("emergency_name", emergency_name);
      studentData.append("emergency_email", emergency_email);
      studentData.append("emergency_phone_number", emergency_phone_number);
      studentData.append("role", user.role);
      studentData.append("size", uniform_size);
      if (typeof profile_pic === "object") {
        studentData.append("profile_pic", profile_pic);
      }

      updateUserProfile(user._id, studentData)
        .then((res) => {
          loadUser();

          toast.success(res.data.message);

          setLoader(false);

          setTimeout(() => {
            history.push("/student/my-enrollments");
          }, 4000);
        })
        .catch((error) => {
          setLoader(false);

          toast.error(error.response.data.message);
        });
    }
  };

  // ----------- Save coach profile function role : 2  -----------
  const saveCoachProfile = (e) => {
    let validationFlag = true;
    let errProfilePic = "";
    let errPhone = "";
    let errName = "";

    let nameLbl = "Name";
    let mobileLbl = "Phone number";
    let profileLbl = "Profile Pic ";

    if (lbl && lbl.profilePic) profileLbl = lbl.profilePic;
    if (lbl && lbl.name) nameLbl = lbl.name;
    if (lbl && lbl.mobileNumber) mobileLbl = lbl.mobileNumber;

    errProfilePic = checkEmptyValidation(profile_pic, profileLbl);
    errPhone = checkRequiredValidationWithMinMax(phone_number, mobileLbl, 11, 11);
    errName = checkRequiredValidationWithMinMax(name, nameLbl, 1, 25);

    if (errName || errPhone || errProfilePic) {
      validationFlag = false;

      setErrorName(errName);
      setErrorProfilePic(errProfilePic);
    } else {
      validationFlag = true;

      clearAllErrorFields();
    }

    if (validationFlag) {
      setLoader(true);

      const studentData = new FormData();

      studentData.append("name", name);
      studentData.append("phone_number", phone_number);
      studentData.append("role", user.role);
      studentData.append("ptype", user.type);
      studentData.append("amount", user.amount);
      if (typeof profile_pic === "object") {
        studentData.append("profile_pic", profile_pic);
      }

      updateUserProfile(user._id, studentData)
        .then((res) => {
          loadUser();

          toast.success(res.data.message);

          setLoader(false);

          setTimeout(() => {
            if (user.role === 1) {
              history.push("/student/my-enrollments");
            } else if (user.role === 2) {
              history.push("/coach/my-courses");
            }
          }, 4000);
        })
        .catch((error) => {
          setLoader(false);

          toast.error(error.response.data.message);
        });
    }
  };

  // --------------- function for clear all error fields ---------------
  const clearAllErrorFields = (e) => {
    setErrorFirstName("");
    setErrorLastName("");
    setErrorName("");
    setErrorGender("");
    setErrorAgblank("");
    setErrorProfilePic("");
    setErrorEmergencyName("");
    setErrorEmergencyPhone("");
    setErrorEmergencyEmail("");
    setErrorUniformSize("");
  };

  // ----------- render student emergency contact and uniform size -------------
  const renderStudentFields = (e) => {
    return (
      <Fragment>
        <div className="mt-3  w-100 float-left">
          <p className="title_para mb-3">
            {lbl ? lbl.emergenContact : "Emergency Contact"}
          </p>
        </div>
        <div className="mt-4 w-100 float-left">
          <InputField
            inputType="text"
            placeHolder={
              lbl ? lbl.emergencyName : "Emergency contact person's name "
            }
            errorValue={error_emergency_name}
            disable={loader}
            onInputChange={(e) => setEmergencyName(e.target.value)}
            value={emergency_name}
          />
        </div>
        <div className="mt-4 w-100 float-left">
          <InputField
            inputType="text"
            placeHolder={
              lbl ? lbl.emergencyPhone : "Emergency contact phone number "
            }
            errorValue={error_emergency_phone}
            disable={loader}
            onInputChange={(e) => onMobileNumberChange(e, 2)}
            value={emergency_phone_number}
          />
        </div>
        <div className="mt-4 w-100 float-left">
          <InputField
            inputType="text"
            placeHolder={lbl ? lbl.emergencyEmail : "Emergency contact email "}
            errorValue={error_emergency_email}
            disable={loader}
            onInputChange={(e) => setEmergencyEmail(e.target.value)}
            value={emergency_email}
          />
        </div>
        <div className="mt-4 w-100 float-left">
          <select
            className={classnames(
              "form-select ",
              { invalid: error_uniform_size.length > 0 },
              {
                "valid update_profile_select": error_uniform_size.length === 0,
              }
            )}
            disabled={loader}
            onChange={(e) => setUniformSize(e.target.value)}
          >
            {uniform_size !== "" ? (
              <option value={uniform_size}>{uniform_size}</option>
            ) : (
              <option value="">{lbl ? lbl.select : "Select"}</option>
            )}
            {uniform_size !== "XS (Kids)" && (
              <option value="XS (Kids)">{lbl ? lbl.xsK : "XS (Kids)"}</option>
            )}
            {uniform_size !== "S (Kids)" && (
              <option value="S (Kids)">{lbl ? lbl.sK : "S (Kids)"}</option>
            )}
            {uniform_size !== "M (Kids)" && (
              <option value="M (Kids)">{lbl ? lbl.mK : "M (Kids)"}</option>
            )}
            {uniform_size !== "L (Kids)" && (
              <option value="L (Kids)">{lbl ? lbl.lK : "L (Kids)"}</option>
            )}
            {uniform_size !== "XL (Kids)" && (
              <option value="XL (Kids)">{lbl ? lbl.xlK : "XL (Kids)"}</option>
            )}

            {uniform_size !== "XS (Youth)" && (
              <option value="XS (Youth)">{lbl ? lbl.xsY : "XS (Youth)"}</option>
            )}
            {uniform_size !== "S (Youth)" && (
              <option value="S (Youth)">{lbl ? lbl.sY : "S (Youth)"}</option>
            )}
            {uniform_size !== "M (Youth)" && (
              <option value="M (Youth)">{lbl ? lbl.mY : "M (Youth)"}</option>
            )}
            {uniform_size !== "L (Youth)" && (
              <option value="L (Youth)">{lbl ? lbl.lY : "L (Youth)"}</option>
            )}
            {uniform_size !== "XL (Youth)" && (
              <option value="XL (Youth)">{lbl ? lbl.xlY : "XL (Youth)"}</option>
            )}

            {uniform_size !== "XS (Adult)" && (
              <option value="XS (Adult)">{lbl ? lbl.xsA : "XS (Adult)"}</option>
            )}
            {uniform_size !== "S (Adult)" && (
              <option value="S (Adult)">{lbl ? lbl.sA : "S (Adult)"}</option>
            )}
            {uniform_size !== "M (Adult)" && (
              <option value="M (Adult)">{lbl ? lbl.mA : "M (Adult)"}</option>
            )}
            {uniform_size !== "L (Adult)" && (
              <option value="L (Adult)">{lbl ? lbl.lA : "L (Adult)"}</option>
            )}
            {uniform_size !== "XL (Adult)" && (
              <option value="XL (Adult)">{lbl ? lbl.xlA : "XL (Adult)"}</option>
            )}
          </select>
          {error_uniform_size ? (
            <span className="invalid-text ">{error_uniform_size}</span>
          ) : null}
        </div>
      </Fragment>
    );
  };

  // ----------- render student name and gender fields -------------
  const renderStudentNameFields = (e) => {
    return (
      <Fragment>
        <div className="mt-4 w-100 float-left">
          <InputField
            inputType="text"
            placeHolder={lbl ? lbl.fName : "First name"}
            errorValue={error_first_name}
            disable={loader}
            onInputChange={(e) => setFirstName(e.target.value)}
            value={first_name}
          />
        </div>
        <div className="mt-4 w-100 float-left">
          <InputField
            inputType="text"
            placeHolder={lbl ? lbl.lName : "Last name"}
            errorValue={error_last_name}
            disable={loader}
            onInputChange={(e) => setLastName(e.target.value)}
            value={last_name}
          />
        </div>
        <div className="mt-4 w-100 float-left">
          <select
            className={classnames(
              "form-select ",
              { invalid: error_gender.length > 0 },
              {
                "valid update_profile_select": error_gender.length === 0,
              }
            )}
            onChange={(e) => setGender(e.target.value)}
            disabled={loader}
          >
            {gender === 1 && (
              <option value={1}>{lbl ? lbl.male : "Male"}</option>
            )}
            {gender === 2 && (
              <option value={2}>{lbl ? lbl.female : "Female"}</option>
            )}
            {!gender && (
              <option value={2}>
                {lbl ? lbl.selectGender : "Select Gender"}
              </option>
            )}
            {gender !== 1 && (
              <option value={1}>{lbl ? lbl.male : "Male"}</option>
            )}
            {gender !== 2 && (
              <option value={2}>{lbl ? lbl.female : "Female"}</option>
            )}
          </select>
          {error_gender ? (
            <span className="invalid-text ">{error_gender}</span>
          ) : null}
        </div>
        <div className="mt-4 w-100 float-left">
          <InputField
            inputType="text"
            placeHolder={lbl ? lbl.shirtName : "Shirt name"}
            errorValue={error_ag_blank}
            disable={loader}
            onInputChange={(e) => setAg_blank(e.target.value)}
            value={ag_blank}
          />
        </div>
      </Fragment>
    );
  };

  const renderCoachFields = (e) => {
    return (
      <Fragment>
        <hr className="tg_border" />
        <div className="row w-100">
          <div className="col-md-12">
            <div className="row tg_mart">
              <div className="peers_common_user_profile_txt mt-2 cust_pad_w">
                <span className="gnrl_para m-0">
                  {lbl ? lbl.paymentType : "Payment Type"}
                  <span className="tg_span colon_space ms-2 ps-1">:</span>
                  {auth.user && user.type === 1 ? (
                    <span>{lbl ? lbl.hourly : "Hourly"}</span>
                  ) : auth.user && user.type === 2 ? (
                    <span>{lbl ? lbl.class : "Class"}</span>
                  ) : (
                    <span>{lbl ? lbl.notfound : "Not found"}</span>
                  )}
                </span>
              </div>
              <div className="peers_common_user_profile_txt cust_pad_w">
                <span className="gnrl_para m-0">
                  {lbl ? lbl.paymentAmount : "Payment Amount"}
                  <span className="tg_span">:</span>
                  {auth.user && user.amount ? (
                    <span>
                      {user.amount} {lbl ? lbl.hkdPer : "HKD per"}{" "}
                      {user.type === 1 ? (
                        <span>{lbl ? lbl.hour : "Hour"}</span>
                      ) : auth.user && user.type === 2 ? (
                        <span>{lbl ? lbl.class : "Class"}</span>
                      ) : null}
                    </span>
                  ) : (
                    <span>{lbl ? lbl.notFound : "Not found"}</span>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 w-100 float-left">
          <InputField
            inputType="text"
            placeHolder={lbl ? lbl.oName : "Name"}
            errorValue={error_name}
            disable={loader}
            onInputChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <section className="edit_student_profile_main ">
        <ToastifyContainer />
        <div className="container firefox-display">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8 mt-5 ">
              <div className="row w-100  px-0 mx-0">
                <div className="col-md-12">
                  <h2 className="main_title">
                    {lbl ? lbl.updateProfileDetails : "Update profile details"}
                  </h2>
                  <p className="title_para">
                    {lbl
                      ? lbl.updateProfileDesc
                      : "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."}
                  </p>
                </div>

                <div className="col-md-12 mt-4">
                  <div className="tab-content w-100 float-left">
                    <div id="home" className="tab-pane active w-100 float-left">
                      <div className="mt-4 edit_profile_upload_pic w-100 float-left">
                        <div className="circle_main">
                          <div className="circle">
                            <img
                              className="profile-pic"
                              src={
                                profile_pic_preview !== ""
                                  ? profile_pic_preview
                                  : noProfile
                              }
                              alt="profile-pic"
                            />
                          </div>
                          <div
                            className="p-image bg_color"
                            onClick={(e) => openImageUpload(e)}
                          >
                            <i className="fa fa-camera upload-button"></i>
                            <input
                              id="photo"
                              className="file-upload d-none"
                              type="file"
                              accept="image/*"
                              disabled={loader}
                              onChange={(e) => onFileChange(e)}
                            />
                          </div>
                        </div>
                        {error_profile_pic ? (
                          <div className="mt-2 mb-0 w-100 text-center invalid-text ">
                            {error_profile_pic}
                          </div>
                        ) : null}
                      </div>

                      <div className="mt-2 mb-3 w-100 float-left text-center tg-cont-ad">
                        <p className="gnrl_para">
                          {email !== "" ? email : "Not found"}
                        </p>
                      </div>

                      {/* ---------- Student Role First and last name fields ---------- */}
                      {auth.user &&
                        user.role === 1 &&
                        renderStudentNameFields()}
                      {/* -------------------------------------------------- */}

                      {/* ---------- Student Role First and last name fields ---------- */}
                      {auth.user && user.role === 2 && renderCoachFields()}
                      {/* -------------------------------------------------- */}
                      <div
                        className="w-100 mt-4 float-left "
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          findDOMNode(updateRef.current).click();
                        }}
                      >
                        <Tooltip title="Update mobile number" animation="scale">
                          <button
                            className="btn btn-sm mobile-btn"
                            disabled={true}
                          >
                            {phone_number ? phone_number : "Phone Number"}
                            <span
                              className="tg-edit-icon"
                              ref={updateRef}
                              onClick={(e) => setShowUpdateMobileModal(true)}
                            >
                              <img
                                src={editicon}
                                className="tg_e_icon"
                                alt="edit-icon"
                              />
                            </span>
                          </button>
                        </Tooltip>
                      </div>
                      <div className="mt-4 w-100 float-left">
                        <button
                          className="tg-coach-pswrd-btn"
                          disabled={loader}
                          onClick={(e) => setShowResetPasswordModal(true)}
                        >
                          {lbl ? lbl.changePassword : "Change password"}
                        </button>
                      </div>

                      {/* ---------- Student role emergency contact ad sizefields ---------- */}
                      {auth.user && user.role === 1 && renderStudentFields()}
                      {/* -------------------------------------------------- */}
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mt-4">
                  <ButtonComponent
                    loading={loader}
                    title={lbl ? lbl.save : "Save"}
                    onBtnClick={(e) => {
                      user.role === 1
                        ? saveStudentProfile(e)
                        : saveCoachProfile(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <UpdateMobileModal
        showModal={showUpdateMobileModal}
        closeModal={closeUpdateMobileModal}
        currentNumber={phone_number}
        toast={toast}
      />
      <ResetPasswordModal
        showModal={showResetPasswordModal}
        closeModal={savePasswordModal}
        toast={toast}
      />
    </Fragment>
  );
};

UpdateProfile.propTypes = {
  updateUserProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { updateUserProfile, loadUser })(
  UpdateProfile
);
