import { GET_PAYMENT_HISTORY } from "../actions/types";

const initialState = {
  paymentHistory: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  loading: true,
};

/**
 * Reducer Called for state management for the Course List.
 * @param {*} [state=initialState] initail state of the list.
 * @param {*} action State Update.
 * @return {*}
 */
const paymentHistoryReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PAYMENT_HISTORY:
      return {
        ...state,
        paymentHistory: payload,
        loading: false,
      };

    default:
      return state;
  }
};
export default paymentHistoryReducer;
