import {
  CLASS_SCHEDULED,
  CLASS_TIME_SCHEDULE,
  COURSE_STUDENT_REQ_LIST,
} from "../actions/types";

const initialState = {
  getClassSchedule: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  getTimeCLassSchedule: {},
  classLoading: true,
  studentCourseReqList: [],
};

/**
 * Reducer Called for state management for the Course List.
 * @param {*} [state=initialState] initail state of the list.
 * @param {*} action State Update.
 * @return {*}
 */
const coachReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case CLASS_SCHEDULED:
      return {
        ...state,
        getClassSchedule: payload,
        classLoading: false,
      };
    case CLASS_TIME_SCHEDULE:
      return {
        ...state,
        getTimeCLassSchedule: payload,
        classLoading: false,
      };
    case COURSE_STUDENT_REQ_LIST:
      return {
        ...state,
        studentCourseReqList: payload,
        classLoading: false,
      };
    default:
      return state;
  }
};
export default coachReducer;
