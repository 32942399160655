import React, { Fragment, useEffect, useState } from "react";
import ProfileHeader from "../../CommonComponents/ProfileHeader/ProfileHeader";
import calender from "../../../assets/images/date.svg";
import gift from "../../../assets/images/my-gifts-black.svg";
import course from "../../../assets/images/course-white.svg";
import { Link, useLocation } from "react-router-dom";
import DefaultHeader from "../../../DefaultLayout/DefaultHeader";
import { getCoachCourseList } from "../../../actions/coachCourseAction";
import { loadUser } from "../../../actions/authActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CourseListLoader } from "../../../components/ContentLoader/CourseCardLoader";
import { scrollToTop } from "../../../Helpers/ScrollToTop";
import labels from "../../../labels.json";
import moment from 'moment'
import './CoachProfile.css'

const CoachProfile = (routerProps) => {
    const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

    const { getCoachCourseList, coachCourseListing, loadUser, auth } = routerProps;
    const { getAllCourseList, Loading, myListedCourses } = coachCourseListing;

    const [pageLimit] = useState(10);
    const [page] = useState(1);
    const { pathname } = useLocation();
    const [loadingResponse, setLoadingResponse] = useState(false);

    useEffect(() => {
        coachCourseListing.myListedCourses = [];
        loadCourseList();
        loadUser();
    }, []);

    const loadCourseList = () => {
        setLoadingResponse(true);
        coachCourseListing.myListedCourses = [];
        getCoachCourseList({ page: page, limit: pageLimit }).then((res) => {
            setLoadingResponse(false);
            scrollToTop();
        });
    };
    const onPageClick = (page) => {
        getCoachCourseList({ page: page, limit: pageLimit });
    };

    const viewMore = (data) => {
        const { hasNextPage, nextPage } = data;

        return (
            <div className="w-100 ">
                <button
                    hidden={hasNextPage === true ? false : true}
                    onClick={hasNextPage === true ? () => onPageClick(nextPage) : null}
                    className=" blue_fill_btn view-more-paginate-btn btn-sm "
                >
                    {lbl ? lbl.viewMore : "View more..."}
                </button>
            </div>
        );
    };

    return (
        <Fragment>
            <DefaultHeader />
            <ProfileHeader />
            <section className="student_profile_main mar_top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mt-2 mt-4 mb-3 tg-ctsm-box">
                            <Link to="/coach/my-courses">
                                <button className="my_course_btn my-active">
                                    <img src={course} alt="course" />
                                    {lbl ? lbl.myCourse : "My Courses"}
                                </button>
                            </Link>
                            <Link to="/coach/gifts">
                                <button className="my_btn_gift ">
                                    <img src={gift} alt="gifts" />
                                    {lbl ? lbl.gifts : "Gifts"}
                                </button>
                            </Link>
                        </div>
                        {!loadingResponse &&
                            getAllCourseList.docs.length > 0 &&
                            !Loading ? (
                            myListedCourses.map((courselist, i) => {
                                let dateYMD = ''
                                if(courselist.date !== '-') dateYMD = `${moment(courselist.date).year()}年${moment(courselist.date).format('MM')}月${moment(courselist.date).format('DD')}日`
                                return (
                                    <div className="col-sm-12 col-md-12 col-lg-6 mb-3" key={i} style={{ overflow: 'hidden' }}>
                                        <div className="stdnt_home_main_box">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="feed_common_user_profile_txt mt-0">
                                                        <p className="gnrl_para m-0 pl-8 pr-8 font-18">
                                                            {courselist.title}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="flex_box">
                                                    <div className="people_going_main">
                                                        <p className="color_gray gnrl_para feed_date_time pr-20">
                                                            <img src={calender} alt="" /> {dateYMD}
                                                        </p>
                                                    </div>
                                                    <Link
                                                        to={
                                                            pathname === "/coach/my-courses"
                                                                ? `/coach/my-courses/details/${courselist._id}`
                                                                : "/coach/details"
                                                        }
                                                    >
                                                        <button className="view_more_btn viewMore">
                                                            {lbl ? lbl.viewMoreDetails : "View more details"}
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : Loading || loadingResponse ? (
                            <div className="col-xl-12 col-lg-12 col-md-12 mt-5">
                                <CourseListLoader />
                            </div>
                        ) : getAllCourseList.docs.length === 0 && !Loading ? (
                            <div className="col-xl-12 col-lg-12 col-md-12 mt-5">
                                <div className="col tg-display tg-content">
                                    {lbl ? lbl.noCourseListFound : "No Course List found"}
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className=" w-100 mt-2">{viewMore(getAllCourseList)}</div>
                </div>
            </section>
        </Fragment>
    );
};

CoachProfile.propTypes = {
    coachCourseListing: PropTypes.object.isRequired,
    loadUser: PropTypes.func.isRequired,
    getCoachCourseList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    coachCourseListing: state.all_courseList,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    loadUser,
    getCoachCourseList,
})(CoachProfile);
