import axios from "axios";
import { config, Get_Cms_Details, Get_Faq_Details, Send_Inquiry } from "../api";

export const getContentDetails = (cms_id) => async (dispatch) => {
  try {
    const res = await axios.get(Get_Cms_Details + cms_id);
    dispatch({
      type: "GET_CMS_DETAILS",
      payload: res.data.result,
    });
  } catch (error) {
    dispatch({
      type: "GET_CMS_DETAILS",
      payload: null,
    });
    return error;
  }
};

export const getFaqDetails = () => async (dispatch) => {
  try {
    const res = await axios.get(Get_Faq_Details, {
      params: { page: 1, limit: 50 },
    });
    dispatch({
      type: "GET_FAQ_DETAILS",
      payload: res.data.result,
    });
  } catch (error) {
    dispatch({
      type: "GET_FAQ_DETAILS",
      payload: null,
    });
    return error;
  }
};

export const sendContactInquiry = (inquiryData) => async (dispatch) => {
  const body = JSON.stringify(inquiryData);
  return await axios.post(Send_Inquiry, body, config);
};
