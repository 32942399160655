import React, { Fragment } from "react";
import DefaultHeader from "../../../DefaultLayout/DefaultHeader";
import LeftSideNavigationMenu from "../../CommonComponents/LeftSideNavigationMenu/LeftSideNavigationMenu";
import MyPaymentHistoryComponent from "../../CommonComponents/MyPaymentHistoryComponent/MyPaymentHistoryComponent";
import ProfileHeader from "../../CommonComponents/ProfileHeader/ProfileHeader";
export default function MyPaymentHistory() {
  return (
    <Fragment>
      <DefaultHeader />
      <ProfileHeader />
      <section className="stdnt_home_main">
        <div className="container">
          <div className="row mt-4 pl-1 pr-2">
            <LeftSideNavigationMenu />
            <MyPaymentHistoryComponent />
          </div>
        </div>
      </section>
    </Fragment>
  );
}
