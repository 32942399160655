import React, { Fragment, useState } from "react";
import { useLocation } from "react-router";
import enrollBlue from "../../../assets/images/my-enrollments-blue.svg";
import enrollBlack from "../../../assets/images/my-enrollments-black.svg";
import giftsBlue from "../../../assets/images/my-gifts-blue.svg";
import giftsBlack from "../../../assets/images/my-gifts-black.svg";
import leaveBlue from "../../../assets/images/my-leaves-blue.svg";
import leaveBlack from "../../../assets/images/my-leaves-black.svg";
import paymentBlue from "../../../assets/images/payment-blue.svg";
import paymentBlack from "../../../assets/images/payment-black.svg";
import "./leftNavigation.css";
import classnames from "classnames";
import { Link } from "react-router-dom";
import labels from "../../../labels.json";

const LeftSideNavigationMenu = () => {
  const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

  const { pathname } = useLocation();
  const [hoverImage, setHoverImage] = useState("");

  const enrollmentPath = "/student/my-enrollments";
  const giftPath = "/student/my-gifts";
  const leavePath = "/student/my-leaves";
  const paymentPath = "/student/payment-history";

  return (
    <Fragment>
      <div className="col-md-4 col-lg-3 pl-2 ">
        <div className="left-navigation-section">
          <Link to="/student/my-enrollments">
            <div
              className="left-side-menu  bg-white"
              onMouseOver={() => setHoverImage("enroll")}
              onMouseOut={() => setHoverImage("")}
            >
              <img
                src={
                  pathname === enrollmentPath || hoverImage === "enroll"
                    ? enrollBlue
                    : enrollBlack
                }
                alt="icon"
              />
              <p
                className={classnames("gnrl_para m-0", {
                  " menu-active": pathname === enrollmentPath,
                })}
              >
                {lbl ? lbl.myEnrollments : "My Enrollments"}
              </p>
            </div>
          </Link>
          <Link to="/student/my-gifts">
            <div
              className="left-side-menu  bg-white"
              onMouseOver={() => setHoverImage("gifts")}
              onMouseOut={() => setHoverImage("")}
            >
              <img
                src={
                  pathname === giftPath || hoverImage === "gifts"
                    ? giftsBlue
                    : giftsBlack
                }
                alt="icon"
              />
              <p
                className={classnames("gnrl_para m-0", {
                  " menu-active": pathname === giftPath,
                })}
              >
                {lbl ? lbl.myGifts : "My Gifts"}
              </p>
            </div>
          </Link>
          <Link to="/student/my-leaves">
            <div
              className="left-side-menu  bg-white"
              onMouseOver={() => setHoverImage("leave")}
              onMouseOut={() => setHoverImage("")}
            >
              <img
                src={
                  pathname === leavePath || hoverImage === "leave"
                    ? leaveBlue
                    : leaveBlack
                }
                alt="icon"
              />
              <p
                className={classnames("gnrl_para m-0", {
                  " menu-active": pathname === leavePath,
                })}
              >
                {lbl ? lbl.myLeaves : "My Leaves"}
              </p>
            </div>
          </Link>
          <Link to="/student/payment-history">
            <div
              className="left-side-menu  bg-white"
              onMouseOver={() => setHoverImage("payment")}
              onMouseOut={() => setHoverImage("")}
            >
              <img
                src={
                  pathname === paymentPath || hoverImage === "payment"
                    ? paymentBlue
                    : paymentBlack
                }
                alt="icon"
              />
              <p
                className={classnames("gnrl_para m-0", {
                  " menu-active": pathname === paymentPath,
                })}
              >
                {lbl ? lbl.paymentHistory : "Payment History"}
              </p>
            </div>
          </Link>
        </div>
      </div>
    </Fragment>
  );
};
export default LeftSideNavigationMenu;
