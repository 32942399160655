import React, { Fragment } from "react";
import { BeatLoader } from "react-spinners";

const ButtonComponent = (buttonProps) => {
  const { loading, title, onBtnClick, btnRef } = buttonProps;

  return (
    <Fragment>
      <button
        className="blue_fill_btn w-100 float-left "
        onClick={(e) => onBtnClick()}
        disabled={loading}
        ref={btnRef}
      >
        {!loading ? title : <BeatLoader color="#005da1" size="10" />}
      </button>
    </Fragment>
  );
};
export default ButtonComponent;
