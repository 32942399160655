import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { FormGroup, Input, Label, Modal, ModalBody } from "reactstrap";
import { changePassword } from "../../../actions/authActions";
import "./Payment.css";
import {
    CardElement,
    Elements,
    ElementsConsumer,
} from "@stripe/react-stripe-js";
import CardSection from "./CardSection";
import { loadStripe } from "@stripe/stripe-js";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { studentPayment } from "../../../actions/paymentHistoryAction";
import ToastifyContainer from "../../../components/Toastify/ToastContainer";
import { getStudentCourseDetails } from "../../../actions/studentCourseAction";
import labels from "../../../labels.json";
import classnames from "classnames";
import parse from "html-react-parser";
import { bindActionCreators } from "redux";
import * as cmsActions from "../../../actions/cmsAction";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_KEY);

const PaymentModal = (props) => {
    const lbl = labels[process.env.REACT_APP_LABEL_TYPE];
    const dispatch = useDispatch();
    const {
        showModal,
        closeModal,
        course_id,
        getStudentCourseDetails,
        package_id,
        studentPayment,
    } = props;

    const [selectedMode, setselectedMode] = useState("1");
    const [error, setError] = useState("");
    const [bookingLoading, setBookingLoading] = useState(false);
    const [errLeaveTnc, setErrLeaveTnc] = useState('');
    const [checkLeaveTnc, setCheckLeaveTnc] = useState(false);
    const [openTermsModal, setOpenTermsModal] = useState(false);
    const { getContentDetails } = bindActionCreators(cmsActions, dispatch);
    const { cmsDetails, cmsLoading } = useSelector((state) => state.cmsDetails);

    useEffect(() => {

    }, []);

    const onCheckLeaveTnc = (e) => {
        setCheckLeaveTnc(!checkLeaveTnc);
        setErrLeaveTnc('')
    }

    const onClickCheckTerms = async (e, type) => {
        setOpenTermsModal(true);
        await getContentDetails(type);
    }

    const onHandleChange = (e) => {
        setselectedMode(e.target.value);
    };

    const onHandleClickPay = async (e, type, stripe, elements) => {
        setBookingLoading(true);
        const card = elements.getElement(CardElement);
        const result = await stripe.createToken(card);
        if (result.error) {
            setBookingLoading(false);
            if (
                result.error.message === "Your card's security code is incomplete." ||
                result.error.message === "Your card number is incomplete."
            ) {
                setError("請輸入正確的信用卡號資料");
            }
            if (result.error.message === "Your card number is invalid.") {
                setError("你的信用卡號碼不正確");
            }
            // setTimeout(() => {
            //   setError("");
            // }, 1000);
        } else {
            setError("");
            const payment = {
                course: course_id,
                package: package_id,
                payment_type: 1,
                token: result.token.id,
            };
            studentPayment(payment).then((res) => {
                setTimeout(() => {
                    setBookingLoading(false);
                    closeModal(false);
                    getStudentCourseDetails(course_id);
                }, 4500);
            });
        }
    };

    const onHandleOfflinePay = (e) => {
        if (!checkLeaveTnc) {
            setErrLeaveTnc("請勾選請假條款")
            return
        } else {
            setErrLeaveTnc("")
        }
        setBookingLoading(true);
        const payment = {
            course: course_id,
            package: package_id,
            payment_type: 2,
            token: "",
        };
        studentPayment(payment).then((res) => {
            setTimeout(() => {
                closeModal(false);
                setBookingLoading(false);
                getStudentCourseDetails(course_id);
            }, 4500);
        });
    };

    const oncloseModal = () => {
        closeModal(false);
        setselectedMode("1");
        setBookingLoading(false);
    };


    const renderTermsModal = (e) => {
        return (
            <Fragment>
                <Modal size="lg" isOpen={openTermsModal}>
                    <ModalBody className="modal-body modal-body-container">
                        <div className="col-md-12">
                            <h3 className="modal-label w-100" style={{ textAlign: 'center' }}>
                                {cmsDetails && !cmsLoading ? (
                                    cmsDetails.title
                                ) : null}

                            </h3>
                        </div>
                        <div className="col-md-12">
                            {cmsDetails && !cmsLoading ? parse(`${cmsDetails.content}`) : null}
                        </div>
                        <div className="w-100 text-center">
                            <button
                                type="button"
                                className="btn blue_fill_btn w-25 mt-4 text-right"
                                onClick={() => {
                                    setCheckLeaveTnc(true);
                                    setOpenTermsModal(false);
                                }}
                            >
                                {lbl ? lbl.ok : "Ok"}
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>
        );
    };


    return (
        <Fragment>
            <ToastifyContainer />
            {/* 彈窗 */}
            <Modal isOpen={showModal} className={'paymentModal'}>
                <ModalBody>
                    <span className="reset_title">
                        <strong>{lbl ? lbl.paymentMode : "Payment Mode"}</strong>
                    </span>
                    <span className="close_btn" onClick={() => oncloseModal()}>
                        x
                    </span>
                    <div className="w-100 tg_payment">
                        <div className="radio">
                            <input
                                type="radio"
                                className="check_btn"
                                name="1"
                                value="1"
                                checked={selectedMode === "1"}
                                onChange={(e) => onHandleChange(e)}
                            />
                            <label className="radio-label check_labl">
                                {lbl ? lbl.offlinePayment : "Offline Payment"}
                            </label>
                        </div>
                        {/* <div className="radio">
                            <input
                                type="radio"
                                name="2"
                                value="2"
                                className="check_btn"
                                checked={selectedMode === "2"}
                                onChange={(e) => onHandleChange(e)}
                            />
                            <label className="radio-label check_labl">
                                {lbl ? lbl.onlinePayment : "Online Payment"}
                            </label>
                        </div> */}
                    </div>

                    <div className="w-100 tg_payment">
                        <FormGroup
                            className={classnames(
                                { invalid: errLeaveTnc.length > 0 },
                                { valid: errLeaveTnc.length === 0 }
                            )}
                            check>
                            <Input type="checkbox" onChange={onCheckLeaveTnc} checked={checkLeaveTnc} />
                            {' '}
                            <Label check>
                                {lbl ? lbl.check_user_agreement : ''}
                                <button onClick={(e) => onClickCheckTerms(e, 'leave-terms')} style={{ textDecorationLine: "underline", borderWidth: '0' }}>{lbl ? lbl.leave_terms : ''}</button>
                                <span>、</span>
                                <button onClick={(e) => onClickCheckTerms(e, 'tnc-payment')} style={{ textDecorationLine: "underline", borderWidth: '0' }}>{lbl ? lbl.payment_terms : ''}</button>
                            </Label>
                        </FormGroup>
                        {errLeaveTnc ? <span className="invalid-text">{errLeaveTnc}</span> : null}
                    </div>

                    <hr className="w-100 color_gray" />
                    <div className="card_pay_box">
                        {selectedMode === "2" ? (
                            <Fragment>
                                <span className="card-pay">
                                    {lbl ? lbl.cardDetails : "Card details"}
                                </span>
                                <Elements stripe={stripePromise}>
                                    <ElementsConsumer>
                                        {({ stripe, elements }) => (
                                            <div className="col-md-12 mt-3 mb-3 text-left">
                                                <CardSection />
                                                <span className="invalid-text">{error}</span>
                                                <div className="pay_btn">
                                                    <ButtonComponent
                                                        loading={bookingLoading}
                                                        title="Pay"
                                                        onBtnClick={(e) =>
                                                            onHandleClickPay(e, "card", stripe, elements)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </ElementsConsumer>
                                </Elements>
                            </Fragment>
                        ) : selectedMode === "1" ? (
                            <div>
                                <div className="card-pay">{lbl ? lbl.offlinePayment : "Offline Payment"}</div>
                                <div className="offlinePayment">
                                    <div className="offlinePaymentItem">
                                        <div>{lbl.paymentMethod}</div>
                                        <div>{lbl.payMeOnHere}</div>
                                        <div>
                                            <a href="https://qr.payme.hsbc.com.hk/2/WkAgAoouFPSuAyvXwqs5da" target="__blank">
                                                {'https://qr.payme.hsbc.com.hk/2/WkAgAoouFPSuAyvXwqs5da'}
                                            </a>
                                        </div>
                                        <div>{lbl.fastPay}</div>
                                        <div>97026622</div>
                                        <div>{lbl.payForHFAccount}</div>
                                        <div>817-238421-838</div>
                                    </div>
                                    <div className="offlinePaymentItem">
                                        <div>{lbl.payForChinaBankAccount}</div>
                                        <div>012-74220037864</div>
                                        <div>(戶口名稱：Man Lap International Sports Group Co Ltd)</div>
                                        <div>(匯款顯示戶口名稱:MLISG*****LSC)</div>
                                        <div>或 支付寶（香港/國內版）及微信支付</div>
                                        <div>存款後請提供有效存款證明</div>
                                    </div>
                                </div>
                                <div className="pay_btn">
                                    <ButtonComponent
                                        loading={bookingLoading}
                                        title={lbl ? lbl.pay : "Pay"}
                                        onBtnClick={(e) => onHandleOfflinePay(e)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <span className="card-pay no-pay-ava">
                                {lbl ? lbl.noPaymentModeAvailable : "No Payment Mode Available"}
                            </span>
                        )}
                    </div>
                </ModalBody>
            </Modal>
            {renderTermsModal()}
        </Fragment>
    );
};

PaymentModal.propTypes = {
    changePassword: PropTypes.func.isRequired,
    studentPayment: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {
    changePassword,
    studentPayment,
    getStudentCourseDetails,
})(PaymentModal);
