import React from "react";
import "./loader.css";
export default function NotificationsLoader() {
  return (
    <div className="ph-item mb-1 px-3">
      <div className="ph-col-12 ">
        <div className="ph-row ">
          <div className="ph-col-6  color about mt-3"></div>
          <div className="ph-col-2 empty"></div>
          <div className="ph-col-4 color about  mt-3"></div>
        </div>
        <div className="ph-row  pt-2">
          <div className="ph-col-6  color about mt-3"></div>
          <div className="ph-col-2 empty"></div>
          <div className="ph-col-4 color about  mt-3"></div>
        </div>
        <div className="ph-row pt-2">
          <div className="ph-col-6  color about mt-3"></div>
          <div className="ph-col-2 empty"></div>
          <div className="ph-col-4 color about  mt-3"></div>
        </div>
        <div className="ph-row pt-2">
          <div className="ph-col-6  color about mt-3"></div>
          <div className="ph-col-2 empty"></div>
          <div className="ph-col-4 color about  mt-3"></div>
        </div>
        <div className="ph-row pt-2">
          <div className="ph-col-6  color about mt-3"></div>
          <div className="ph-col-2 empty"></div>
          <div className="ph-col-4 color about  mt-3"></div>
        </div>
      </div>
    </div>
  );
}
