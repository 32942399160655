import React, { Fragment, useEffect } from "react";
import DefaultHeader from "../../../DefaultLayout/DefaultHeader";
import "./authenticationPages.css";
import LeftSideImage from "../../CommonComponents/AuthenticationComponents/LeftSideImage";
import LoginInput from "../../CommonComponents/AuthenticationComponents/LoginInput";
import DefaultFooter from "../../../DefaultLayout/DefaultFooter";
import { scrollToTop } from "../../../Helpers/ScrollToTop";

const Login = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <Fragment>
      <DefaultHeader />
      <section className="stdnt_login_registr_main sec_mar_top">
        <div className="container">
          <div className="row">
            <LeftSideImage />
            <LoginInput userRole={1} />
          </div>
        </div>
      </section>
      <DefaultFooter />
    </Fragment>
  );
};
export default Login;
