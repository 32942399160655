import React, { Fragment, useEffect } from "react";
import Coach from "../../assets/images/coach-login.png";
import Student from "../../assets/images/student-login-.png";
import "./loginCategory.css";
import { Link, Redirect } from "react-router-dom";
import DefaultHeader from "../../DefaultLayout/DefaultHeader";
import DefaultFooter from "../../DefaultLayout/DefaultFooter";
import { scrollToTop } from "../../Helpers/ScrollToTop";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import labels from "../../labels.json";

const LoginCategory = (routerProps) => {
  const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

  console.log();
  useEffect(() => {
    scrollToTop();
  }, []);

  const { auth } = routerProps;

  const { isAuthenticated, loading, user } = auth;

  if (isAuthenticated && !loading && user.role === 1) {
    return <Redirect to="/student/course" />;
  }
  if (isAuthenticated && !loading && user.role === 2) {
    return <Redirect to="/coach/course-list" />;
  }

  return (
    <Fragment>
      <DefaultHeader />
      <section className="select_type_main sec_mar_top mb-5">
        <div className="container">
          <div className="row custom-row">
            <div className="col-lg-7 col-md-10">
              <div className="row text-center">
                <div className="col-md-12">
                  <h2 className="main_title ">
                    {lbl
                      ? lbl.welcomeToInterAcademy
                      : "Login to your account and start exploring Inter Academy features"}
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-10">
              <div className="row text-center">
                <div className="col-md-6 mt-5 custome-padding-right ">
                  <div className="select_type_main_card select_type_main_card_custome">
                    <span className="select_type_main_card_img1 student_login_img">
                      <img src={Student} alt="student-icon" />
                    </span>
                    <Link to="/student/login">
                      <button className="blue_fill_btn w-100 custom-font ">
                        {lbl ? lbl.loginAsStudent : "Login as student"}
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="col-md-6 mt-5 custome-padding-left">
                  <div className="select_type_main_card select_type_main_card_custome">
                    <span className="select_type_main_card_img2 student_login_img">
                      <img src={Coach} alt="coach-icon" />
                    </span>
                    <Link to="/coach/login">
                      <button className="blue_fill_btn w-100 custom-font ">
                        {lbl ? lbl.loginAsCoach : "Login as coach"}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <DefaultFooter />
    </Fragment>
  );
};

LoginCategory.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(LoginCategory);
