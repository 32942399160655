import React, { Fragment, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import DefaultFooter from "../../DefaultLayout/DefaultFooter";
import DefaultHeader from "../../DefaultLayout/DefaultHeader";
import Slider from "react-slick";
import "./welcomePage.css";
import arrowLeft from "../../assets/images/arrow-left.png";
import arrowRight from "../../assets/images/arrow-right.png";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getBannerImages } from "../../actions/authActions";
import { PulseLoader } from "react-spinners";
import { scrollToTop } from "../../Helpers/ScrollToTop";
import labels from "../../labels.json";

const WelcomePage = (routerProps) => {
  const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

  const { auth, getBannerImages } = routerProps;
  const { isAuthenticated, loading, user } = auth;

  const [bannerImages, setBannerImages] = useState([]);
  const [bannerloading, setBannerLoading] = useState(false);

  useEffect(() => {
    scrollToTop();
    getBannerImages().then((res) => {
      let response = res.data.result;
      if (response.length > 0) {
        setBannerImages(response);
        setBannerLoading(false);
      } else {
        setBannerLoading(true);
      }
    });
  }, []);

  if (isAuthenticated && !loading && user.role === 1) {
    return <Redirect to="/student/course" />;
  }
  if (isAuthenticated && !loading && user.role === 2) {
    return <Redirect to="/coach/course-list" />;
  }

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img
          src={arrowRight}
          style={{
            ...style,
            display: "block",
            width: "30px",
            marginLeft: "23px",
          }}
          alt=""
        />
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img
          src={arrowLeft}
          style={{
            ...style,
            display: "block",
            width: "30px",
            marginRight: "20px",
          }}
          alt=""
        />
      </div>
    );
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    appendDots: (dots) => (
      <div
        style={{
          borderRadius: "10px",
          width: "100%",
          padding: "0px",
          height: "0px",
          marginTop: "0px",
        }}
      >
        <ul className={bannerImages.length < 10 ? "tg-cust-dots" : "tg_list"}>
          {dots}
        </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          height: "4px",
          backgroundColor: "#80808094",
          color: "#005da1",
        }}
      ></div>
    ),
  };

  return (
    <Fragment>
      <DefaultHeader />
      <div className="container tg-cust-mr">
        <h3 className="main_title w-100 text-center my-4 my-md-5 pt-4">
          {lbl ? lbl.welcomeToInterAcademy : "Welcome to Inter Academy"}
        </h3>
        <div className="row  w-100 px-0 px-sm-4 px-md-0 px-lg-0 mx-0">
          <div className="col-md-12 px-0 mx-0">
            <Slider {...settings}>
              {bannerImages.length > 0 && !bannerloading ? (
                bannerImages.map((image, i) => {
                  return (
                    <div key={i} className="image-conatiner">
                      <img
                        src={image.image_path}
                        className="w-100 mb-5 tg-slider-image"
                        alt="slides"
                      />
                    </div>
                  );
                })
              ) : bannerImages.length === [] ? (
                <div className="col-xl-12 col-lg-12 col-md-12 mt-5">
                  <div className="col tg-display tg-content">
                    No Banner Image found
                  </div>
                </div>
              ) : (
                <div className="col-xl-12 col-lg-12 col-md-12 mt-5">
                  <div className="col tg-display tg-content">
                    <PulseLoader color="#005da1" size="15" />
                  </div>
                </div>
              )}
            </Slider>
          </div>
        </div>
      </div>
      <DefaultFooter />
    </Fragment>
  );
};

WelcomePage.propTypes = {
  auth: PropTypes.object.isRequired,
  getBannerImages: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { getBannerImages })(WelcomePage);
