import React, { useEffect } from "react";
import { HashRouter, Route, Switch, BrowserRouter } from "react-router-dom";
import StudentLogin from "../views/student/AuthenticationPages/Login";
import CoachLogin from "../views/coach/Login/CoachLogin";
import StudentRegister from "../views/student/AuthenticationPages/Register";
import ForgotPassword from "../views/student/AuthenticationPages/ForgotPassword";
import ResetPassword from "../views/student/AuthenticationPages/ResetPassword";
import DefaultLayout from "../DefaultLayout/DefaultLayout";
import LoginCategory from "../views/LoginCategory/LoginCategory";
import WelcomePage from "../views/WelcomePage/WelcomePage";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { BeatLoader } from "react-spinners";
import RegisteredEmailVerified from "../views/student/AuthenticationPages/RegisteredEmailVerified";
import AboutUs from "../views/CmsPages/AboutUs";
import Faq from "../views/CmsPages/Faq";
import ContactUs from "../views/CmsPages/ContactUs";
const AppRoutes = (routerProps) => {
  const { auth } = routerProps;

  const { loading } = auth;

  useEffect(()=>{

  },[])

  if (loading === true) {
    return (
      <div className="main-loader">
        <BeatLoader size="20" color="#005da1" />
      </div>
    );
  }
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact={true} component={WelcomePage} />
        <Route path="/login-category" exact={true} component={LoginCategory} />
        <Route path="/student/login" exact={true} component={StudentLogin} />
        <Route
          path="/student/register"
          exact={true}
          component={StudentRegister}
        />
        <Route
          path="/forgot-password"
          exact={true}
          component={ForgotPassword}
        />
        <Route
          path="/user/verify/:code"
          exact={true}
          component={RegisteredEmailVerified}
        />
        <Route
          path="/reset-password/:code"
          exact={true}
          component={ResetPassword}
        />
        <Route path="/about-us" exact={true} component={AboutUs} />
        <Route path="/privacy-policy" exact={true} component={AboutUs} />
        <Route path="/faq" exact={true} component={AboutUs} />
        <Route path="/contact-us" exact={true} component={ContactUs} />
        <Route path="/coach/login" exact={true} component={CoachLogin} />
        <Route path="/" render={(props) => <DefaultLayout {...props} />} />
      </Switch>
    </BrowserRouter>
  );
};

AppRoutes.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(AppRoutes);
