import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { changePassword } from "../../../actions/authActions";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import InputField from "../../../components/InputField/InputField";
import { checkRequiredValidationWithMinMax } from "../../../Helpers/Validation";
import "./common.css";
import labels from "../../../labels.json";

function ResetPasswordModal(props) {
  const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

  const { showModal, closeModal, toast, changePassword } = props;

  const [old_password, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [error_old_password, setErrorOldPassword] = useState("");
  const [error_password, setErrorPassword] = useState("");
  const [error_confirm_password, setErrorConfirmPassword] = useState("");
  const [loader, setLoader] = useState(false);

  const clearAllErrorFields = () => {
    setErrorOldPassword("");
    setErrorPassword("");
    setErrorConfirmPassword("");
  };

  const clearAllInputFields = () => {
    setOldPassword("");
    setPassword("");
    setConfirmPassword("");
  };

  const closeModalClick = (e) => {
    clearAllErrorFields();
    clearAllInputFields();
    closeModal();
  };
  const onResetPasswordClick = (e) => {
    let errOldPwd = "";
    let errPassword = "";
    let errConfPassword = "";
    let validationFlag = true;

    let oldPasswordLbl = "Old password ";
    let passwordLbl = "Password ";
    let confPasswordLbl = "Confirm Password ";
    let errConfPasswordLbl = "Confirm password doesn't match with password ";

    if (lbl && lbl.oldPassword) oldPasswordLbl = lbl.oldPassword;
    if (lbl && lbl.password) passwordLbl = lbl.password;
    if (lbl && lbl.confPassword) confPasswordLbl = lbl.confPassword;
    if (lbl && lbl.errConfPasswordLbl)
      errConfPasswordLbl = lbl.errConfPasswordLbl;

    errOldPwd = checkRequiredValidationWithMinMax(
      old_password,
      oldPasswordLbl,
      6,
      12
    );
    errPassword = checkRequiredValidationWithMinMax(
      password,
      passwordLbl,
      6,
      12
    );
    errConfPassword = checkRequiredValidationWithMinMax(
      confirm_password,
      confPasswordLbl,
      6,
      12
    );
    if (password !== confirm_password) {
      errConfPassword = errConfPasswordLbl;
    }

    if (errOldPwd || errPassword || errConfPassword) {
      validationFlag = false;

      setErrorOldPassword(errOldPwd);
      setErrorPassword(errPassword);
      setErrorConfirmPassword(errConfPassword);
    } else {
      validationFlag = true;
      clearAllErrorFields();
    }

    if (validationFlag) {
      setLoader(true);

      const userObj = {
        old_password,
        password,
        confirm_password,
      };

      changePassword(userObj)
        .then((res) => {
          toast.success(res.data.message);
          setLoader(false);
          closeModalClick();
        })
        .catch((error) => {
          setLoader(false);
          toast.error(error.response.data.message);
        });
    }
  };
  return (
    <Fragment>
      <Modal isOpen={showModal}>
        <ModalBody className="modalContainer">
          <span className="reset_title">
            <strong>{lbl ? lbl.resetPassword : "Reset Password"}</strong>
          </span>
          <span className="close_btn" onClick={closeModalClick}>
            x
          </span>
          <div className="mt-4 w-100 float-left text-input">
            <InputField
              inputType="password"
              placeHolder={lbl ? lbl.oldPassword : "Old Password"}
              errorValue={error_old_password}
              disable={loader}
              onInputChange={(e) => setOldPassword(e.target.value)}
              value={old_password}
            />
          </div>
          <div className="mt-4 w-100 float-left text-input">
            <InputField
              inputType="password"
              placeHolder={lbl ? lbl.newPassword : "New Password"}
              errorValue={error_password}
              disable={loader}
              onInputChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </div>
          <div className="mt-4 w-100 float-left text-input">
            <InputField
              inputType="password"
              placeHolder={lbl ? lbl.confNewPassword : "Confirm Password"}
              errorValue={error_confirm_password}
              disable={loader}
              onInputChange={(e) => setConfirmPassword(e.target.value)}
              value={confirm_password}
            />
          </div>
          <div className="mt-4">
            <ButtonComponent
              loading={loader}
              title={lbl ? lbl.resetPassword : "Reset Password"}
              onBtnClick={(e) => onResetPasswordClick(e)}
            />
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
}

ResetPasswordModal.propTypes = {
  changePassword: PropTypes.func.isRequired,
};

export default connect(null, { changePassword })(ResetPasswordModal);
