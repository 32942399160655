import React, { Fragment } from "react";
import DefaultHeader from "../../../DefaultLayout/DefaultHeader";
import LeftSideNavigationMenu from "../../CommonComponents/LeftSideNavigationMenu/LeftSideNavigationMenu";
import MyEnrollmentComponent from "../../CommonComponents/MyEnrollmentComponent/MyEnrollmentComponent";
import ProfileHeader from "../../CommonComponents/ProfileHeader/ProfileHeader";

const MyEnrollments = (props) => {
  return (
    <Fragment>
      <DefaultHeader />
      <ProfileHeader />
      <section className="stdnt_home_main">
        <div className="container">
          <div className="row mt-4 pl-1 pr-2">
            <LeftSideNavigationMenu />
            <MyEnrollmentComponent />
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default MyEnrollments;
