import React, { useEffect, useState } from "react";
import "./loader.css";
import classnames from "classnames";

export const LeaveBalanceLoader = () => {
  const [width, setWidth] = useState(getWidth());

  useEffect(() => {
    function handleResize() {
      setWidth(getWidth());
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function getWidth() {
    const { innerWidth: width } = window;
    return width;
  }

  return (
    <div className="ph-item pt-1">
      <div className="ph-col-12 mx-0 px-0">
        <div className="ph-row mx-0 px-0">
          <div
            className={classnames(
              "leave-loading-title",
              {
                "ph-col-12": width < 769,
              },
              {
                "ph-col-6": width > 768,
              }
            )}
          ></div>
          <div
            className={classnames(
              "empty big",
              { "ph-col-0": width < 769 },
              { "ph-col-2": width > 768 }
            )}
          ></div>
          <div
            className={classnames(
              "leave",
              { "ph-col-6": width < 769 },
              { "ph-col-2": width > 768 }
            )}
          ></div>
          <div
            className={classnames(
              "ml-1 big  apply-leave ",
              { "ph-col-6 ": width < 769 },
              { "ph-col-2 ": width > 768 }
            )}
          ></div>
        </div>
        <div className="ph-row mx-0 px-0 mt-2">
          <div
            className={classnames(
              "leave-loading-title",
              {
                "ph-col-12": width < 769,
              },
              {
                "ph-col-6": width > 768,
              }
            )}
          ></div>
          <div
            className={classnames(
              "empty big",
              { "ph-col-0": width < 769 },
              { "ph-col-2": width > 768 }
            )}
          ></div>
          <div
            className={classnames(
              "leave",
              { "ph-col-6": width < 769 },
              { "ph-col-2": width > 768 }
            )}
          ></div>
          <div
            className={classnames(
              "ml-1 big  apply-leave ",
              { "ph-col-6 ": width < 769 },
              { "ph-col-2 ": width > 768 }
            )}
          ></div>
        </div>
        <div className="ph-row mx-0 px-0 mt-2">
          <div
            className={classnames(
              "leave-loading-title",
              {
                "ph-col-12": width < 769,
              },
              {
                "ph-col-6": width > 768,
              }
            )}
          ></div>
          <div
            className={classnames(
              "empty big",
              { "ph-col-0": width < 769 },
              { "ph-col-2": width > 768 }
            )}
          ></div>
          <div
            className={classnames(
              "leave",
              { "ph-col-6": width < 769 },
              { "ph-col-2": width > 768 }
            )}
          ></div>
          <div
            className={classnames(
              "ml-1 big  apply-leave ",
              { "ph-col-6 ": width < 769 },
              { "ph-col-2 ": width > 768 }
            )}
          ></div>
        </div>
        {/* <div className="ph-row mx-0 px-0 mt-2">
        <div className="ph-col-6  leave-loading-title "></div>
        <div className="ph-col-2  empty big"></div>
        <div className="ph-col-2 leave"></div>
        <div className="ph-col-2 ml-1 big  apply-leave "></div>
      </div>
      <div className="ph-row mx-0 px-0 mt-2">
        <div className="ph-col-6  leave-loading-title "></div>
        <div className="ph-col-2  empty big"></div>
        <div className="ph-col-2 leave"></div>
        <div className="ph-col-2 ml-1 big  apply-leave "></div>
      </div> */}
      </div>
    </div>
  );
};
