import React, { Fragment, useEffect } from "react";
import DefaultHeader from "../../../DefaultLayout/DefaultHeader";
import { scrollToTop } from "../../../Helpers/ScrollToTop";
import LeftSideNavigationMenu from "../../CommonComponents/LeftSideNavigationMenu/LeftSideNavigationMenu";
import MyLeavesComponent from "../../CommonComponents/MyLeavesComponent/MyLeavesComponent";
import ProfileHeader from "../../CommonComponents/ProfileHeader/ProfileHeader";
export default function MyLeaves() {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <Fragment>
      <DefaultHeader />
      <ProfileHeader />
      <section className="stdnt_home_main">
        <div className="container">
          <div className="row mt-4 pl-1 pr-2">
            <LeftSideNavigationMenu />
            <MyLeavesComponent />
          </div>
        </div>
      </section>
    </Fragment>
  );
}
