import React, { Fragment } from "react";
import DefaultHeader from "../../../DefaultLayout/DefaultHeader";
import CourseDetailsComponent from "../../CommonComponents/CourseDetailsComponent/CourseDetailsComponent";
const CourseDetails = (props) => {
  return (
    <Fragment>
      <DefaultHeader />
      <CourseDetailsComponent />
    </Fragment>
  );
};
export default CourseDetails;
