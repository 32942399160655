import { combineReducers } from "redux";
import authReducer from "./authReducers";
import enrollmentReducer from "./enrollmentReducer";
import paymentHistoryReducer from "./paymentHistoryReducer";
import studentCourseReducer from "./studentCourseReducer";
import coachReducer from "./coachReducer";
import myLeavesReducer from "./myLeavesReducer";
import coachGiftsReducer from "./coachGiftsReducer";
import notificationReducer from "./notificationReducer";
import cmsReducers from "./cmsReducers";

export default combineReducers({
  auth: authReducer,
  all_courseList: studentCourseReducer,
  payment: paymentHistoryReducer,
  enrollment: enrollmentReducer,
  coachClass: coachReducer,
  leave: myLeavesReducer,
  coachGifts: coachGiftsReducer,
  notification: notificationReducer,
  cmsDetails: cmsReducers,
});
