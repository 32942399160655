import React, { useEffect, useState } from "react";
import "./loader.css";
import classnames from "classnames";

export const CoachGiftsLoader = ({ rows }) => {
  const [width, setWidth] = useState(getWidth());

  useEffect(() => {
    function handleResize() {
      setWidth(getWidth());
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function getWidth() {
    const { innerWidth: width } = window;
    return width;
  }

  const renderGiftRows = () => {
    const data = Array.from(Array(rows).keys(""));

    return data.map((c, i) => (
      <div className="ph-row mb-2" key={i}>
        <div className="ph-col-1 avatar"></div>
        <div
          className={classnames(
            " s-title",
            { "ph-col-8": width < 769 },
            { "ph-col-2": width > 768 }
          )}
        ></div>
        <div
          className={classnames(
            " s-desc",
            { "ph-col-12": width < 769 },
            { "ph-col-4": width > 768 }
          )}
        ></div>
        <div
          className={classnames(
            { "ph-col-0": width < 769 },
            { "ph-col-2 empty": width > 768 }
          )}
        ></div>
        <div className="ph-col-1 s-gift"></div>
        <div
          className={classnames(
            { "ph-col-0": width > 768 },
            { "ph-col-7 empty empty-sp": width < 769 }
          )}
        ></div>
        <div className="ph-col-1 s-btn"></div>
      </div>
    ));
  };
  return (
    <div className="ph-item px-0 mx-0">
      <div className="ph-col-12 pl-2 pr-0 mx-0">{renderGiftRows()}</div>
    </div>
  );
};
