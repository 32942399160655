import React, { Fragment, useEffect, useState } from "react";
import DefaultHeader from "../../../DefaultLayout/DefaultHeader";
import Breadcrumb from "../CommonComponents/Breadcrumb";
import checkgreen from "../../../assets/images/greencheck.svg";
import Banner2 from "../../../assets/images/Hero-IWL-2-800x500.jpg";
import "./detail.css";
import { useLocation, useParams } from "react-router-dom";
import CoachModal from "../CommonComponents/Modal";
import ProfileHeader from "../../CommonComponents/ProfileHeader/ProfileHeader";
import AttendanceModal from "../CommonComponents/AttendanceModal";
import { loadUser } from "../../../actions/authActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getCoachCourseDetails,
  getCourseClassSchedule,
} from "../../../actions/coachCourseAction";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";
import {
  CourseCardLoader,
  CourseDetailLoader,
} from "../../../components/ContentLoader/CourseCardLoader";
import TableLoader from "../../../components/ContentLoader/TableLoader";
import { scrollToTop } from "../../../Helpers/ScrollToTop";
import labels from "../../../labels.json";

function Details(routerProps) {
  const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

  const {
    course_Details,
    loadUser,
    auth,
    getCoachCourseDetails,
    getCourseClassSchedule,
    coachClass,
  } = routerProps;
  const { _id } = useParams();
  const location = useLocation();
  const path = location.pathname;

  const [openModal, setOpenModal] = useState(false);
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [openAttendanceModal, setOpenAttendanceModal] = useState(false);
  const [modaldate, setModaldate] = useState(null);
  const [modalFromTime, setModalFromTime] = useState(null);
  const [modalToTime, setModalToTime] = useState(null);
  const [chekFromDate, setChekFromDate] = useState(null);
  const [chektoDate, setChekToDate] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addDays(new Date(), 7));

  useEffect(() => {

    console.log(moment('2023-12-28T01:00:00.272Z').format('HH:mm:ss'))
    console.log(moment('2023-12-28T01:00:00.871Z').format('HH:mm:ss'))
    setLoadingResponse(true);
    getCoachCourseDetails(_id).then((res) => {
      let response = res.data.result.type;
      if (response === 1) {
        getCourseClassSchedule(response, _id, "", "");
      } else {
        setTableLoading(true);
        let fromDate = moment(startDate).format("YYYY-MM-DD");
        setChekFromDate(fromDate);
        let toDate = moment(endDate).format("YYYY-MM-DD");
        setChekToDate(toDate);
        getCourseClassSchedule(response, _id, fromDate, toDate).then((res) => {
          setTableLoading(false);
        });
      }
      setLoadingResponse(false);
    });
    scrollToTop();
    loadUser();
  }, []);

  const closeModal = () => {
    setOpenModal(false);
  };
  const Modaltoggle = (value) => {
    setModaldate(value);
    setOpenModal(true);
  };
  const closeAttendanceModal = () => {
    setOpenAttendanceModal(false);
  };

  const AttendanceModalToggle = (date, fromTime, toTime) => {
    setOpenAttendanceModal(true);
    setModaldate(date);
    setModalFromTime(fromTime);
    setModalToTime(toTime);
  };

  const onChnageDateRange = (date) => {
    const [start, end] = date;
    setStartDate(start);
    setEndDate(end);
    let fromDate = moment(start).format("YYYY-MM-DD");
    setChekFromDate(fromDate);
    let toDate = moment(end).format("YYYY-MM-DD");
    setChekToDate(toDate);
    if (end !== null) {
      setTableLoading(true);
      getCourseClassSchedule(2, _id, fromDate, toDate).then((res) => {
        setTableLoading(false);
      });
    }
  };

  const { courseDetails, Loading } = course_Details;
  const { getClassSchedule, classLoading, getTimeCLassSchedule } = coachClass;

  return (
    <Fragment>
      <DefaultHeader />
      {path === "/coach/notification" ||
      path === `/coach/course-details/${_id}` ? null : (
        <ProfileHeader />
      )}
      {path === `/coach/my-courses/details/${_id}` ? <Breadcrumb /> : null}
      <div className="container w-100">
        <div className="row w-100">
          <div className="col-md-12 col-md-12 col-sm-12 mt-4">
            {!loadingResponse && courseDetails !== {} && !Loading ? (
              <div className="stdnt_home_main_box px-3 px-md-4">
                <p className="font-18 pl-8">{courseDetails.title}</p>
                <div>
                  {path !== "/coach/details/regular" ? (
                    <img
                      src={courseDetails.image}
                      alt="banner1"
                      className="card-img-top custome-img-radius"
                    />
                  ) : (
                    <img
                      src={Banner2}
                      alt="banner2"
                      className="card-img-top custome-img-radius"
                    />
                  )}
                </div>

                <div className="col-md-12 ">
                  <h3 className="bld_secnd_title mb-3 pl-8">
                    {lbl ? lbl.aboutTheCourse : "About the course"}
                  </h3>
                </div>
                <div className="col-md-12 mt-2">
                  <p
                    className="color_gray gnrl_para mt-0 pl-8"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {courseDetails.description}
                  </p>
                </div>
                {path === `/coach/my-courses/details/${_id}` && (
                  <Fragment>
                    <h3 className="bld_secnd_title mt-4 pl-8">
                      {lbl ? lbl.totalClasses : "Total Classes"}
                    </h3>
                    <p className="color_gray gnrl_para pl-8">
                      {courseDetails.packageData &&
                      courseDetails.packageData !== {}
                        ? courseDetails.packageData.no_of_classes
                        : "Class are not scheduled"}
                    </p>
                    <div className="bld_secnd_title mt-4 mb-3 pl-8">
                      {lbl ? lbl.classesScheduled : "Classes scheduled"}

                      {courseDetails.type === 2
                        ? path !== "/coach/details/regular" && (
                            <span className="color_gray class_scheduled_bracket ">
                              {lbl
                                ? lbl.weeklyMessage
                                : "(Repeated weekly as per below schedule)"}
                            </span>
                          )
                        : ""}
                    </div>
                    {courseDetails.type === 2 ? (
                      <div className="col-md-3 col-sm-1 tg_picker">
                        <DatePicker
                          className="custm_datepicker tg_dropicon"
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          onChange={onChnageDateRange}
                          isClearable={false}
                          dateFormat="dd MMM yyyy"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col-lg-8 col-md-12 col-sm-12 col-12 mt-4">
                      <div className="table-responsive-sm pl-8">
                        <table className="table custome-tabel">
                          <thead>
                            <tr>
                              <th>{lbl ? lbl.date : "Date"}</th>
                              <th>{lbl ? lbl.day : "Day"}</th>
                              <th>{lbl ? lbl.time : "Time"}</th>
                              <th>
                                {lbl
                                  ? lbl.studentAttendance
                                  : "Student Attendance"}
                              </th>
                              <th>
                                {lbl ? lbl.myAttendance : "My Attendance"}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {courseDetails.type === 1 ? (
                              <Fragment>
                                {!tableLoading &&
                                getClassSchedule.docs.length > 0 &&
                                !classLoading ? (
                                  getClassSchedule.docs.map((class_list, i) => {
                                    return (
                                      <tr key={i}>
                                        <td>{class_list.date}</td>
                                        <td>{class_list.day}</td>
                                        <td>
                                          {moment(class_list.fromTime).format(
                                            "LT"
                                          )}
                                          &nbsp; - &nbsp;
                                          {moment(class_list.toTime).format(
                                            "LT"
                                          )}
                                        </td>
                                        <td>
                                          {class_list.attendance === true ? (
                                            <button
                                              className="blue_fill_btn "
                                              disabled={
                                                !moment(new Date()).isBetween(moment(class_list.date).format('YYYY/MM/DD')+' '+moment(class_list.fromTime).format('HH:mm:ss'), moment(class_list.date).format('YYYY/MM/DD')+' '+'23:00:00')
                                              }
                                              onClick={(e) =>
                                                AttendanceModalToggle(
                                                  class_list.date,
                                                  class_list.fromTime,
                                                  class_list.toTime
                                                )
                                              }
                                            >
                                              {lbl ? lbl.edit : "Edit"}
                                            </button>
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                        <td>
                                          {class_list.checkingData.length ===
                                          0 ? (
                                            <button
                                              className="blue_fill_btn"
                                              disabled={
                                                !moment(new Date()).isBetween(moment(class_list.date).format('YYYY/MM/DD')+' '+moment(class_list.fromTime).format('HH:mm:ss'), moment(class_list.date).format('YYYY/MM/DD')+' '+'23:00:00')
                                              }
                                              onClick={() =>
                                                Modaltoggle(class_list.date)
                                              }
                                            >
                                              {lbl ? lbl.checkIn : "Check-in"}
                                            </button>
                                          ) : (
                                            <>
                                              <span>
                                                <img
                                                  src={checkgreen}
                                                  className="tg_checked"
                                                  alt=""
                                                />
                                              </span>
                                              <span className="tg_checkin">
                                                {lbl
                                                  ? lbl.checkedIn
                                                  : "Checked-in"}
                                              </span>
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : classLoading || tableLoading ? (
                                  <tr>
                                    <TableLoader column={5} btnColumn={5} />
                                  </tr>
                                ) : getClassSchedule.docs.length === 0 &&
                                  !classLoading ? (
                                  <tr>
                                    <td colSpan={5}>
                                      {lbl
                                        ? lbl.noClassScheduleFound
                                        : "No Class Schedule found"}
                                    </td>
                                  </tr>
                                ) : null}
                              </Fragment>
                            ) : (
                              <Fragment>
                                {!tableLoading &&
                                getTimeCLassSchedule &&
                                getTimeCLassSchedule.length > 0 &&
                                !classLoading ? (
                                  getTimeCLassSchedule.map((class_list, i) => {
                                    return (
                                      <tr key={i}>
                                        <td>{class_list.date}</td>
                                        <td>{class_list.day}</td>
                                        <td>
                                          {moment(class_list.fromTime).format(
                                            "LT"
                                          )}
                                          &nbsp; - &nbsp;
                                          {moment(class_list.toTime).format(
                                            "LT"
                                          )}
                                        </td>
                                        <td>
                                          {class_list.attendance === true ? (
                                            <button
                                              className="blue_fill_btn "
                                              disabled={
                                                !moment(new Date()).isBetween(moment(class_list.date).format('YYYY/MM/DD')+' '+moment(class_list.fromTime).format('HH:mm:ss'), moment(class_list.date).format('YYYY/MM/DD')+' '+'23:00:00')
                                              }
                                              onClick={(e) =>
                                                AttendanceModalToggle(
                                                  class_list.date,
                                                  class_list.fromTime,
                                                  class_list.toTime
                                                )
                                              }
                                            >
                                              {lbl ? lbl.edit : "Edit"}
                                            </button>
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                        <td>
                                          {class_list.checkingData.length ===
                                          0 ? (
                                            <button
                                              className="blue_fill_btn"
                                              disabled={
                                                !moment(new Date()).isBetween(moment(class_list.date).format('YYYY/MM/DD')+' '+moment(class_list.fromTime).format('HH:mm:ss'), moment(class_list.date).format('YYYY/MM/DD')+' '+'23:00:00')
                                              }
                                              onClick={() =>
                                                Modaltoggle(class_list.date)
                                              }
                                            >
                                              {lbl ? lbl.checkIn : "Check-in"}
                                            </button>
                                          ) : (
                                            <span>
                                              <span>
                                                <img
                                                  src={checkgreen}
                                                  className="tg_checked"
                                                  alt=""
                                                />
                                              </span>
                                              <span className="tg_checkin">
                                                {lbl
                                                  ? lbl.checkedIn
                                                  : "Checked-in"}
                                              </span>
                                            </span>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : classLoading || tableLoading ? (
                                  <tr>
                                    <TableLoader column={5} btnColumn={5} />
                                  </tr>
                                ) : getTimeCLassSchedule.length === 0 &&
                                  !classLoading ? (
                                  <tr>
                                    <td colSpan={5}>
                                      {lbl
                                        ? lbl.noClassScheduleFound
                                        : "No Class Schedule found"}
                                    </td>
                                  </tr>
                                ) : null}
                              </Fragment>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Fragment>
                )}
              </div>
            ) : Loading || loadingResponse ? (
              path === `/coach/my-courses/details/${_id}` ? (
                <div className="col-xl-12 col-lg-12 col-md-12 mt-5">
                  <CourseCardLoader />
                </div>
              ) : (
                <div className="col-xl-12 col-lg-12 col-md-12 mt-5">
                  <CourseDetailLoader />
                </div>
              )
            ) : courseDetails === {} && !Loading ? (
              <div className="col-xl-12 col-lg-12 col-md-12 mt-5">
                <div className="col tg-display tg-content">
                  {lbl ? lbl.myAttendance : "No Course Details found"}
                </div>
              </div>
            ) : null}
            <CoachModal
              showModal={openModal}
              coachtype={auth.user.type}
              coursetype={courseDetails.type}
              checkIndate={modaldate}
              closeModal={closeModal}
              checkFromDate={chekFromDate}
              checkToDate={chektoDate}
              _id={_id}
            />
            <AttendanceModal
              showModal={openAttendanceModal}
              closeModal={closeAttendanceModal}
              editDate={modaldate}
              fromTime={modalFromTime}
              toTime={modalToTime}
              checkFromDate={chekFromDate}
              checkToDate={chektoDate}
              type={courseDetails.type}
              _id={_id}
            />
          </div>
        </div>
      </div>
      {/* <DefaultFooter /> */}
    </Fragment>
  );
}

Details.propTypes = {
  loadUser: PropTypes.func.isRequired,
  getCoachCourseDetails: PropTypes.func.isRequired,
  getCourseClassSchedule: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  course_Details: state.all_courseList,
  auth: state.auth,
  coachClass: state.coachClass,
});

export default connect(mapStateToProps, {
  loadUser,
  getCoachCourseDetails,
  getCourseClassSchedule,
})(Details);
