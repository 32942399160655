import React, {useState, Fragment, useEffect} from "react";
import {Redirect, useLocation, useHistory} from "react-router-dom";
import {Modal, ModalBody,FormGroup,Input,Label} from "reactstrap";
import RegisterSuccess from "../../../assets/images/Successful.svg";
import {userRegistration} from "../../../actions/authActions";
import PropTypes from "prop-types";
import {connect, useDispatch, useSelector} from "react-redux";
import {
    checkEmailValidation,
    checkRequiredValidationWithMinMax,
    checkMobileNumberValidation,
    checkDropdownValidation,
} from "../../../Helpers/Validation";
import {toast} from "react-toastify";
import ToastifyContainer from "../../../components/Toastify/ToastContainer";
import InputField from "../../../components/InputField/InputField";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import classnames from "classnames";
import labels from "../../../labels.json";
import {bindActionCreators} from "redux";
import * as cmsActions from "../../../actions/cmsAction";
import parse from "html-react-parser";
import DateSelect from "../../../components/DateSelect";
import moment from "moment";

const StudentRegisterInput = (props) => {
    const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

    const {userRegistration, auth} = props;
    const dispatch = useDispatch();
    const { getContentDetails } = bindActionCreators(cmsActions, dispatch);
    const {pathname} = useLocation();
    const initialPath = pathname.split("/")[1];
    const [openRegisterModal, setOpenRegisterModal] = useState(false);
    const [openChartModal, setOpenChartModal] = useState(false);
    const [openUserAgreementModal, setOpenUserAgreementModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [agreementCheck, setAgreementCheck] = useState(false);
    const { cmsDetails, cmsLoading } = useSelector((state) => state.cmsDetails);
    const [registerData, setregisterData] = useState({
        first_name: "",
        last_name: "",
        gender: '',
        email: "",
        phone: "",
        password: "",
        confirm_password: "",
        emergency_name: "",
        emergency_email: "",
        emergency_phone_number: "",
        size: "",
        select_size_chart: "",
        birth_date:'',
        birth_year:'',
        birth_month:'',
        birth_day:'',
    });

    const [errors, setErrors] = useState({
        error_first_name: "",
        error_last_name: "",
        error_phone: "",
        error_email: "",
        error_password: "",
        error_confirm_password: "",
        error_emergency_name: "",
        error_emergency_email: "",
        error_emergency_phone_number: "",
        error_size: "",
        error_birth_date:'',
        err_user_agreement:'',
        error_gender:'',
    });

    useEffect(() => {
        let fetchApi = async () => {
            let info = "user-agreement";
            await getContentDetails(info);
        };
        fetchApi();
    }, []);

    const onBirthDateChange = (date) => {
       setregisterData({
           ...registerData,
           birth_year: date.getFullYear(),
           birth_month: (1+date.getMonth()),
           birth_day: (date.getDate()),
           birth_date: moment(date).format("YYYY-MM-DD")
       })
    };
    const onBirthDateOk = (value) => {
        console.log('onOk: ', value);
    };

    const onCheckAgreement = (e) => {
        setAgreementCheck(!agreementCheck)
    }
    const onClickCheckAgreement = (e) => {
        setOpenUserAgreementModal(true);
    }

    const renderRegisterSuccessModal = (e) => {
        return (
            <Modal isOpen={openRegisterModal}>
                <ModalBody className="modal-body w-100 float-left text-center pt-5 pb-5">
                    <img src={RegisterSuccess} className="register-success-img" alt=""/>
                    <p className="secnd_title mt-3">
                        {lbl ? lbl.registerSuccess : " You are registered successfully."}
                    </p>
                    <div className="row w-100">
                        <div className="col-md-12 text-center">
                            <button
                                type="button"
                                className="btn  blue_fill_btn btn_ok px-5"
                                onClick={(e) => onRegisterSuccess(e)}
                            >
                                {lbl ? lbl.ok : "Ok"}
                            </button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        );
    };

    const renderSizeChartModal = (e) => {
        return (
            <Fragment>
                <Modal size="lg" isOpen={openChartModal}>
                    <ModalBody className="modal-body  modal-body-container  ">
                        <div className="col-md-12">
                            <h3 className="modal-label w-100">
                                {lbl ? lbl.uniformSizeChart : "Uniform size chart"}
                            </h3>
                        </div>
                        <div className="col-md-12 mt-5">
                            <table className="class-schedule-table">
                                <thead>
                                <tr>
                                    <th>{lbl ? lbl.size : "Size"}</th>
                                    <th>{lbl ? lbl.chest : "Chest (in)"}</th>
                                    <th>{lbl ? lbl.frontLength : "Front Length (in)"}</th>
                                    <th>{lbl ? lbl.acrossShoulder : "Across Shoulder (in)"}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{lbl ? lbl.xs : "XS"}</td>
                                    <td>38.0</td>
                                    <td>29.0</td>
                                    <td>18.0</td>
                                </tr>
                                <tr>
                                    <td>{lbl ? lbl.s : "S"}</td>
                                    <td>40.0</td>
                                    <td>29.0</td>
                                    <td>18.5</td>
                                </tr>
                                <tr>
                                    <td>{lbl ? lbl.m : "M"}</td>
                                    <td>42.0</td>
                                    <td>29.0</td>
                                    <td>18.8</td>
                                </tr>
                                <tr>
                                    <td>{lbl ? lbl.l : "L"}</td>
                                    <td>44.0</td>
                                    <td>29.5</td>
                                    <td>19.3</td>
                                </tr>
                                <tr>
                                    <td>{lbl ? lbl.xl : "XL"}</td>
                                    <td>46.0</td>
                                    <td>30.0</td>
                                    <td>19.5</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="w-100 text-center">
                            <button
                                type="button"
                                className="btn blue_fill_btn w-25 mt-4 text-right"
                                onClick={() => {
                                    setOpenChartModal(false);
                                }}
                            >
                                {lbl ? lbl.ok : "Ok"}
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>
        );
    };

    const renderUserAgreementModal = (e) => {
        return (
            <Fragment>
                <Modal size="lg" isOpen={openUserAgreementModal}>
                    <ModalBody className="modal-body  modal-body-container  ">
                        <div className="col-md-12">
                            <h3 className="modal-label w-100" style={{textAlign:'center'}}>
                                {cmsDetails && !cmsLoading ? (
                                        cmsDetails.title
                                    ):null}

                            </h3>
                        </div>
                        <div className="col-md-12">
                            {cmsDetails && !cmsLoading ? parse(`${cmsDetails.content}`) : null}
                        </div>
                        <div className="w-100 text-center">
                            <button
                                type="button"
                                className="btn blue_fill_btn w-25 mt-4 text-right"
                                onClick={() => {
                                    setAgreementCheck(true);
                                    setOpenUserAgreementModal(false);

                                }}
                            >
                                {lbl ? lbl.ok : "Ok"}
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>
        );
    };

    const onHandleChange = (e) => {
        setregisterData({
            ...registerData,
            [e.target.name]: e.target.value,
        });
    };

    const history = useHistory();

    const onRegisterClick = (e) => {
        let {
            emergency_name,
            emergency_email,
            emergency_phone_number,
            size,
            first_name,
            last_name,
            email,
            phone,
            password,
            confirm_password,
            birth_date,
            birth_year,
            birth_month,
            birth_day,
            gender
        } = registerData;
        let errFname = null;
        let errLname = null;
        let errEmail = null;
        let errPhone = null;
        let errPassword = null;
        let errConfPassword = null;
        let errEmName = null;
        let errEmEmail = null;
        let errEmNumber = null;
        let errUniformSize = null;
        let errBirthDate = null;
        let errUserAgreement = null;
        let errGender = null;
        let validationFlag = true;

        let fNameLbl = "First name";
        let lNameLbl = "Last name";
        let nameLbl = "Name";
        let emailLbl = "Email ";
        let phoneLbl = "Phone ";
        let uniformLbl = "Uniform Size ";
        let passwordLbl = "Password ";
        let mobileLbl = "Mobile number";
        let confPasswordLbl = "Confirm Password ";
        let errConfPasswordLbl = "Confirm password doesn't match with password ";
        let genderLbl = "Gender";

        if (lbl && lbl.fName) fNameLbl = lbl.fName;
        if (lbl && lbl.lName) lNameLbl = lbl.lName;
        if (lbl && lbl.name) nameLbl = lbl.name;
        if (lbl && lbl.oEmail) emailLbl = lbl.oEmail;
        if (lbl && lbl.oPhone) phoneLbl = lbl.oPhone;
        if (lbl && lbl.uniformSize) uniformLbl = lbl.uniformSize;
        if (lbl && lbl.password) passwordLbl = lbl.password;
        if (lbl && lbl.mobileNumber) mobileLbl = lbl.mobileNumber;
        if (lbl && lbl.confPassword) confPasswordLbl = lbl.confPassword;
        if (lbl && lbl.errConfPasswordLbl)
            errConfPasswordLbl = lbl.errConfPasswordLbl;
        if (lbl && lbl.gender) genderLbl = lbl.gender;

        errFname = checkRequiredValidationWithMinMax(first_name, fNameLbl, 1, 1000);
        errEmName = checkRequiredValidationWithMinMax(
            emergency_name,
            nameLbl,
            1,
            1000
        );

        errLname = checkRequiredValidationWithMinMax(last_name, lNameLbl, 1, 1000);
        errEmEmail = checkEmailValidation(emergency_email, emailLbl);
        errEmail = checkEmailValidation(email, emailLbl);
        errPhone = checkMobileNumberValidation(phone, phoneLbl);
        errUniformSize = checkDropdownValidation(size, uniformLbl);
        errGender = checkDropdownValidation(gender, genderLbl);
        errPassword = checkRequiredValidationWithMinMax(
            password,
            passwordLbl,
            6,
            12
        );
        errEmNumber = checkMobileNumberValidation(
            emergency_phone_number,
            mobileLbl
        );

        errConfPassword = checkRequiredValidationWithMinMax(
            confirm_password,
            confPasswordLbl,
            6,
            12
        );
        if (password !== confirm_password) errConfPassword = errConfPasswordLbl;
        if(!birth_date)
            errBirthDate = lbl.err_birth_date;
        // else{
        //     let dd = birth_date.getFullYear()+'-'+(birth_date.getMonth()+1)+"-"+birth_date.getDate();
        //     birth_date = dd;
        // }

        if(!agreementCheck) errUserAgreement = lbl.err_user_agreement

        if (errFname === "名需要") {
            errFname = lbl.pleaseEnterName;
        }
        if (errLname === "姓需要") {
            errLname = lbl.pleaseEnterLastName;
        }
        if (errPassword === "密碼需要") {
            errPassword = lbl.pleaseEnterPassword;
        }
        if (errConfPassword === "確認密碼需要") {
            errConfPassword = lbl.pleaseEnterConfPwd;
        }
        if (errEmName === "名稱需要") {
            errEmName = lbl.pleaseEnterAName;
        }
        if (errEmNumber === "此欄必須填寫數字") {
            errEmNumber = lbl.pleaseEnterYourPhone;
        }
        if (errUniformSize === "Please select 球衣尺寸") {
            errUniformSize = lbl.selectUniform;
        }
        if (errGender === "Please select 性別") {
            errGender = lbl.selectGender;
        }
        
        if (
            errFname ||
            errLname ||
            errEmail ||
            errPhone ||
            errPassword ||
            errConfPassword ||
            errEmName ||
            errEmEmail ||
            errEmNumber ||
            errUniformSize||
            errBirthDate||
            errUserAgreement||
            errGender
        ) {
            validationFlag = false;
            setErrors({
                error_emergency_name: errEmName,
                error_emergency_email: errEmEmail,
                error_emergency_phone_number: errEmNumber,
                error_size: errUniformSize,
                error_first_name: errFname,
                error_last_name: errLname,
                error_email: errEmail,
                error_phone: errPhone,
                error_password: errPassword,
                error_confirm_password: errConfPassword,
                error_birth_date: errBirthDate??"",
                err_user_agreement: errUserAgreement??"",
                error_gender: errGender??"",
            });
        } else {
            validationFlag = true;
            setErrors({
                error_first_name: "",
                error_last_name: "",
                error_email: "",
                error_phone: "",
                error_password: "",
                error_confirm_password: "",
                error_emergency_name: "",
                error_emergency_email: "",
                error_emergency_phone_number: "",
                error_size: "",
                error_birth_date: "",
                err_user_agreement: "",
                error_gender: "",
            });
        }

        if (validationFlag) {
            console.log('gender', gender)
            let date =

            setLoader(true);
            userRegistration({
                emergency_name,
                emergency_email,
                emergency_phone_number,
                size,
                first_name,
                last_name,
                email,
                phone,
                password,
                confirm_password,
                birth_date,
                birth_year,
                birth_month,
                birth_day,
                gender
            } )
                .then((res) => {
                    toast.success(res.data.message);
                    setregisterData({
                        first_name: "",
                        last_name: "",
                        email: "",
                        phone: "",
                        password: "",
                        confirm_password: "",
                        emergency_name: "",
                        emergency_email: "",
                        emergency_phone_number: "",
                        size: "",
                        select_size_chart: "",
                        birth_date: "",
                        err_user_agreement: "",
                        error_gender: "",
                    });
                    setOpenRegisterModal(true);
                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);
                })
                .catch((err) => {
                    setLoader(false);
                    setErrors({
                        error_first_name: "",
                        error_last_name: "",
                        error_phone: "",
                        error_password: "",
                        error_confirm_password: "",
                        error_emergency_name: "",
                        error_emergency_phone_number: "",
                        error_size: "",
                        // error_email: typeof err.response.data !=='undefined'? err.response.data.message :'Network error',
                        error_email: err.response.status == 409 ? err.response.data.message : '',
                        error_emergency_email: "",
                        error_birth_date: err.response.status == 600 ? err.response.data.message : '',
                        err_user_agreement: "",
                        error_gender: "",
                    });
                    err.message && toast.error(err.message);
                });
        }
    };

    const onRegisterSuccess = (e) => {
        e.preventDefault();
        setOpenRegisterModal(false);
        setTimeout(() => {
            history.push("/student/login");
        }, 2000);
    };

    const {isAuthenticated} = auth;
    if (isAuthenticated) {
        return <Redirect to="/"/>;
    }

    return (
        <Fragment>
            <div className="col-lg-5 col-md-6 mt-3">
                <div className="row">
                    <ToastifyContainer/>
                    <div className="col-md-12">
                        <h2 className="main_title">
                            {initialPath === "student" ? (
                                <span>
                  {lbl ? lbl.registerAsStudent : "Register as Student"}
                </span>
                            ) : (
                                <span>{lbl ? lbl.registerAsCoach : "Register as Coach"}</span>
                            )}
                        </h2>
                        <p className="title_para">
                            {lbl
                                ? lbl.registerDesc
                                : " It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."}
                        </p>
                    </div>
                    <div className="col-md-12 mt-4">
                        <h3 className="secnd_title title_without_border">
                            {lbl ? lbl.register : "Register"}
                        </h3>
                    </div>
                    <div className="w-100">
                        <span className="blue_border register_border_width_blue"></span>
                        <span className="gray_border register_border_width_gray"></span>
                    </div>
                    <div className="col-md-6 mt-4">
                        <InputField
                            inputType="text"
                            inputName="first_name"
                            placeHolder={lbl ? lbl.firstName : "Enter First name"}
                            errorValue={errors.error_first_name}
                            disable={loader}
                            onInputChange={(e) => onHandleChange(e)}
                            value={registerData.first_name}
                        />
                    </div>
                    <div className="col-md-6 mt-4">
                        <InputField
                            inputType="text"
                            inputName="last_name"
                            placeHolder={lbl ? lbl.lastName : "Enter Last name"}
                            errorValue={errors.error_last_name}
                            disable={loader}
                            onInputChange={(e) => onHandleChange(e)}
                            value={registerData.last_name}
                        />
                    </div>
                    <div className="col-md-12 mt-4">
                        <select
                            className={classnames(
                                "form-select select-box-shadow ",
                                {invalid: errors.error_gender.length > 0},
                                {
                                    "valid ": errors.error_gender.length === 0,
                                }
                            )}
                            value={registerData.gender}
                            name="gender"
                            disabled={loader}
                            onChange={(e) => onHandleChange(e)}
                        >
                            <option value={''}>{lbl ? lbl.gender : "gender"}</option>
                            <option value={1}>{lbl ? lbl.male : "Male"}</option>
                            <option value={2}>{lbl ? lbl.female : "Female"}</option>
                        </select>
                        {errors.error_gender ? (
                            <span className="invalid-text ">{errors.error_gender}</span>
                        ) : null}
                    </div>
                    <div className="col-md-12 mt-4">
                        <DateSelect
                            className={classnames(
                                { invalid: errors.error_birth_date.length > 0 },
                                { valid: errors.error_birth_date.length === 0 }
                            )}
                            errorValue={errors.error_birth_date}
                            yearText={'出生年'}
                            monthText={'出生月'}
                            dayText={'出生日'}
                            year={registerData.birth_year}
                            month={registerData.birth_month}
                            day={registerData.birth_day}
                            onChange={(date) => {
                                onBirthDateChange(date)
                            }}
                            />
                        {errors.error_birth_date ? <span className="invalid-text">{errors.error_birth_date}</span> : null}

                    </div>
                    <div className="col-md-12 mt-4">
                        <InputField
                            inputType="tel"
                            inputName="phone"
                            placeHolder={lbl ? lbl.enterPhone : "Enter your phone"}
                            errorValue={errors.error_phone}
                            disable={loader}
                            onInputChange={(e) => onHandleChange(e)}
                            value={registerData.phone}
                        />
                    </div>
                    <div className="col-md-12 mt-4">
                        <InputField
                            inputType="email"
                            inputName="email"
                            placeHolder={lbl ? lbl.enterEmail : "Enter your email"}
                            errorValue={errors.error_email}
                            disable={loader}
                            onInputChange={(e) => onHandleChange(e)}
                            value={registerData.email}
                        />
                    </div>
                    <div className="col-md-12 mt-4">
                        <InputField
                            inputType="password"
                            inputName="password"
                            placeHolder={lbl ? lbl.enterPassword : "Enter your password"}
                            errorValue={errors.error_password}
                            disable={loader}
                            onInputChange={(e) => onHandleChange(e)}
                            value={registerData.password}
                        />
                    </div>
                    <div className="col-md-12 mt-4">
                        <InputField
                            inputType="password"
                            inputName="confirm_password"
                            placeHolder={lbl ? lbl.confPassword : "Enter Confirm Password"}
                            errorValue={errors.error_confirm_password}
                            disable={loader}
                            onInputChange={(e) => onHandleChange(e)}
                            value={registerData.confirm_password}
                        />
                    </div>

                    <div className="col-md-12 mt-4">
                        <h3 className="secnd_title">
                            {lbl ? lbl.emergencyContact : "Emergency contact"}
                        </h3>
                    </div>
                    <div className="col-md-12 mt-4">
                        <InputField
                            inputType="text"
                            inputName="emergency_name"
                            placeHolder={lbl ? lbl.name : "Enter Name"}
                            errorValue={errors.error_emergency_name}
                            disable={loader}
                            onInputChange={(e) => onHandleChange(e)}
                            value={registerData.emergency_name}
                        />
                    </div>
                    <div className="col-md-12 mt-4">
                        <InputField
                            inputType="text"
                            inputName="emergency_email"
                            placeHolder={lbl ? lbl.email : "Enter Email"}
                            errorValue={errors.error_emergency_email}
                            disable={loader}
                            onInputChange={(e) => onHandleChange(e)}
                            value={registerData.emergency_email}
                        />
                    </div>
                    <div className="col-md-12 mt-4">
                        <InputField
                            inputType="tel"
                            inputName="emergency_phone_number"
                            placeHolder={lbl ? lbl.phone : "Enter Phone number"}
                            errorValue={errors.error_emergency_phone_number}
                            disable={loader}
                            onInputChange={(e) => onHandleChange(e)}
                            value={registerData.emergency_phone_number}
                        />
                    </div>
                    <div className="col-md-12 mt-4">
                        <h3 className="secnd_title ">
                            {lbl ? lbl.uniformSize : " Uniform size"}
                        </h3>
                    </div>
                    <div className="col-md-12 mt-4">
                        <select
                            className={classnames(
                                "form-select select-box-shadow ",
                                {invalid: errors.error_size.length > 0},
                                {
                                    "valid ": errors.error_size.length === 0,
                                }
                            )}
                            value={registerData.size}
                            name="size"
                            disabled={loader}
                            onChange={(e) => onHandleChange(e)}
                        >
                            <option value="">{lbl ? lbl.select : "Select"}</option>
                            <option value="XS (Kids)">{lbl ? lbl.xsK : "XS (Kids)"}</option>
                            <option value="S (Kids)">{lbl ? lbl.sK : "S (Kids)"}</option>
                            <option value="M (Kids)">{lbl ? lbl.mK : "M (Kids)"}</option>
                            <option value="L (Kids)">{lbl ? lbl.lK : "L (Kids)"}</option>
                            <option value="XL (Kids)">{lbl ? lbl.xlK : "XL (Kids)"}</option>
                            <option value="XS (Youth)">{lbl ? lbl.xsY : "XS (Youth)"}</option>
                            <option value="S (Youth)">{lbl ? lbl.sY : "S (Youth)"}</option>
                            <option value="M (Youth)">{lbl ? lbl.mY : "M (Youth)"}</option>
                            <option value="L (Youth)">{lbl ? lbl.lY : "L (Youth)"}</option>
                            <option value="XL (Youth)">{lbl ? lbl.xlY : "XL (Youth)"}</option>
                            <option value="XS (Adult)">{lbl ? lbl.xsA : "XS (Adult)"}</option>
                            <option value="S (Adult)">{lbl ? lbl.sA : "S (Adult)"}</option>
                            <option value="M (Adult)">{lbl ? lbl.mA : "M (Adult)"}</option>
                            <option value="L (Adult)">{lbl ? lbl.lA : "L (Adult)"}</option>
                            <option value="XL (Adult)">{lbl ? lbl.xlA : "XL (Adult)"}</option>
                        </select>
                        {errors.error_size ? (
                            <span className="invalid-text ">{errors.error_size}</span>
                        ) : null}
                    </div>
                    <div className="col-md-12 mt-4">
            <span
                className=" size-chart"
                onClick={(e) => setOpenChartModal(true)}
            >
              {lbl ? lbl.sizeChart : " View size chart"}
            </span>
                    </div>
                    <div className="col-md-12 mt-4">
                        <FormGroup
                            className={classnames(
                                { invalid: errors.err_user_agreement.length > 0 },
                                { valid: errors.err_user_agreement.length === 0 }
                            )}
                            check>
                            <Input type="checkbox" onChange={onCheckAgreement}  checked={agreementCheck}/>
                            {' '}
                            <Label check>
                                {lbl ? lbl.check_user_agreement : ''}
                                <button onClick={(e) => onClickCheckAgreement(e)} style={{textDecorationLine: "underline",borderWidth:'0'}}>{lbl ? lbl.user_agreement : ''}</button>
                            </Label>
                        </FormGroup>
                        {errors.err_user_agreement ? <span className="invalid-text">{errors.err_user_agreement}</span> : null}
                    </div>
                    <div className="col-md-12 mt-4">
                        <ButtonComponent
                            loading={loader}
                            title={lbl ? lbl.register : " Register"}
                            onBtnClick={(e) => onRegisterClick(e)}
                        />
                    </div>
                </div>
                {/* --------------- register success modal --------------- */}
                {renderRegisterSuccessModal()}
                {/* --------------- size chart modal --------------- */}
                {renderSizeChartModal()}
                {renderUserAgreementModal()}
            </div>
        </Fragment>
    );
};

StudentRegisterInput.propTypes = {
    auth: PropTypes.object.isRequired,
    userRegistration: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {
    userRegistration,
})(StudentRegisterInput);
