import React, { Fragment } from "react";
import "./defaultLayout.css";
import logo from "../assets/images/logo.jpg";
import noProfile from "../assets/images/no-profile.png";
import notificationIcon from "../assets/images/bell-icon.png";
import MobileMenu from "../components/MobileMenu/Index";
import PropTypes from "prop-types";
import { Link, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import labels from "../labels.json";

const DefaultHeader = (routerProps) => {
    const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

  const { code, _id } = useParams();
  const { pathname } = useLocation();
  const initialPath = pathname.split("/")[1];
  const { auth } = routerProps;
  const renderLoginButton = () => {
    return (
      <Link to={initialPath === "student" ? "/student/login" : "/coach/login"}>
        <button className="blue_fill_btn float-right btn_login mt-2 ">
          {lbl ? lbl.login : "Login"}
        </button>
      </Link>
    );
  };

  const renderLoginAsCoachButton = () => {
    return (
      <Link to="/coach/login">
        <button className="brder_btn login_as_coach_btn mt-2 mx-md-2   ">
          {lbl ? lbl.loginAsCoach : "Login as coach"}
        </button>
      </Link>
    );
  };

  const renderLoginAsStudentButton = () => {
    return (
      <Link to="/student/login">
        <button className="brder_btn login_as_coach_btn mt-2  ml-md-2   ">
          {lbl ? lbl.loginAsStudent : "Login as student"}
        </button>
      </Link>
    );
  };

  const renderStudentRegisterButton = () => {
    return (
      <Link to="/student/register">
        <button className="brder_btn  mt-2">
          {lbl ? lbl.register : "Register"}
        </button>
      </Link>
    );
  };
  return (
    <Fragment>
      <header>
        <div className="container ">
          <div className="row">
            <div className="col-lg-2 col-md-12 col-sm-12 main_logo register-btn">
              <Link to="/">
                <img
                  src={logo}
                  className="main_logo"
                  alt="Inter academy logo"
                />
              </Link>
            </div>
            <div className="col-lg-5 col-md-4 col-sm-2 col-2">
              <nav className="desktop_menu">
                <ul>
                  <li>
                    <Link
                      to={
                        pathname === "/" ||
                        pathname === "/login-category" ||
                        pathname === "/student/login" ||
                        pathname === "/student/register" ||
                        pathname === "/forgot-password" ||
                        pathname === "/coach/login" ||
                        pathname === "/forgot-password" ||
                        pathname === `/reset-password/${code}`
                          ? "/"
                          : initialPath === "student"
                          ? "/student/course"
                          : initialPath === "coach"
                          ? "/coach/course-list"
                          : "/"
                      }
                    >
                      {lbl ? lbl.home : "Home"}
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-us">{lbl ? lbl.aboutUs : "About Us"}</Link>
                  </li>
                  <li>
                    <Link to="/faq">{lbl ? lbl.faq : "FAQ"}</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">
                      {lbl ? lbl.privacyPolicy : " Privacy policy"}
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact-us">
                      {lbl ? lbl.contactUs : "Contact us"}
                    </Link>
                  </li>
                </ul>
              </nav>

              <div className="mobile_menu">
                <MobileMenu />
              </div>
            </div>
            <div className="col-lg-5 col-md-8 col-sm-10 col-10 text-right header-btn header-text">
              <div className="text">All rights reserved by Man Lap International Sports Group Co. Limited</div>
              {pathname === "/student/login" ? (
                <Fragment>
                  <Link to="/student/register">
                    <button className="brder_btn register_btn mt-2">
                      {lbl ? lbl.register : "Register"}
                    </button>
                  </Link>
                  {renderLoginAsCoachButton()}
                </Fragment>
              ) : null}
              {pathname === "/coach/login" ? (
                <Fragment>{renderLoginAsStudentButton()}</Fragment>
              ) : null}
              {pathname === "/" ||
              pathname === "/about-us" ||
              pathname === "/contact-us" ||
              pathname === "/faq" ||
              pathname === "/privacy-policy" ? (
                <Fragment>
                  {auth.isAuthenticated && !auth.loading && (
                    <div className="header_right_side">
                      <span className="header_right_notification">
                        <Link
                          to={
                            auth.user.role === 1
                              ? "/student/notification"
                              : "/coach/notification"
                          }
                        >
                          <img
                            src={notificationIcon}
                            alt="notification"
                            className="notification-icon"
                          />
                        </Link>
                      </span>
                      <span className="header_right_profile">
                        <Link
                          to={
                            auth.user.role === 1
                              ? "/student/my-enrollments"
                              : "/coach/my-courses"
                          }
                        >
                          <img
                            src={
                              auth.user && auth.user.profile_pic
                                ? auth.user.profile_pic
                                : noProfile
                            }
                            alt="profile"
                          />
                        </Link>
                      </span>
                    </div>
                  )}
                  {!auth.isAuthenticated && !auth.loading && (
                    <Link to="/login-category">
                      <button className="brder_btn register_btn mt-1">
                        {lbl ? lbl.login : "Login"}
                      </button>
                    </Link>
                  )}
                </Fragment>
              ) : null}
              {pathname === "/student/register" ||
              pathname === "/coach/register" ? (
                <Fragment>{renderLoginButton()}</Fragment>
              ) : null}
              {pathname === "/forgot-password" ||
              pathname === `/reset-password/${code}` ||
              pathname === "/forgot-password" ||
              pathname === `/reset-password/${code}` ? (
                <Fragment>
                  {renderLoginButton()} {renderStudentRegisterButton()}
                </Fragment>
              ) : null}
              {pathname === "/student/course" ||
              pathname === `/student/course/details/${_id}` ||
              pathname === "/student/my-enrollments" ||
              pathname === "/student/my-gifts" ||
              pathname === "/student/my-leaves" ||
              pathname === "/student/payment-history" ||
              pathname === "/student/update-profile" ||
              pathname === "/student/notification" ||
              pathname === "/coach/course-list" ||
              pathname === `/coach/course-details/${_id}` ||
              pathname === "/coach/gifts" ||
              pathname === "/coach/my-courses" ||
              pathname === `/coach/my-courses/details/${_id}` ||
              pathname === "/coach/details/regular" ||
              pathname === "/coach/update-profile" ||
              pathname === "/coach/notification" ? (
                <div className="header_right_side">
                  <span className="header_right_notification">
                    <Link
                      to={
                        initialPath === "student"
                          ? "/student/notification"
                          : "/coach/notification"
                      }
                    >
                      <img
                        src={notificationIcon}
                        alt="notification"
                        className="notification-icon"
                      />
                    </Link>
                  </span>
                  <span className="header_right_profile">
                    <Link
                      to={
                        initialPath === "student"
                          ? "/student/my-enrollments"
                          : "/coach/my-courses"
                      }
                    >
                      <img
                        src={
                          auth.user && auth.user.profile_pic
                            ? auth.user.profile_pic
                            : noProfile
                        }
                        alt="profile"
                      />
                    </Link>
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </header>
    </Fragment>
  );
};

DefaultHeader.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(DefaultHeader);
