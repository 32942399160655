import React, { Fragment, useEffect, useState } from "react";
import "./myLeavess.css";
import { Modal, ModalBody, Button } from "reactstrap";
import {
  applyLeave,
  getAvailableLeaveBalance,
  getClassFromCourse,
  getLeaveHistory,
} from "../../../actions/leaveBalanceAction";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import LeaveHistoryTable from "./LeaveHistoryTable";
import { LeaveBalanceLoader } from "../../../components/ContentLoader/LeaveBalanceLoader";
import moment from "moment";
import { toast } from "react-toastify";
import ToastifyContainer from "../../../components/Toastify/ToastContainer";
import { checkEmptyValidation } from "../../../Helpers/Validation";
import classnames from "classnames";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import labels from "../../../labels.json";

const MyLeavesComponent = (props) => {
  const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

  const {
    getAvailableLeaveBalance,
    leaves,
    getClassFromCourse,
    applyLeave,
    getLeaveHistory,
  } = props;

  const { leaveBalance, leaveBalanceLoading, classList } = leaves;

  useEffect(() => {
    getAvailableLeaveBalance();
  }, []);

  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedReason, setSelectedReason] = useState('');
  const [selectedDate, setSelectedDate] = useState("");
  const [comment, setComment] = useState("");
  const [comment_error, setCommentError] = useState("");
  const [date_error, setDateError] = useState("");
  const [reason_error, setReasonError] = useState("");
  const [loader, setLoader] = useState(false);
  const [profile_pic, setProfilePic] = useState("");
  const [profile_pic_preview, setProfilePicPreview] = useState("");
  const [error_profile_pic, setErrorProfilePic] = useState("");

  const onApplyLeaveClick = (e, leave) => {
    setOpenStatusModal(true);
    setSelectedLeave(leave);
    setSelectedCourse(leave._id);
    getClassFromCourse(leave._id);
  };

  //  ---------- Apply Leave Now click ----------
  const onApplyNowClick = (e) => {
    let errComment = null;
    let errDate = null;
    let errReason = null;
    let errProfilePic = null;

    let commentLbl = "Comment ";
    let classDateLbl = "Class date ";
    let reasonLbl = "Reason for leave ";

    if (lbl && lbl.comment) commentLbl = lbl.comment;
    if (lbl && lbl.classDate) classDateLbl = lbl.classDate;
    if (lbl && lbl.reasonForTakenLeave) reasonLbl = lbl.reasonForTakenLeave;

    errComment = checkEmptyValidation(comment, commentLbl);
    errDate = checkEmptyValidation(selectedDate, classDateLbl);
    errReason = checkEmptyValidation(selectedReason, reasonLbl);

    if(selectedReason == 1){
      let profileLbl = "Leave Pic ";
      if (lbl && lbl.leavePic) profileLbl = lbl.leavePic;
      errProfilePic = checkEmptyValidation(profile_pic, profileLbl);
    }

    if (errComment || errDate || errReason || errProfilePic) {
      setCommentError(errComment);
      setDateError(errDate);
      setReasonError(errReason);
      setErrorProfilePic(errProfilePic);
    } else {
      setCommentError("");
      setDateError("");
      setReasonError("");
      setErrorProfilePic("");
      setLoader(true);
      // const leaveObj = {
      //   course: selectedCourse,
      //   date: selectedDate,
      //   comment,
      // };
      const leaveObj = new FormData()
      leaveObj.append('course', selectedCourse)
      leaveObj.append('date', selectedDate)
      leaveObj.append('comment', comment)
      leaveObj.append('leave_type', selectedReason)
      if(selectedReason == 1){
        leaveObj.append('leave_pic', profile_pic)
      }

      applyLeave(leaveObj)
        .then((res) => {
          toast.success(res.data.message);
          setLoader(false);
          setOpenStatusModal(false);
          getAvailableLeaveBalance();
          getLeaveHistory({ page: 1, limit: 10 });
          setComment("");
          setSelectedDate("");
          setProfilePic('')
          setProfilePicPreview('')
          setSelectedReason('')
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          setLoader(false);
          setOpenStatusModal(false);
        });
    }
  };

  // --------- get class from course (dropdown) ---------
  const handleCourse = (e) => {
    const { value } = e.target;
    setSelectedCourse(value);

    getClassFromCourse(value);
  };

  // -----

  const closeLeaveModal = (e) => {
    setOpenStatusModal(false);
    setSelectedDate("");
    setComment("");
    setDateError("");
    setCommentError("");
    setProfilePic('')
    setProfilePicPreview('')
    setSelectedReason('')
  };

  const openImageUpload = (e, type) => {
    document.getElementById("photo").click();
  };

  const onFileChange = async (e) => {
    let validateImage = true;
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const imageSize = e.target.files[0].size / 1024 / 1024;

      if (!file) {
        validateImage = false;
        let selectLbl = "Please select image.";
        if (lbl && lbl.selectImage) selectLbl = lbl.selectImage;
        setErrorProfilePic(selectLbl);
      } else if (!file.name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/)) {
        validateImage = false;
        let selectValidLbl = "Please select valid image.";
        if (lbl && lbl.selectValidImage) selectValidLbl = lbl.selectValidImage;
        setErrorProfilePic(selectValidLbl);
      } else if (imageSize > 2) {
        validateImage = false;
        let selectSizeLbl = "Please select image size less then 2 MB.";
        if (lbl && lbl.selectImageLessThan2)
          selectSizeLbl = lbl.selectImageLessThan2;

        setErrorProfilePic(selectSizeLbl);
      } else {
        validateImage = true;

        const preview = URL.createObjectURL(file);
        setProfilePicPreview(preview);
        setProfilePic(file);
        setErrorProfilePic("");
      }
    }
  };

  // --------- Apply for leave component ---------
  const renderApplyForLeaveModal = () => {
    return (
      <Fragment>
        <Modal isOpen={openStatusModal}>
          <ModalBody className="modal-body  modal-body-container  ">
            <span
              className="close_btn mb-2"
              onClick={(e) => closeLeaveModal(e)}
            >
              x
            </span>
            <div className="col-md-12">
              <select
                className="form-select border border-none"
                onChange={(e) => handleCourse(e)}
              >
                {selectedLeave && (
                  <option value={selectedLeave._id}>
                    {selectedLeave.title}
                  </option>
                )}
                {leaveBalance.map((leave, i) => {
                  if (selectedLeave !== null && selectedLeave !== leave) {
                    return <option value={leave._id}>{leave.title}</option>;
                  }
                })}
              </select>
            </div>
            <div className="col-md-12 mt-4">
              <select
                className={classnames("form-select border border-none", {
                  invalid: date_error.length > 0,
                })}
                onChange={(e) => setSelectedDate(e.target.value)}
              >
                {classList.length > 0 && (
                  <option value="">
                    {lbl ? lbl.selectClassDate : "Select class date"}
                  </option>
                )}
                {classList.length > 0 ? (
                  classList.map((data, i) => (
                    <option value={moment(data.date).format("YYYY-MM-DD")}>
                      {data.date}
                    </option>
                  ))
                ) : (
                  <option value="">
                    {lbl ? lbl.noClassDateAvailable : "No class date available"}
                  </option>
                )}
              </select>
              {date_error ? (
                <div className="invalid-text" style={{width: '100%'}}>{date_error}</div>
              ) : null}
            </div>
            
            {/* 请假原因 */}
            <div className="col-md-12 mt-4">
              <select
                className={classnames("form-select border border-none", {
                  invalid: reason_error.length > 0,
                })}
                onChange={(e) => setSelectedReason(e.target.value)}
              >
                <option value={''}>
                  { lbl ? lbl.selectReason : 'Please select reason' }
                </option>
                <option value={1}>
                  { lbl ? lbl.sickLeave : 'Sick leave' }
                </option>
                <option value={2}>
                  { lbl ? lbl.casualLeave : 'Casual leave' }
                </option>
              </select>
              {reason_error ? (
                <span className="invalid-text " style={{float: 'none'}}>{reason_error}</span>
              ) : null}
            </div>
            
            {
              selectedReason == 1 ? 
                <div className="col-md-12 mt-4">
                  <div
                    className="p-image bg_color"
                    onClick={(e) => openImageUpload(e)}
                  >
                    <Button color="link">
                      { lbl.upload || 'Upload' }
                    </Button>
                    <input
                      id="photo"
                      className="file-upload d-none"
                      type="file"
                      accept="image/*"
                      onChange={(e) => onFileChange(e)}
                    />
                    <img
                      style={{ maxHeight: 200, maxWidth: 200, marginLeft: 20 }}
                      hidden={!profile_pic_preview}
                      src={profile_pic_preview}
                    />
                  </div>
                  {error_profile_pic ? (
                    <span className="invalid-text ">{error_profile_pic}</span>
                  ) : null}
                </div>
              : null
            }

            <div className="col-md-12 mt-4">
              <textarea
                className={classnames(
                  "form-control border border-none comment-area",
                  { invalid: comment_error.length > 0 },
                  { valid: comment_error.length === 0 }
                )}
                rows="5"
                placeholder={lbl ? lbl.comment : "Comment"}
                onChange={(e) => setComment(e.target.value)}
                value={comment}
              ></textarea>
              {comment_error ? (
                <span className="invalid-text ">{comment_error}</span>
              ) : null}
            </div>
            <div className="w-100 mt-4">
              {/* <button
              type="button"
              className="btn w-100 mt-4 blue_fill_btn text-center "
              onClick={(e) => onApplyNowClick(e)}
            >
            Apply Now
            </button> */}
              <ButtonComponent
                loading={loader}
                title={lbl ? lbl.applyNow : "Apply Now"}
                onBtnClick={(e) => onApplyNowClick(e)}
              />
            </div>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div className="col-md-8 col-lg-9 px-2 mt-4 mt-sm-0 ">
        <ToastifyContainer />
        <div className="stdnt_home_main_box mb-4">
          <div className="row  px-2 ">
            <div className="my_leaves_components pb-3">
              <h3 className="bld_secnd_title w-100 mb-4">
                {lbl ? lbl.availableLeaveBalance : "Available Leave Balance"}
              </h3>

              {leaveBalance.length > 0 && !leaveBalanceLoading ? (
                leaveBalance.map((leave, i) => (
                  <div className="leave-item color_gray" key={i}>
                    <div className="leave-title">
                      {i + 1 + ". " + leave.title}
                    </div>
                    {/* <div className="leave-balance">
                      {leave.leaveBalance < 0 ? "0" : leave.leaveBalance + " "}{" "}
                      {leave.leaveBalance === 1 ? (
                        <span>{lbl ? lbl.leave : "Leave"}</span>
                      ) : (
                        <span>{lbl ? lbl.leaves : "Leaves"}</span>
                      )}
                    </div> */}
                    <button
                      disabled={leave.leaveBalance === 0}
                      className={leave.leaveBalance === 0 && "disabled-btn"}
                      onClick={(e) => onApplyLeaveClick(e, leave)}
                    >
                      {lbl ? lbl.applyForLeave : "Apply for Leave"}
                    </button>
                  </div>
                ))
              ) : leaveBalanceLoading ? (
                <div className="row w-100 mt-0 pt-0">
                  <LeaveBalanceLoader />
                </div>
              ) : leaveBalance.length === 0 && !leaveBalanceLoading ? (
                <div className="leave-item color_gray w-100 text-center">
                  {lbl ? lbl.noLeavebalance : "No leave balance found"}
                </div>
              ) : null}
            </div>
          </div>
          <hr />

          {/* --------- Leave History table component --------- */}
          <div className="row px-2">
            <LeaveHistoryTable />
          </div>

          {/* --------- Apply for leave modal --------- */}
          {renderApplyForLeaveModal()}
        </div>
      </div>
    </Fragment>
  );
};

MyLeavesComponent.propTypes = {
  getAvailableLeaveBalance: PropTypes.func.isRequired,
  getClassFromCourse: PropTypes.func.isRequired,
  applyLeave: PropTypes.func.isRequired,
  leaves: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  leaves: state.leave,
});
export default connect(mapStateToProps, {
  getAvailableLeaveBalance,
  getClassFromCourse,
  applyLeave,
  getLeaveHistory,
})(MyLeavesComponent);
