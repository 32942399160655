import React, { Fragment, useEffect } from "react";
import DefaultHeader from "../../../DefaultLayout/DefaultHeader";
import DefaultFooter from "../../../DefaultLayout/DefaultFooter";
import LeftSideImage from "../../CommonComponents/AuthenticationComponents/LeftSideImage";
import StudentForgotPasswordInput from "../../CommonComponents/AuthenticationComponents/ForgotPasswordInput";
import { scrollToTop } from "../../../Helpers/ScrollToTop";

const ForgotPassword = (props) => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <Fragment>
      <DefaultHeader />
      <section className="stdnt_login_registr_main sec_mar_top">
        <div className="container">
          <div className="row">
            <LeftSideImage />
            <StudentForgotPasswordInput />
          </div>
        </div>
      </section>
      <DefaultFooter />
    </Fragment>
  );
};
export default ForgotPassword;
