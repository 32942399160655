import React, { Fragment, useEffect } from "react";
import "./aboutus.css";
import * as cmsActions from "../../actions/cmsAction";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import parse from "html-react-parser";
import { BeatLoader } from "react-spinners";
import DefaultHeader from "../../DefaultLayout/DefaultHeader";
import DefaultFooter from "../../DefaultLayout/DefaultFooter";
import { useLocation } from "react-router";
import labels from "../../labels.json";

export default function AboutUs() {
  const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

  const dispatch = useDispatch();
  const { getContentDetails } = bindActionCreators(cmsActions, dispatch);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0 });

    let fetchApi = async () => {
      let info = "about-us";
      if (pathname === "/privacy-policy") {
        info = "privacy-policy";
      }
      if (pathname === "/faq") {
        info = "faq";
      }
      await getContentDetails(info);
    };
    fetchApi();
  }, [pathname]);

  const { cmsDetails, cmsLoading } = useSelector((state) => state.cmsDetails);
  return (
    <Fragment>
      <DefaultHeader />
      <div className="w-100 pb-5">
        <div className="about-us-container ">
          <div className="section-1 mb-5 pb-5">
            <div className="row mt-4 pt-5">
              <h3 className="text-center mb-5">
                <strong>
                  {pathname === "/privacy-policy" && (
                    <span>{lbl ? lbl.privacyPolicy : "Privacy Policy"}</span>
                  )}
                  {pathname === "/about-us" && (
                    <span>{lbl ? lbl.aboutUs : "About Us"}</span>
                  )}
                  {pathname === "/faq" && <span>{lbl ? lbl.faq : "FAQ"}</span>}
                </strong>
              </h3>
              <div className="col-md-12 pl-0 mx-0 pr-3 ">
                {cmsDetails && !cmsLoading ? (
                  parse(`${cmsDetails.content}`)
                ) : cmsLoading ? (
                  <div
                    className="w-100 text-center py-5 "
                    style={{ marginBottom: !cmsLoading ? "200px" : "50px" }}
                  >
                    <BeatLoader color="#005da1" loading={cmsLoading} />
                  </div>
                ) : !cmsDetails ? (
                  <div
                    className="w-100 text-center py-5 h5"
                    style={{ marginBottom: !cmsLoading ? "200px" : "50px" }}
                  >
                    {pathname === "/privacy-policy" ? (
                      <span>
                        {lbl
                          ? lbl.noPrivacyContent
                          : "No privacy policy content found"}
                      </span>
                    ) : pathname === "/about-us" ? (
                      <span>
                        {lbl ? lbl.noAboutContent : "No about us content found"}
                      </span>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* <div className="section-2">
            <img src={cmsDetails.banner_image} alt="about-us" />
          </div> */}
        </div>
      </div>
      <DefaultFooter />
    </Fragment>
  );
}
