import React from "react";
import { AvatarWithText, InstaStories } from "./Loader";
import "./loader.css";

export const StudnetCourseLoader = (props) => (
  <div className="ph-item">
    <div className="ph-col-12">
      <div className="ph-row ">
        <div className="ph-col-4 big color my-2 mt-3"></div>
      </div>
      <div className="ph-picture color"></div>
      <div className="ph-row">
        <div className="ph-col-4  color about "></div>
        <div className="ph-col-8  empty big"></div>
        <div className="ph-col-12 color description  "></div>
        <div className="ph-col-12 color description  "></div>
        <div className="ph-col-8  color description mb-3"></div>
        <div className="ph-col-4  empty"></div>
        <div className="ph-col-2  color description mb-2 mt-4"></div>
        <div className="ph-col-8  empty "></div>
        <div className="ph-col-4  color data-list mb-2 mt-4 mr-2"></div>
        <div className="ph-col-8  empty "></div>
        <div className="ph-col-2  color description mt-4"></div>
        <div className="ph-col-8  empty "></div>
        <AvatarWithText />
        <InstaStories />
      </div>
    </div>
  </div>
);
