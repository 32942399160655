import axios from "axios";
import { all_course_list, course_student_gifts } from "../api/index";
import {
  ALL_COURSE_LIST,
  ALL_COURSE_DETAILS,
  COURSE_STUDENT_GIFT,
} from "./types";
import { config } from "../api";
/**
 * Api method : Get API
 * Function called for getting the course list.
 */
export const getStudentCourseList = (pagination) => async (dispatch) => {
  try {
    const res = await axios.get(all_course_list, { params: pagination });
    dispatch({
      type: ALL_COURSE_LIST,
      payload: res.data.result,
    });
    return res;
  } catch (error) {
    return error;
  }
};

/**
 * Api method : Get API
 * Function called for getting the course details.
 */
export const getStudentCourseDetails = (_id) => async (dispatch) => {
  try {
    const res = await axios.patch(all_course_list + `/${_id}`);
    dispatch({
      type: ALL_COURSE_DETAILS,
      payload: res.data.result,
    });
    return res;
  } catch (error) {
    return error;
  }
};

/**
 * Api method : Get API
 * Function called for getting the course student gift list.
 */
export const getStudnetGiftList = (pagination) => async (dispatch) => {
  try {
    const res = await axios.get(course_student_gifts , { params: pagination });
    dispatch({
      type: COURSE_STUDENT_GIFT,
      payload: res.data.result,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const cancelBooking = (params) => async (dispatch) => {
  const body = JSON.stringify(params);
  const res = await axios.post(all_course_list + '/cancelBooking', body, config);
  try {
    return res;
  } catch (error) {
    return error;
  }
};
