import { GET_MY_ENROLLMENTS } from "../actions/types";

const initialState = {
  myEnrollments: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  myEnrollmentList: [],
  loading: true,
};

/**
 * Reducer Called for state management for the Course List.
 * @param {*} [state=initialState] initail state of the list.
 * @param {*} action State Update.
 * @return {*}
 */
const enrollmentReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case GET_MY_ENROLLMENTS:
      return {
        ...state,
        myEnrollments: payload,
        myEnrollmentList: state.myEnrollmentList.concat(payload.docs),
        loading: false,
      };

    default:
      return state;
  }
};
export default enrollmentReducer;
