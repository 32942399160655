import React, { Fragment } from "react";
import DefaultHeader from "../../../DefaultLayout/DefaultHeader";
import UpdateCoachProfile from "../../CommonComponents/UpdateProfile/UpdateProfile";

function UpdateprofileDetails() {
  return (
    <Fragment>
      <DefaultHeader />
      <UpdateCoachProfile />
    </Fragment>
  );
}

export default UpdateprofileDetails;
