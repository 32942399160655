const initialState = {
  faqData: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  faqLoading: true,
  cmsDetails: null,
  cmsLoading: true,
};

const cmsReducers = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "GET_CMS_DETAILS":
      return {
        ...state,
        cmsDetails: payload,
        cmsLoading: false,
      };
    case "GET_FAQ_DETAILS":
      return {
        ...state,
        faqData: payload,
        faqLoading: false,
      };
    default:
      return state;
  }
};
export default cmsReducers;
