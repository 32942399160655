import React, { Fragment, useEffect, useState } from "react";
import gift from "../../../assets/images/my-gifts-blue.svg";
import "./myGifts.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getStudnetGiftList } from "../../../actions/studentCourseAction";
import StudentGiftLoader from "../../../components/ContentLoader/StudentGifltLoader";
import labels from "../../../labels.json";

const MyGiftsComponents = (routerProps) => {
  const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

  const { myGiftlist, getStudnetGiftList } = routerProps;

  const [isReadMore, setIsReadMore] = useState(true);
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [pageLimit] = useState(5);
  const [page, setPage] = useState(1);
  const [giftList, setGiftList] = useState([]);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  useEffect(() => {
    getGiftList()
  }, [page]);

  const getGiftList = () => {
    setLoadingResponse(true);
    getStudnetGiftList({ page: page, limit: pageLimit }).then((res) => {
      let list = [...giftList, ...res.data.result.docs]
      setGiftList(list)
      setLoadingResponse(false);
    });
  }

  const onPageClick = (page) => {
    setPage(page)
    // getStudnetGiftList({ page: page, limit: pageLimit });
  };

  const viewMore = (data) => {
    const { hasNextPage, nextPage } = data;

    return (
      <div className="w-100 ">
        <button
          hidden={hasNextPage === true ? false : true}
          onClick={hasNextPage === true ? () => onPageClick(nextPage) : null}
          className=" blue_fill_btn view-more-paginate-btn btn-sm "
        >
          {lbl ? lbl.viewMore : "View more..."}
        </button>
      </div>
    );
  };
  const { getStudentGiftList, myGiftListing, Loading } = myGiftlist;

  return (
    <Fragment>
      <div className="col-md-8 col-lg-9 px-2 mt-4 mt-sm-0 ">
        <div className="stdnt_home_main_box px-4">
          <h3 className="bld_secnd_title w-100 mb-4 recieved-gift-title">
            {lbl ? lbl.receivedGifts : "Received Gifts"}
          </h3>
          <div className="my_gifts_components">
            <div className="row w-100">
              {!loadingResponse &&
              getStudentGiftList &&
              getStudentGiftList.docs.length > 0 &&
              !Loading ? (
                giftList.map((gifts, i) => {
                  return (
                    <div className="gift-item " key={i}>
                      <div className="gift_main">
                        <div className="gift-container">
                          <img src={gift} alt="gift icon" />
                          <span className="gift-title ">
                            {isReadMore
                              ? gifts.gift_text.slice(0, 120)
                              : gifts.gift_text}
                            <span
                              onClick={toggleReadMore}
                              className="read-or-hide"
                            >
                              {gifts.gift_text.length > 100
                                ? isReadMore
                                  ? "...read more"
                                  : " show less"
                                : ""}
                            </span>
                          </span>
                        </div>
                        <div
                          className={
                            gifts.gift_given === 1
                              ? "container_pos color_gray"
                              : "container_position color_gray"
                          }
                        >
                          <div className="col">
                            <i className="far fa-clock clock_mr"></i>{" "}
                            {gifts.updatedAt}
                          </div>
                          <div
                            className={
                              gifts.gift_given === 1
                                ? "mt-2 tg_position"
                                : "mt-2 tg_pos"
                            }
                          >
                            <i
                              className="fa fa-info-circle tg_info"
                              aria-hidden="true"
                            ></i>
                            {gifts.gift_given === 1 ? (
                              <span>
                                {lbl ? lbl.receivedGifts : "Received"}
                              </span>
                            ) : (
                              <span>
                                {lbl ? lbl.notReceived : "Not Received"}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : Loading || loadingResponse ? (
                <StudentGiftLoader />
              ) : getStudentGiftList.docs.length === 0 && !Loading ? (
                <div className="col-xl-12 col-lg-12 col-md-12 mt-5">
                  <div className="col tg-display tg-content">
                    {lbl ? lbl.noGiftsFound : "No Gifts found"}
                  </div>
                </div>
              ) : null}
            </div>
            <div className=" w-100 mt-2">{viewMore(getStudentGiftList)}</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

MyGiftsComponents.propTypes = {
  getStudnetGiftList: PropTypes.func.isRequired,
  myGiftlist: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  myGiftlist: state.all_courseList,
});

export default connect(mapStateToProps, {
  getStudnetGiftList,
})(MyGiftsComponents);
