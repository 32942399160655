import React, { Fragment, useRef, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import InputField from "../InputField/InputField";
import { checkEmptyValidation, checkRequiredValidationWithMinMax } from "../../Helpers/Validation";
import ButtonComponent from "../Button/ButtonComponent";
import { findDOMNode } from "react-dom";
import firebase from "../../Helpers/firebase";
import classnames from "classnames";
import { countryCodes } from "../../Helpers/countryCodeList";
import { loadUser, updateUserMobileNumber,verifyUserMobileNumber } from "../../actions/authActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import labels from "../../labels.json";

function UpdateMobileModal(props) {
    const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

    const {
        showModal,
        closeModal,
        toast,
        currentNumber,
        verifyUserMobileNumber,
        updateUserMobileNumber,
        loadUser,
    } = props;

    const [new_phone_number, setNewPhoneNumber] = useState("");
    const [sendOtpLoader, setSendOtpLoader] = useState(false);
    const [verifyLoader, setVerifyLoader] = useState(false);
    const [openVerifyOTPInput, setopenVerifyOTPInput] = useState(false);
    const [regexp] = useState(/^[0-9\b]+$/);
    const [country_code, setCountryCode] = useState("+852");
    const [mobileVerificationToken, setMobileVerificationToken] = useState(null);
    const [error_new_phone_number, setErrorNewPhoneNumber] = useState("");
    const [error_otp, setErrorOtp] = useState("");

    const n1 = useRef(null);
    const n2 = useRef(null);
    const n3 = useRef(null);
    const n4 = useRef(null);
    const n5 = useRef(null);
    const n6 = useRef(null);
    const verifyBtnRef = useRef(null);

    const [number1, setNumber1] = useState("");
    const [number2, setNumber2] = useState("");
    const [number3, setNumber3] = useState("");
    const [number4, setNumber4] = useState("");
    const [number5, setNumber5] = useState("");
    const [number6, setNumber6] = useState("");

    const [error_n1, setErrorN1] = useState("");
    const [error_n2, setErrorN2] = useState("");
    const [error_n3, setErrorN3] = useState("");
    const [error_n4, setErrorN4] = useState("");
    const [error_n5, setErrorN5] = useState("");
    const [error_n6, setErrorN6] = useState("");

    const closeModalClick = (e) => {
        closeModal();
        clearErrorFields();
        clearInputFields();
        setopenVerifyOTPInput(false);
        setErrorNewPhoneNumber("");
    };

    // ---------- Mobile Input change ------------
    const onMobileNumberChange = (e) => {
        let mobileNo = e.target.value;

        // if value is not blank, then test the regex
        if (mobileNo === "" || regexp.test(mobileNo)) {
            setNewPhoneNumber(mobileNo);
            setopenVerifyOTPInput(false);
        }
    };

    // ---------- handle OTP input change ----------
    const handleOTPChange = (e) => {
        const { name, value } = e.target;

        if (name === "number1") {
            setNumber1(n1.current.value);
            if (value) findDOMNode(n2.current).focus();
        }
        if (name === "number2") {
            setNumber2(n2.current.value);
            if (value) findDOMNode(n3.current).focus();
        }
        if (name === "number3") {
            setNumber3(n3.current.value);
            if (value) findDOMNode(n4.current).focus();
        }
        if (name === "number4") {
            setNumber4(n4.current.value);
            if (value) findDOMNode(n5.current).focus();
        }
        if (name === "number5") {
            setNumber5(n5.current.value);
            if (value) findDOMNode(n6.current).focus();
        }
        if (name === "number6") {
            setNumber6(n6.current.value);
            if (value) findDOMNode(verifyBtnRef.current).focus();
        }
    };

    const makeid = (length) => {
        var result = "";
        var characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    //   ------------ configure recaptcha ------------
    const configureCaptcha = (e) => {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
            "sign-in-button",
            {
                size: "invisible",
                callback: (response) => {
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                    // onSendOTPClick(e);
                },

                // defaultCountry: "IN", // Not necessary, on your choice
            }
        );
        window.recaptchaVerifier.render().then(function (widgetId) {
            window.recaptchaWidgetId = widgetId;
        });
    };

    //  https://www.googleapis.com/identitytoolkit/v3/relyingparty/sendVerificationCode?key=AIzaSyBUX05lipqPIB99p1zU1C3EJ1F38-rLh7s

    //   --------- Submit event when otp entered -------
    const onSendOTPClick = (e) => {
        if (e !== undefined) e.preventDefault();
        let errMobileNumber = "";

        let mobileLbl = "Mobile number";
        if (lbl && lbl.mobileNumber) mobileLbl = lbl.mobileNumber;

        errMobileNumber = checkRequiredValidationWithMinMax(
            new_phone_number,
            mobileLbl,
            8,
            14
        );

        configureCaptcha(e);
        if (errMobileNumber) {
            setErrorNewPhoneNumber(errMobileNumber);
        } else if (new_phone_number === currentNumber) {
            setSendOtpLoader(false);
            return toast.error("This mobile number is already exist");
        } else {
            setSendOtpLoader(true);
            setErrorNewPhoneNumber("");

            const phoneNumber = country_code + new_phone_number;
            const appVerifier = window.recaptchaVerifier;

            const id = makeid(10);
            const verifyObj = { phone_number: new_phone_number, phone_token: id };

            setMobileVerificationToken(id);
            verifyUserMobileNumber(verifyObj)
                .then((result) => {
                    firebase
                        .auth()
                        .signInWithPhoneNumber(phoneNumber, appVerifier)
                        .then((confirmationResult) => {
                            setSendOtpLoader(false);
                            setopenVerifyOTPInput(true);
                            findDOMNode(n1.current).focus();
                            toast.success("OTP Has been sent successfully");
                            // SMS sent. Prompt user to type the code from the message, then sign the
                            // user in with confirmationResult.confirm(code).
                            return (window.confirmationResult = confirmationResult);
                        })
                        .catch((error) => {
                            // Error; SMS not sent
                            setSendOtpLoader(false);
                            toast.error("SMS not sent, please try again later");

                            return error;
                        });
                })
                .catch((error) => {
                    
                    setSendOtpLoader(false);
                    toast.error(error.response.data.message);
                });
        }
    };

    //  ----------- on submit otp -----------
    const onSubmitOTP = (e) => {
        let errN1 = "";
        let errN2 = "";
        let errN3 = "";
        let errN4 = "";
        let errN5 = "";
        let errN6 = "";

        errN1 = checkEmptyValidation(number1, "");
        errN2 = checkEmptyValidation(number2, "");
        errN3 = checkEmptyValidation(number3, "");
        errN4 = checkEmptyValidation(number4, "");
        errN5 = checkEmptyValidation(number5, "");
        errN6 = checkEmptyValidation(number6, "");

        if (errN1 || errN2 || errN3 || errN4 || errN5 || errN6) {
            setErrorN1(errN1);
            setErrorN2(errN2);
            setErrorN3(errN3);
            setErrorN4(errN4);
            setErrorN5(errN5);
            setErrorN6(errN6);
            setErrorOtp("Please enter valid OTP");
        } else {
            clearErrorFields();

            const code = number1 + number2 + number3 + number4 + number5 + number6;

            setVerifyLoader(true);
            setErrorOtp(false);

            window.confirmationResult
                .confirm(code)
                .then((result) => {
                    // User signed in successfully.
                    // const user = result.user;

                    updateUserMobileNumber({
                        phone_number: new_phone_number,
                        phone_token: mobileVerificationToken,
                    })
                        .then((res) => {
                            loadUser();
                            toast.success(res.data.message);
                            setopenVerifyOTPInput(false);
                            setVerifyLoader(false);
                            clearInputFields();
                            closeModal();
                        })
                        .catch((err) => {
                            setVerifyLoader(false);
                            toast.error(err.response.data.message);
                        });
                    return result;
                })
                .catch((error) => {
                    // User couldn't sign in (bad verification code?)
                    toast.error("Please enter valid OTP");
                    setVerifyLoader(false);
                    return error;
                });
        }
    };

    const clearErrorFields = () => {
        setErrorN1("");
        setErrorN2("");
        setErrorN3("");
        setErrorN4("");
        setErrorN5("");
        setErrorN6("");
    };
    const clearInputFields = () => {
        setNumber1("");
        setNumber2("");
        setNumber3("");
        setNumber4("");
        setNumber5("");
        setNumber6("");
        setNewPhoneNumber("");
    };

    // ----------- Send OTP on enter -----------
    const sendOTPonEnter = (e) => {
        if (e.key === "Enter") {
            onSendOTPClick();
        }
    };

    const renderEnterMobileNumberInput = () => {
        return (
            <Fragment>
                <div className="row w-100 px-0 mx-0">
                    <div className="col-4 px-0 mx-0">
                        <select
                            className="form-select mt-2    country_code_select"
                            onChange={(e) => {
                                setCountryCode("+" + e.target.value);
                                setErrorNewPhoneNumber("");
                            }}
                            disabled={sendOtpLoader}
                        >
                            {countryCodes.map((country, i) => (
                                <option value={country.phonecode} key={i}>
                                    {country.name + " (+" + country.phonecode + ")"}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-8">
                        <div className="mt-2 w-100 float-left text-input">
                            <InputField
                                inputType="text"
                                placeHolder={lbl ? lbl.mobileNumber : "Mobile number"}
                                errorValue={error_new_phone_number}
                                disable={sendOtpLoader}
                                onInputChange={(e) => onMobileNumberChange(e)}
                                value={new_phone_number}
                                keyDown={(e) => sendOTPonEnter(e)}
                            />
                        </div>
                    </div>
                    <div id="sign-in-button"></div>
                </div>
                <div className="mt-4 ">
                    <ButtonComponent
                        loading={sendOtpLoader}
                        title={lbl ? lbl.sendOtp : "Send OTP"}
                        onBtnClick={(e) => onSendOTPClick(e)}
                    />
                </div>
            </Fragment>
        );
    };
    // ----------- Display enter otp input --------------
    const renderVerifyOTPInput = () => {
        return (
            <Fragment>
                <div className="row w-100">
                    <div className="col-md-12 mt-2">
                        <div className=" w-100 otp-label">
                            <span className="text-secondary ">
                                {lbl ? lbl.enter6DigitOtp : "Please Enter your 6 digit OTP"}
                            </span>
                        </div>
                        <div className="row">
                            <div className="col-lg-2 col-md-2 col-sm-2 col-2 pr-2 pl-2">
                                <input
                                    type="text"
                                    maxLength="1"
                                    className={classnames(
                                        "otp-input text-center",
                                        { invalid: error_n1.length > 0 },
                                        { valid: error_n1.length === 0 }
                                    )}
                                    name="number1"
                                    onChange={(e) => {
                                        handleOTPChange(e);
                                    }}
                                    ref={n1}
                                    value={number1}
                                />
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2 col-2 pr-2 pl-2">
                                <input
                                    type="text"
                                    maxLength="1"
                                    className={classnames(
                                        "otp-input text-center",
                                        { invalid: error_n2.length > 0 },
                                        { valid: error_n2.length === 0 }
                                    )}
                                    name="number2"
                                    onChange={(e) => {
                                        handleOTPChange(e);
                                    }}
                                    ref={n2}
                                    value={number2}
                                />
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2 col-2 pr-2 pl-2">
                                <input
                                    type="text"
                                    maxLength="1"
                                    className={classnames(
                                        "otp-input text-center",
                                        { invalid: error_n3.length > 0 },
                                        { valid: error_n3.length === 0 }
                                    )}
                                    name="number3"
                                    onChange={(e) => {
                                        handleOTPChange(e);
                                    }}
                                    ref={n3}
                                    value={number3}
                                />
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2 col-2 pr-2 pl-2">
                                <input
                                    type="text"
                                    maxLength="1"
                                    className={classnames(
                                        "otp-input text-center",
                                        { invalid: error_n4.length > 0 },
                                        { valid: error_n4.length === 0 }
                                    )}
                                    name="number4"
                                    onChange={(e) => {
                                        handleOTPChange(e);
                                    }}
                                    ref={n4}
                                    value={number4}
                                />
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2 col-2 pr-2 pl-2">
                                <input
                                    type="text"
                                    maxLength="1"
                                    className={classnames(
                                        "otp-input text-center",
                                        { invalid: error_n5.length > 0 },
                                        { valid: error_n5.length === 0 }
                                    )}
                                    name="number5"
                                    onChange={(e) => {
                                        handleOTPChange(e);
                                    }}
                                    ref={n5}
                                    value={number5}
                                />
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2 col-2 pr-2 pl-2">
                                <input
                                    type="text"
                                    maxLength="1"
                                    className={classnames(
                                        "otp-input text-center",
                                        { invalid: error_n6.length > 0 },
                                        { valid: error_n6.length === 0 }
                                    )}
                                    name="number6"
                                    onChange={(e) => {
                                        handleOTPChange(e);
                                    }}
                                    ref={n6}
                                    value={number6}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-100 invalid-text">{error_otp}</div>
                <div className="mt-4 px-1">
                    <ButtonComponent
                        loading={verifyLoader}
                        title={lbl ? lbl.verify : "Verify"}
                        onBtnClick={(e) => onSubmitOTP(e)}
                        btnRef={verifyBtnRef}
                    />
                </div>
            </Fragment>
        );
    };

    return (
        <Fragment>
            <Modal isOpen={showModal}>
                <ModalBody className="modalContainer">
                    <span className="reset_title">
                        <strong>{lbl ? lbl.updateMobile : "Update mobile number"}</strong>
                    </span>
                    <span className="close_btn" onClick={closeModalClick}>
                        x
                    </span>
                    {/* ------------ Render verify input fields ---------------- */}
                    {!openVerifyOTPInput && renderEnterMobileNumberInput()}
                    {/* ------------ Render verify input fields ---------------- */}
                    {openVerifyOTPInput && renderVerifyOTPInput()}
                </ModalBody>
            </Modal>
        </Fragment>
    );
}
UpdateMobileModal.propTypes = {
    verifyUserMobileNumber: PropTypes.func.isRequired,
};
export default connect(null, {
    verifyUserMobileNumber,
    updateUserMobileNumber,
    loadUser,
})(UpdateMobileModal);
