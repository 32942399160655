import React, { Fragment, useEffect, useState } from "react";
import {
  createPaymentHistoryPDF,
  getPaymentHistory,
} from "../../../actions/paymentHistoryAction";
import "./myPaymentHistory.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ToastifyContainer from "../../../components/Toastify/ToastContainer";
import { toast } from "react-toastify";
import TableLoader from "../../../components/ContentLoader/TableLoader";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { scrollToTop } from "../../../Helpers/ScrollToTop";
import ReactTooltip from "react-tooltip";
import labels from "../../../labels.json";

const MyPaymentHistoryComponent = (routerProps) => {
  const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

  const { getPaymentHistory, paymentDetails, createPaymentHistoryPDF } =
    routerProps;

  const { loading, paymentHistory } = paymentDetails;

  useEffect(() => {
    scrollToTop();
    getPaymentHistory({ page: 1, limit: 10 });
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(1);

  // ---------- function for download pdf link ------------
  const onDownloadPDFClick = (id) => {
    createPaymentHistoryPDF({ id })
      .then((res) => {
        const url = res.data.result;

        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("target", `_blank`);
        link.setAttribute("download", `my_payment_history.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();
        toast.success(res.data.message);

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        return res;
      })
      .catch((err) => {
        if (err.response.data) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Oops, Something goes wrong. please try again later");
        }
      });
  };

  // ------------ page number click ------------
  const onPageClick = (page) => {
    setCurrentPage(page);
    getPaymentHistory({
      page: page,
      limit: limit,
    });
  };

  // ------------ Pagination Section ------------
  const renderPaginationSection = (data) => {
    const { page, totalPages, hasPrevPage, hasNextPage, prevPage, nextPage } =
      data;

    let Pages = [];
    let skipped = 0;

    for (var i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        (page < 4 && i <= 5) ||
        i === page - 1 ||
        i === page + 1 ||
        i === page ||
        i === totalPages ||
        (page >= totalPages - 3 && i >= totalPages - 4)
      ) {
        const test = i;
        const item = (
          <React.Fragment key={i}>
            {skipped === 1 ? (
              <PaginationItem>
                <PaginationLink disabled tag="button">
                  ...
                </PaginationLink>
              </PaginationItem>
            ) : null}
            <PaginationItem
              active={page === i ? true : false}
              onClick={page === i ? () => null : () => onPageClick(test)}
              key={i}
            >
              <PaginationLink tag="button">{i}</PaginationLink>
            </PaginationItem>
          </React.Fragment>
        );
        skipped = 0;
        Pages.push(item);
      } else {
        skipped = 1;
      }
    }

    return (
      <nav className="pagination-nav">
        <Pagination>
          {hasPrevPage && (
            <PaginationItem
              onClick={
                hasPrevPage === true ? () => onPageClick(prevPage) : null
              }
            >
              <PaginationLink
                previous
                disabled={hasPrevPage === true ? false : true}
                tag="button"
              >
                <i class="fas fa-chevron-left" />
              </PaginationLink>
            </PaginationItem>
          )}
          {Pages}
          {hasNextPage && (
            <PaginationItem
              onClick={
                hasNextPage === true ? () => onPageClick(nextPage) : null
              }
            >
              <PaginationLink
                next
                tag="button"
                disabled={hasNextPage === true ? false : true}
              >
                <i class="fas fa-chevron-right" />
              </PaginationLink>
            </PaginationItem>
          )}
        </Pagination>
      </nav>
    );
  };

  const onHandleLimitChange = (e) => {
    const { value } = e.target;

    setLimit(value);

    getPaymentHistory({
      page: 1,
      limit: value,
    });
  };

  return (
    <Fragment>
      <div className="col-md-8 col-lg-9 px-2 mt-4 mt-sm-0 ">
        <ToastifyContainer />
        <div className="stdnt_home_main_box mb-4">
          <div className="row  px-2 ">
            <div className="my_payment_history_components pb-3">
              <h3 className="bld_secnd_title w-100 mb-2">
                {lbl ? lbl.paymentHistory : "Payment History"}
              </h3>
              <div className="payment-table-container">
                <table className="payment-table ">
                  <thead>
                    <tr>
                      <th>{lbl ? lbl.paymentDate : "Payment Date"}</th>
                      <th className="border-right-white border-left-white">
                        {lbl ? lbl.courseTitle : "Course Title"}
                      </th>
                      <th>{lbl ? lbl.classroom : "Classroom"}</th>
                      <th>{lbl ? lbl.paymentMethod : "Payment Method"}</th>
                      <th>{lbl ? lbl.status : "Status"}</th>
                      <th className="border-right-white border-left-white">
                        {lbl ? lbl.comment : "Comment"}
                      </th>
                      <th>{lbl ? lbl.receipt : "Receipt"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentHistory.docs.length > 0 && !loading ? (
                      paymentHistory.docs.map((history, i) => (
                        <tr key={i}>
                          <td className="p-date">
                            {history.date ? history.date : "-"}
                          </td>
                          <td
                            className="border-left-blue border-right-blue"
                            style={{ width: "20%" }}
                          >
                            {history.course ? history.course : "-"}
                          </td>
                          <td className="border-left-blue border-right-blue package-td">
                            {history.package ? history.package : "-"}
                          </td>
                          {/* payment_type = 1=Stripe,2=Offline */}
                          <td className="">
                            {history.payment === 1 ? (
                              <span>{lbl ? lbl.stripe : "Stripe"}</span>
                            ) : history.payment === 2 ? (
                              <span>{lbl ? lbl.offline : "Offline"}</span>
                            ) : (
                              "-"
                            )}
                          </td>
                          {/* payment_status = 1=Success,2=pending,3=cancel,4=declined */}
                          <td className="">
                            {history.payment_status === 1 ? (
                              <span>{lbl ? lbl.success : "Success"}</span>
                            ) : history.payment_status === 2 ? (
                              <span>{lbl ? lbl.pending : "Pending"}</span>
                            ) : history.payment_status === 3 ? (
                              <span>{lbl ? lbl.cancelled : "Cancelled"}</span>
                            ) : history.payment_status === 4 ? (
                              <span>{lbl ? lbl.declined : "Declined"}</span>
                            ) : (
                              "-"
                            )}
                          </td>

                          <td
                            className="border-left-blue border-right-blue"
                            style={{ width: "25%" }}
                          >
                            {/* <Tooltip
                              title={history.comment}
                              animation="fade"
                              position="left"
                              arrow="true"
                              animateFill="true"
                            > */}
                            {/* <p data-tip={history.comment}> */}
                            <p className="mb-0" data-tip={history.comment}>
                              <span className="comment-limit">
                                {history.comment ? history.comment : "-"}
                              </span>
                            </p>
                            <ReactTooltip place="right" />
                            {/* </Tooltip> */}
                          </td>
                          <td>
                            {history.payment_status === 1 ||
                            history.payment_status === 2 ? (
                              <button
                                className="download_btn"
                                onClick={(e) =>
                                  onDownloadPDFClick(history.payment_id)
                                }
                              >
                                {lbl ? lbl.download : "Download"}
                              </button>
                            ) : (
                              "-"
                            )}
                          </td>
                        </tr>
                      ))
                    ) : loading ? (
                      <tr>
                        <TableLoader column={7} btnColumn={7} />
                      </tr>
                    ) : paymentHistory.docs.length === 0 && !loading ? (
                      <tr>
                        <td colSpan="7" className="py-5">
                          {lbl
                            ? lbl.noPaymentHistoryFound
                            : "No payment history found"}
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
              {paymentHistory.docs.length > 10 && (
                <div className="row">
                  <div className="col-6">
                    <select
                      className="form-select form-select-sm select-limit"
                      onChange={(e) => onHandleLimitChange(e)}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={50}>50</option>
                    </select>
                  </div>
                  <div className="col-6">
                    <div className="text-left">
                      {renderPaginationSection(paymentHistory)}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

MyPaymentHistoryComponent.propTypes = {
  getPaymentHistory: PropTypes.func.isRequired,
  createPaymentHistoryPDF: PropTypes.func.isRequired,
  paymentDetails: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  paymentDetails: state.payment,
});

export default connect(mapStateToProps, {
  getPaymentHistory,
  createPaymentHistoryPDF,
})(MyPaymentHistoryComponent);
