import axios from "axios";
import { CardBody } from "reactstrap";
import {
  Apply_For_Leave,
  Available_Leave_Balance,
  config,
  Course_Class_List,
  Get_My_Leave_History,
} from "../api";
import {
  GET_AVAILABLE_LEAVE_BALANCE,
  GET_CLASSES_FROM_COURSE,
  GET_MY_LEAVE_HISTORY,
} from "./types";

/**
 * Api method : Get API
 * Function called for getting the course list.
 */
export const getLeaveHistory = (pagination) => async (dispatch) => {
  try {
    const res = await axios.get(Get_My_Leave_History, {
      params: pagination,
    });
    dispatch({
      type: GET_MY_LEAVE_HISTORY,
      payload: res.data.result,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getAvailableLeaveBalance = () => async (dispatch) => {
  try {
    const res = await axios.get(Available_Leave_Balance);
    dispatch({
      type: GET_AVAILABLE_LEAVE_BALANCE,
      payload: res.data.result,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getClassFromCourse = (_id) => async (dispatch) => {
  try {
    const res = await axios.get(Course_Class_List + _id);
    dispatch({
      type: GET_CLASSES_FROM_COURSE,
      payload: res.data.result,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const applyLeave = (leaveObj) => async (dispatch) => {
  try {
    return axios.post(Apply_For_Leave, leaveObj);
  } catch (error) {
    return error;
  }
};