import axios from "axios";
import { toast } from "react-toastify";
import {
  config,
  Create_Payment_History_PDF,
  Student_Payment_History,
  course_payment,
} from "../api";
import { GET_PAYMENT_HISTORY } from "./types";

/**
 * Api method : Get API
 * Function called for getting the course list.
 */
export const getPaymentHistory = (pagination) => async (dispatch) => {
  try {
    const res = await axios.get(Student_Payment_History, {
      params: pagination,
    });
    dispatch({
      type: GET_PAYMENT_HISTORY,
      payload: res.data.result,
    });
    return res;
  } catch (error) {
    return error;
  }
};

// Change User password (student/coach)
export const createPaymentHistoryPDF = (_id) => async (dispatch) => {
  const body = JSON.stringify(_id);
  const res = await axios.post(Create_Payment_History_PDF, body, config);
  try {
    return res;
  } catch (error) {
    return error;
  }
};

//api called for payment process for purchase of packages
export const studentPayment = (payment) => async (dispatch) => {
  try {
    const body = JSON.stringify(payment);
    const res = await axios.post(course_payment, body, config);
    toast.success(res.data.message);
    return res;
  } catch (error) {
    toast.error(error.response.data.message)
  }
};