import React, { Fragment, useEffect } from "react";
import DefaultFooter from "../../../DefaultLayout/DefaultFooter";
import DefaultHeader from "../../../DefaultLayout/DefaultHeader";
import { scrollToTop } from "../../../Helpers/ScrollToTop";
import LeftSideImage from "../../CommonComponents/AuthenticationComponents/LeftSideImage";
import ResetPasswordInput from "../../CommonComponents/AuthenticationComponents/ResetPasswordInput";
export default function ResetPassword() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <Fragment>
      <DefaultHeader />
      <section className="stdnt_login_registr_main sec_mar_top">
        <div className="container">
          <div className="row">
            <LeftSideImage />
            <ResetPasswordInput />
          </div>
        </div>
      </section>
      <DefaultFooter />
    </Fragment>
  );
}
