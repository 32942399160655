import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import searchImg from "../../../assets/images/search.png";
import calender from "../../../assets/images/date.svg";
import { getStudentCourseList } from "../../../actions/studentCourseAction";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadUser } from "../../../actions/authActions";
import {
    CourseListLoader,
    StudentCourseListLoader,
} from "../../../components/ContentLoader/CourseCardLoader";
import { scrollToTop } from "../../../Helpers/ScrollToTop";
import labels from "../../../labels.json";
import moment from 'moment'
import './CourseList.css'

const CourseList = (routerProps) => {
    const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

    const { pathname } = useLocation();

    const { getStudentCourseList, courseListing, loadUser, auth } = routerProps;
    const { getAllCourseList, Loading, myListedCourses } = courseListing;
    const [search, setSearch] = useState("");
    const initialPath = pathname.split("/")[1];
    const [pageLimit] = useState(10);
    const [page] = useState(1);
    const [loadingResponse, setLoadingResponse] = useState(false);

    useEffect(() => {
        loadUser();
        renderCourseList();
    }, []);

    const renderCourseList = () => {
        setLoadingResponse(true);
        courseListing.myListedCourses = [];
        getStudentCourseList({ page: page, limit: pageLimit, search: "" }).then(
            (res) => {
                setLoadingResponse(false);
                scrollToTop();
            }
        );
    };

    const onPageClick = (page) => {
        getStudentCourseList({ page: page, limit: pageLimit, search: search });
    };

    const viewMore = (data) => {
        const { hasNextPage, nextPage } = data;

        return (
            <div className="w-100 ">
                <button
                    hidden={hasNextPage === true ? false : true}
                    onClick={hasNextPage === true ? () => onPageClick(nextPage) : null}
                    className=" blue_fill_btn view-more-paginate-btn btn-sm "
                >
                    {lbl ? lbl.viewMore : "View more..."}
                </button>
            </div>
        );
    };

    const handleSearchOnClick = (e) => {
        if (e.key === "Enter") {
            courseListing.myListedCourses = [];

            getStudentCourseList({ page: page, limit: pageLimit, search: search });
        }
    };
    return (
        <Fragment>
            <div className="col-xl-12 col-lg-12 col-md-12 mt-5">
                <div className="row">
                    <div className="col-md-12 ">
                        <div className="search_bar">
                            <input
                                type="text"
                                placeholder={lbl ? lbl.search : "Search"}
                                onChange={(e) => setSearch(e.target.value)}
                                value={search}
                                onKeyDown={handleSearchOnClick}
                            />
                            <img src={searchImg} alt="search" />
                        </div>
                    </div>
                    {!loadingResponse && getAllCourseList.docs.length > 0 && !Loading ? (
                        myListedCourses.map((courselist, i) => {
                            let dateYMD = ''
                            if(courselist.date !== '-') dateYMD = `${moment(courselist.date).year()}年${moment(courselist.date).format('MM')}月${moment(courselist.date).format('DD')}日`
                            return (
                                <div className="col-sm-12 col-md-12 col-lg-6 mt-4" key={i}>
                                    <div className="stdnt_home_main_box" style={{ overflow: 'hidden', paddingTop: '0' }}>
                                        <div className="row">

                                            {
                                                (pathname === "/coach/course-list" && auth.user?.role == 2) ? null
                                                    : <div className="col-md-12 mt-3" style={{ marginBottom: '10px' }}>
                                                        <div className="feed_img_main">
                                                            <img src={courselist.image} alt="course" />
                                                        </div>
                                                    </div>
                                            }

                                            <div className="col-md-12 mt-2">
                                                <div className="course_common_user_profile_txt pl-8">
                                                    <p className="gnrl_para m-0 ">{courselist.title}</p>
                                                </div>
                                            </div>

                                            {
                                                pathname === "/coach/course-list" ? (
                                                    <div>
                                                        {
                                                            auth.user?.role == 2 ? 
                                                            <div className="flex_box">
                                                                <p className="color_gray feed_date_time pr-20" style={{ fontSize: '18px', color: '#323232' }}>
                                                                    <img src={calender} alt="calender icon" />
                                                                    { courselist.date !== "-" ? dateYMD : (lbl.expired || "Expired") }
                                                                </p>
                                                                <Link
                                                                    to={
                                                                        initialPath === "student" ?
                                                                        `/student/course/details/${courselist._id}`
                                                                        : 
                                                                        `/coach/course-details/${courselist._id}`
                                                                    }
                                                                >
                                                                    <button className="view_more_btn viewMore">
                                                                        {lbl ? lbl.viewMoreDetails : "View more details"}
                                                                    </button>
                                                                </Link>
                                                            </div>
                                                            :
                                                            <div className="col-md-12 mt-4 tg-ctsm-box">
                                                                <h3 className="bld_secnd_title  pl-8">
                                                                    {lbl ? lbl.aboutTheCourse : "About the course"}
                                                                </h3>
                                                                <p className="color_gray feed_date_time pr-20" style={{ fontSize: '18px', color: '#323232' }}>
                                                                    <img src={calender} alt="calender icon" />
                                                                    { courselist.date !== "-" ? dateYMD : (lbl.expired || "Expired") }
                                                                </p>
                                                            </div>
                                                        }
                                                    </div>
                                                ) : (
                                                    <Fragment>
                                                        <div className="col-md-12 mt-2 tg-ctsm-box" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <div style={{ flex: 1 }}>
                                                                <h3 className="bld_secnd_title pl-8" style={{ margin: '0' }}>
                                                                    {lbl
                                                                        ? lbl.packageStartsFrom
                                                                        : "Package starts from"}
                                                                    &nbsp;&nbsp;
                                                                </h3>
                                                                <p className="color_gray">{courselist.fee || '-'} {lbl ? lbl.hkd : "HKD"} /{lbl ? lbl.lesson : 'Lesson'}</p>
                                                            </div>
                                                            <p className="color_gray feed_date_time pr-20" style={{ fontSize: '18px', color: '#323232' }}>
                                                                <img src={calender} alt="calender icon" />
                                                                { courselist.date !== "-" ? dateYMD : (lbl.expired || "Expired") }
                                                            </p>
                                                        </div>
                                                    </Fragment>
                                                )}
                                            {
                                                auth.user?.role == 2 ? null :
                                                <div className="col-md-12">
                                                    <Link
                                                        to={
                                                            initialPath === "student"
                                                                ? `/student/course/details/${courselist._id}`
                                                                : `/coach/course-details/${courselist._id}`
                                                        }
                                                    >
                                                        <button className="view_more_btn" style={{ float: 'left' }}>
                                                            {lbl ? lbl.viewMoreDetails : "View more details"}
                                                        </button>
                                                    </Link>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : Loading || loadingResponse ? (
                        pathname === "/coach/course-list" ? (
                            <div className="col-xl-12 col-lg-12 col-md-12 mt-5">
                                <CourseListLoader />
                            </div>
                        ) : (
                            <div className="col-xl-12 col-lg-12 col-md-12 mt-5">
                                <StudentCourseListLoader />
                            </div>
                        )
                    ) : getAllCourseList.docs.length === 0 && !Loading ? (
                        <div className="col-xl-12 col-lg-12 col-md-12 mt-5">
                            <div className="col tg-display tg-content">
                                {lbl ? lbl.noCourseListFound : "No Course List found"}
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className=" w-100 mt-2">{viewMore(getAllCourseList)}</div>
            </div>
        </Fragment>
    );
};

CourseList.propTypes = {
    getStudentCourseList: PropTypes.func.isRequired,
    courseListing: PropTypes.object.isRequired,
    loadUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    courseListing: state.all_courseList,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    getStudentCourseList,
    loadUser,
})(CourseList);
