import React, { Fragment, useEffect, useState } from "react";
import { getMyAttendence } from "../../../actions/enrollmentsAction";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import moment from "moment";
import TableLoader from "../../../components/ContentLoader/TableLoader";
import { addDays } from "date-fns";
import DatePicker from "react-datepicker";
import labels from "../../../labels.json";

const MyAttendenceComponent = (props) => {
  const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

  const { courseId, type, getMyAttendence } = props;

  const [attendanceData, setAttendanceData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addDays(new Date(), 7));
  const [attendanceObj, setAttendanceObj] = useState({
    type: type,
    course: courseId,
    page: 1,
    limit: 10,
    fromDate: moment(startDate).format("YYYY-MM-DD"),
    toDate: moment(endDate).format("YYYY-MM-DD"),
  });

  const loadAttendence = () => {
    setLoader(true);

    getMyAttendence(attendanceObj).then((res) => {
      setAttendanceData(res.data.result);
      setLoader(false);
    });
  };

  useEffect(() => {
    loadAttendence();
  }, []);

  const onChnageDateRange = (date) => {
    const [start, end] = date;
    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      attendanceObj.fromDate = moment(start).format("YYYY-MM-DD");
      attendanceObj.toDate = moment(end).format("YYYY-MM-DD");

      getMyAttendence(attendanceObj).then((res) => {
        setAttendanceData(res.data.result);
      });
    }
  };
  // -------- Handle limit change --------
  const onHandleLimitChange = (e) => {
    const { value } = e.target;

    setAttendanceObj({ ...attendanceObj, limit: value });

    attendanceObj.limit = value;

    getMyAttendence(attendanceObj).then((res) => {
      setAttendanceData(res.data.result);
    });
  };

  // ------------ page number click ------------
  const onPageClick = (page) => {
    setAttendanceObj({ ...attendanceObj, page: page });

    attendanceObj.page = page;

    getMyAttendence(attendanceObj).then((res) => {
      setAttendanceData(res.data.result);
    });
  };

  // ------------ Pagination Section ------------
  const renderPaginationSection = (data) => {
    const { page, totalPages, hasPrevPage, hasNextPage, prevPage, nextPage } =
      data;

    let Pages = [];
    let skipped = 0;

    for (var i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        (page < 4 && i <= 5) ||
        i === page - 1 ||
        i === page + 1 ||
        i === page ||
        i === totalPages ||
        (page >= totalPages - 3 && i >= totalPages - 4)
      ) {
        const test = i;
        const item = (
          <React.Fragment key={i}>
            {skipped === 1 ? (
              <PaginationItem>
                <PaginationLink disabled tag="button">
                  ...
                </PaginationLink>
              </PaginationItem>
            ) : null}
            <PaginationItem
              active={page === i ? true : false}
              onClick={page === i ? () => null : () => onPageClick(test)}
              key={i}
            >
              <PaginationLink tag="button">{i}</PaginationLink>
            </PaginationItem>
          </React.Fragment>
        );
        skipped = 0;
        Pages.push(item);
      } else {
        skipped = 1;
      }
    }

    return (
      <nav className="pagination-nav">
        <Pagination>
          {hasPrevPage && (
            <PaginationItem
              onClick={
                hasPrevPage === true ? () => onPageClick(prevPage) : null
              }
            >
              <PaginationLink
                previous
                disabled={hasPrevPage === true ? false : true}
                tag="button"
              >
                <i className="fas fa-chevron-left" />
              </PaginationLink>
            </PaginationItem>
          )}
          {Pages}
          {hasNextPage && (
            <PaginationItem
              onClick={
                hasNextPage === true ? () => onPageClick(nextPage) : null
              }
            >
              <PaginationLink
                next
                tag="button"
                disabled={hasNextPage === true ? false : true}
              >
                <i className="fas fa-chevron-right" />
              </PaginationLink>
            </PaginationItem>
          )}
        </Pagination>
      </nav>
    );
  };

  return (
    <Fragment>
      <div className="col-md-12 mt-4 ">
        <h3 className="bld_secnd_title pl-8">
          {lbl ? lbl.classesSchedule : "Classes Schedule"}
        </h3>
      </div>
      {type === 2 && (
        <div className="col-md-3 col-sm-12 mt-2">
          <DatePicker
            className="custm_datepicker tg_dropicon"
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={onChnageDateRange}
            isClearable={false}
            dateFormat="dd MMM yyyy"
          />
        </div>
      )}
      <div className="col-md-12  mt-4">
        <table className="class-schedule-table">
          <thead>
            <tr>
              <th>{lbl ? lbl.classDate : "Class date"}</th>
              <th className="border-right-white border-left-white">
                {lbl ? lbl.classTime : "Class time"}
              </th>
              <th>{lbl ? lbl.attendanceStatus : "Attendance status"}</th>
            </tr>
          </thead>
          {type === 1 && (
            <tbody>
              {attendanceData !== null && attendanceData.docs.length > 0 ? (
                attendanceData.docs.map((data, i) => (
                  <tr className="pt-5" key={i}>
                    <td>
                      {data.date !== "" && data.date !== null && data.date}
                    </td>
                    <td className="border-left-blue border-right-blue">
                      {moment(data.fromTime).format("hh:mm A") +
                        " to " +
                        moment(data.toTime).format("hh:mm A")}
                    </td>
                    <td>
                      {data.status === 1 ? (
                        <span>{lbl ? lbl.present : "Present"}</span>
                      ) : data.status === 2 ? (
                        <span>{lbl ? lbl.absent : "Absent"}</span>
                      ) : data.status === 3 ? (
                        <span>{lbl ? lbl.leave : "Leave"}</span>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                ))
              ) : loader ? (
                <tr>
                  <TableLoader column={3} />
                </tr>
              ) : attendanceData !== null &&
                attendanceData.docs.length === 0 ? (
                <tr>
                  <td colSpan="3" className="py-4">
                    {lbl ? lbl.noScheduleFound : "No schedule found"}
                  </td>
                </tr>
              ) : null}
            </tbody>
          )}

          {type === 2 && (
            <tbody>
              {attendanceData !== null && attendanceData.length > 0 ? (
                attendanceData.map((data, i) => (
                  <tr className="pt-5" key={i}>
                    <td>
                      {data.date !== "" && data.date !== null && data.date}
                    </td>
                    <td className="border-left-blue border-right-blue">
                      {moment(data.fromTime).format("hh:mm A") +
                        " to " +
                        moment(data.toTime).format("hh:mm A")}
                    </td>
                    <td>
                      {data.status === 1 ? (
                        <span>{lbl ? lbl.present : "Present"}</span>
                      ) : data.status === 2 ? (
                        <span>{lbl ? lbl.absent : "Absent"}</span>
                      ) : data.status === 3 ? (
                        <span>{lbl ? lbl.leave : "Leave"}</span>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                ))
              ) : loader ? (
                <tr>
                  <TableLoader column={3} />
                </tr>
              ) : attendanceData !== null && attendanceData.length === 0 ? (
                <tr>
                  <td colSpan="3" className="py-4">
                    {lbl ? lbl.noScheduleFound : "No schedule found"}
                  </td>
                </tr>
              ) : null}
            </tbody>
          )}
        </table>
      </div>
      {type === 1 &&
        attendanceData !== "" &&
        attendanceData !== null &&
        attendanceData.docs.length > 10 && (
          <div className="row">
            <div className="col-6 mt-3">
              <select
                className="form-select form-select-sm select-limit"
                onChange={(e) => onHandleLimitChange(e)}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={50}>50</option>
              </select>
            </div>
            <div className="col-6 mt-3">
              <div className="text-left">
                {renderPaginationSection(
                  attendanceData !== null && attendanceData
                )}
              </div>
            </div>
          </div>
        )}
    </Fragment>
  );
};
MyAttendenceComponent.propTypes = {
  getMyAttendence: PropTypes.func.isRequired,
};

export default connect(null, {
  getMyAttendence,
})(MyAttendenceComponent);
