export const countryCodes = [
  { name: "HONG KONG", phonecode: "852" },
  { name: "ALBANIA", phonecode: "355" },
  { name: "AFGHANISTAN", phonecode: "93" },
  { name: "ALGERIA", phonecode: "213" },
  { name: "AMERICAN SAMOA", phonecode: "1684" },
  { name: "ANDORRA", phonecode: "376" },
  { name: "ANGOLA", phonecode: "244" },
  { name: "ANGUILLA", phonecode: "1264" },
  { name: "ANTARCTICA", phonecode: "0" },
  { name: "ANTIGUA AND BARBUDA", phonecode: "1268" },
  { name: "ARGENTINA", phonecode: "54" },
  { name: "ARMENIA", phonecode: "374" },
  { name: "ARUBA", phonecode: "297" },
  { name: "AUSTRALIA", phonecode: "61" },
  { name: "AUSTRIA", phonecode: "43" },
  { name: "AZERBAIJAN", phonecode: "994" },
  { name: "BAHAMAS", phonecode: "1242" },
  { name: "BAHRAIN", phonecode: "973" },
  { name: "BANGLADESH", phonecode: "880" },
  { name: "BARBADOS", phonecode: "1246" },
  { name: "BELARUS", phonecode: "375" },
  { name: "BELGIUM", phonecode: "32" },
  { name: "BELIZE", phonecode: "501" },
  { name: "BENIN", phonecode: "229" },
  { name: "BERMUDA", phonecode: "1441" },
  { name: "BHUTAN", phonecode: "975" },
  { name: "BOLIVIA", phonecode: "591" },
  { name: "BOSNIA AND HERZEGOVINA", phonecode: "387" },
  { name: "BOTSWANA", phonecode: "267" },
  { name: "BOUVET ISLAND", phonecode: "0" },
  { name: "BRAZIL", phonecode: "55" },
  { name: "BRITISH INDIAN OCEAN TERRITORY", phonecode: "246" },
  { name: "BRUNEI DARUSSALAM", phonecode: "673" },
  { name: "BULGARIA", phonecode: "359" },
  { name: "BURKINA FASO", phonecode: "226" },
  { name: "BURUNDI", phonecode: "257" },
  { name: "CAMBODIA", phonecode: "855" },
  { name: "CAMEROON", phonecode: "237" },
  { name: "CANADA", phonecode: "1" },
  { name: "CAPE VERDE", phonecode: "238" },
  { name: "CAYMAN ISLANDS", phonecode: "1345" },
  { name: "CENTRAL AFRICAN REPUBLIC", phonecode: "236" },
  { name: "CHAD", phonecode: "235" },
  { name: "CHILE", phonecode: "56" },
  { name: "CHINA", phonecode: "86" },
  { name: "CHRISTMAS ISLAND", phonecode: "61" },
  { name: "COCOS (KEELING) ISLANDS", phonecode: "672" },
  { name: "COLOMBIA", phonecode: "57" },
  { name: "COMOROS", phonecode: "269" },
  { name: "CONGO", phonecode: "242" },
  { name: "CONGO, THE DEMOCRATIC REPUBLIC OF THE", phonecode: "242" },
  { name: "COOK ISLANDS", phonecode: "682" },
  { name: "COSTA RICA", phonecode: "506" },
  { name: "COTE D'IVOIRE", phonecode: "225" },
  { name: "CROATIA", phonecode: "385" },
  { name: "CUBA", phonecode: "53" },
  { name: "CYPRUS", phonecode: "357" },
  { name: "CZECH REPUBLIC", phonecode: "420" },
  { name: "DENMARK", phonecode: "45" },
  { name: "DJIBOUTI", phonecode: "253" },
  { name: "DOMINICA", phonecode: "1767" },
  { name: "DOMINICAN REPUBLIC", phonecode: "1809" },
  { name: "ECUADOR", phonecode: "593" },
  { name: "EGYPT", phonecode: "20" },
  { name: "EL SALVADOR", phonecode: "503" },
  { name: "EQUATORIAL GUINEA", phonecode: "240" },
  { name: "ERITREA", phonecode: "291" },
  { name: "ESTONIA", phonecode: "372" },
  { name: "ETHIOPIA", phonecode: "251" },
  { name: "FALKLAND ISLANDS (MALVINAS)", phonecode: "500" },
  { name: "FAROE ISLANDS", phonecode: "298" },
  { name: "FIJI", phonecode: "679" },
  { name: "FINLAND", phonecode: "358" },
  { name: "FRANCE", phonecode: "33" },
  { name: "FRENCH GUIANA", phonecode: "594" },
  { name: "FRENCH POLYNESIA", phonecode: "689" },
  { name: "FRENCH SOUTHERN TERRITORIES", phonecode: "0" },
  { name: "GABON", phonecode: "241" },
  { name: "GAMBIA", phonecode: "220" },
  { name: "GEORGIA", phonecode: "995" },
  { name: "GERMANY", phonecode: "49" },
  { name: "GHANA", phonecode: "233" },
  { name: "GIBRALTAR", phonecode: "350" },
  { name: "GREECE", phonecode: "30" },
  { name: "GREENLAND", phonecode: "299" },
  { name: "GRENADA", phonecode: "1473" },
  { name: "GUADELOUPE", phonecode: "590" },
  { name: "GUAM", phonecode: "1671" },
  { name: "GUATEMALA", phonecode: "502" },
  { name: "GUINEA", phonecode: "224" },
  { name: "GUINEA-BISSAU", phonecode: "245" },
  { name: "GUYANA", phonecode: "592" },
  { name: "HAITI", phonecode: "509" },
  { name: "HEARD ISLAND AND MCDONALD ISLANDS", phonecode: "0" },
  { name: "HOLY SEE (VATICAN CITY STATE)", phonecode: "39" },
  { name: "HONDURAS", phonecode: "504" },
  { name: "HUNGARY", phonecode: "36" },
  { name: "ICELAND", phonecode: "354" },
  { name: "INDIA", phonecode: "91" },
  { name: "INDONESIA", phonecode: "62" },
  { name: "IRAN, ISLAMIC REPUBLIC OF", phonecode: "98" },
  { name: "IRAQ", phonecode: "964" },
  { name: "IRELAND", phonecode: "353" },
  { name: "ISRAEL", phonecode: "972" },
  { name: "ITALY", phonecode: "39" },
  { name: "JAMAICA", phonecode: "1876" },
  { name: "JAPAN", phonecode: "81" },
  { name: "JORDAN", phonecode: "962" },
  { name: "KAZAKHSTAN", phonecode: "7" },
  { name: "KENYA", phonecode: "254" },
  { name: "KIRIBATI", phonecode: "686" },
  { name: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF", phonecode: "850" },
  { name: "KOREA, REPUBLIC OF", phonecode: "82" },
  { name: "KUWAIT", phonecode: "965" },
  { name: "KYRGYZSTAN", phonecode: "996" },
  { name: "LAO PEOPLE'S DEMOCRATIC REPUBLIC", phonecode: "856" },
  { name: "LATVIA", phonecode: "371" },
  { name: "LEBANON", phonecode: "961" },
  { name: "LESOTHO", phonecode: "266" },
  { name: "LIBERIA", phonecode: "231" },
  { name: "LIBYAN ARAB JAMAHIRIYA", phonecode: "218" },
  { name: "LIECHTENSTEIN", phonecode: "423" },
  { name: "LITHUANIA", phonecode: "370" },
  { name: "LUXEMBOURG", phonecode: "352" },
  { name: "MACAO", phonecode: "853" },
  { name: "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF", phonecode: "389" },
  { name: "MADAGASCAR", phonecode: "261" },
  { name: "MALAWI", phonecode: "265" },
  { name: "MALAYSIA", phonecode: "60" },
  { name: "MALDIVES", phonecode: "960" },
  { name: "MALI", phonecode: "223" },
  { name: "MALTA", phonecode: "356" },
  { name: "MARSHALL ISLANDS", phonecode: "692" },
  { name: "MARTINIQUE", phonecode: "596" },
  { name: "MAURITANIA", phonecode: "222" },
  { name: "MAURITIUS", phonecode: "230" },
  { name: "MAYOTTE", phonecode: "269" },
  { name: "MEXICO", phonecode: "52" },
  { name: "MICRONESIA, FEDERATED STATES OF", phonecode: "691" },
  { name: "MOLDOVA, REPUBLIC OF", phonecode: "373" },
  { name: "MONACO", phonecode: "377" },
  { name: "MONGOLIA", phonecode: "976" },
  { name: "MONTSERRAT", phonecode: "1664" },
  { name: "MOROCCO", phonecode: "212" },
  { name: "MOZAMBIQUE", phonecode: "258" },
  { name: "MYANMAR", phonecode: "95" },
  { name: "NAMIBIA", phonecode: "264" },
  { name: "NAURU", phonecode: "674" },
  { name: "NEPAL", phonecode: "977" },
  { name: "NETHERLANDS", phonecode: "31" },
  { name: "NETHERLANDS ANTILLES", phonecode: "599" },
  { name: "NEW CALEDONIA", phonecode: "687" },
  { name: "NEW ZEALAND", phonecode: "64" },
  { name: "NICARAGUA", phonecode: "505" },
  { name: "NIGER", phonecode: "227" },
  { name: "NIGERIA", phonecode: "234" },
  { name: "NIUE", phonecode: "683" },
  { name: "NORFOLK ISLAND", phonecode: "672" },
  { name: "NORTHERN MARIANA ISLANDS", phonecode: "1670" },
  { name: "NORWAY", phonecode: "47" },
  { name: "OMAN", phonecode: "968" },
  { name: "PAKISTAN", phonecode: "92" },
  { name: "PALAU", phonecode: "680" },
  { name: "PALESTINIAN TERRITORY, OCCUPIED", phonecode: "970" },
  { name: "PANAMA", phonecode: "507" },
  { name: "PAPUA NEW GUINEA", phonecode: "675" },
  { name: "PARAGUAY", phonecode: "595" },
  { name: "PERU", phonecode: "51" },
  { name: "PHILIPPINES", phonecode: "63" },
  { name: "PITCAIRN", phonecode: "0" },
  { name: "POLAND", phonecode: "48" },
  { name: "PORTUGAL", phonecode: "351" },
  { name: "PUERTO RICO", phonecode: "1787" },
  { name: "QATAR", phonecode: "974" },
  { name: "REUNION", phonecode: "262" },
  { name: "ROMANIA", phonecode: "40" },
  { name: "RUSSIAN FEDERATION", phonecode: "70" },
  { name: "RWANDA", phonecode: "250" },
  { name: "SAINT HELENA", phonecode: "290" },
  { name: "SAINT KITTS AND NEVIS", phonecode: "1869" },
  { name: "SAINT LUCIA", phonecode: "1758" },
  { name: "SAINT PIERRE AND MIQUELON", phonecode: "508" },
  { name: "SAINT VINCENT AND THE GRENADINES", phonecode: "1784" },
  { name: "SAMOA", phonecode: "684" },
  { name: "SAN MARINO", phonecode: "378" },
  { name: "SAO TOME AND PRINCIPE", phonecode: "239" },
  { name: "SAUDI ARABIA", phonecode: "966" },
  { name: "SENEGAL", phonecode: "221" },
  { name: "SERBIA AND MONTENEGRO", phonecode: "381" },
  { name: "SEYCHELLES", phonecode: "248" },
  { name: "SIERRA LEONE", phonecode: "232" },
  { name: "SINGAPORE", phonecode: "65" },
  { name: "SLOVAKIA", phonecode: "421" },
  { name: "SLOVENIA", phonecode: "386" },
  { name: "SOLOMON ISLANDS", phonecode: "677" },
  { name: "SOMALIA", phonecode: "252" },
  { name: "SOUTH AFRICA", phonecode: "27" },
  { name: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS", phonecode: "0" },
  { name: "SPAIN", phonecode: "34" },
  { name: "SRI LANKA", phonecode: "94" },
  { name: "SUDAN", phonecode: "249" },
  { name: "SURNAME", phonecode: "597" },
  { name: "SVALBARD AND JAN MAYEN", phonecode: "47" },
  { name: "SWAZILAND", phonecode: "268" },
  { name: "SWEDEN", phonecode: "46" },
  { name: "SWITZERLAND", phonecode: "41" },
  { name: "SYRIAN ARAB REPUBLIC", phonecode: "963" },
  { name: "TAIWAN, PROVINCE OF CHINA", phonecode: "886" },
  { name: "TAJIKISTAN", phonecode: "992" },
  { name: "TANZANIA, UNITED REPUBLIC OF", phonecode: "255" },
  { name: "THAILAND", phonecode: "66" },
  { name: "TIMOR-LESTE", phonecode: "670" },
  { name: "TOGO", phonecode: "228" },
  { name: "TOKELAU", phonecode: "690" },
  { name: "TONGA", phonecode: "676" },
  { name: "TRINIDAD AND TOBAGO", phonecode: "1868" },
  { name: "TUNISIA", phonecode: "216" },
  { name: "TURKEY", phonecode: "90" },
  { name: "TURKMENISTAN", phonecode: "7370" },
  { name: "TURKS AND CAICOS ISLANDS", phonecode: "1649" },
  { name: "TUVALU", phonecode: "688" },
  { name: "UGANDA", phonecode: "256" },
  { name: "UKRAINE", phonecode: "380" },
  { name: "UNITED ARAB EMIRATES", phonecode: "971" },
  { name: "UNITED KINGDOM", phonecode: "44" },
  { name: "UNITED STATES", phonecode: "1" },
  { name: "UNITED STATES MINOR OUTLYING ISLANDS", phonecode: "1" },
  { name: "URUGUAY", phonecode: "598" },
  { name: "UZBEKISTAN", phonecode: "998" },
  { name: "VANUATU", phonecode: "678" },
  { name: "VENEZUELA", phonecode: "58" },
  { name: "VIET NAM", phonecode: "84" },
  { name: "VIRGIN ISLANDS, BRITISH", phonecode: "1284" },
  { name: "VIRGIN ISLANDS, U.S.", phonecode: "1340" },
  { name: "WALLIS AND FUTUNA", phonecode: "681" },
  { name: "WESTERN SAHARA", phonecode: "212" },
  { name: "YEMEN", phonecode: "967" },
  { name: "ZAMBIA", phonecode: "260" },
  { name: "ZIMBABWE", phonecode: "263" },
];
