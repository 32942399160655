import React, { Fragment, useEffect, useState } from "react";
import { verifyEmail } from "../../../actions/authActions";
import { Link, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import emailVerified from "../../../assets/images/verified.png";
import labels from "../../../labels.json";

function VerfifyRegisteredEmail(props) {
  const lbl = labels[process.env.REACT_APP_LABEL_TYPE];
  const { verifyEmail } = props;
  const { code } = useParams();

  const [verify, setVerify] = useState(null);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    verifyEmail({ registration_code: code })
      .then((res) => {
        setMessage(res.data.message);
        setVerify(true);
      })
      .catch((err) => {
        setMessage(err.response.data.message);
        setVerify(false);
      });
  }, [verifyEmail, code]);

  return (
    <Fragment>
      <div className="col-lg-3 col-md-4 main_illustration text-center">
        <img
          src={emailVerified}
          className="mt-0 mt-md-5 email_icon"
          alt="menu icon "
        />
      </div>
      <div className="col-lg-5 col-md-6 mt-3 tg-display">
        <div className="row">
          {verify === true ? (
            <div className="col-md-12">
              <p className="gnrl_para2">{message}</p>
              <p className="gnrl_para2">
                {lbl ? lbl.clickHereTo : "Click here to"}
                <Link
                  to="/student/login"
                  style={{
                    textDecoration: "none",
                    marginLeft: "5px",
                    color: "#005da1",
                    fontWeight: "bold",
                  }}
                >
                  {lbl ? lbl.login : "Login"}
                </Link>
              </p>
            </div>
          ) : (
            <div className="col-md-12">
              <p className="title_para2">{message}</p>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

VerfifyRegisteredEmail.propTypes = {
  verifyEmail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { verifyEmail })(
  VerfifyRegisteredEmail
);
