import React, { Fragment } from "react";
import { useLocation, useParams } from "react-router-dom";
import login from "../../../assets/images/student-login.svg";
import register from "../../../assets/images/student-registration.svg";
import forgotPassword from "../../../assets/images/forgot-password.png";
import coachLogin from "../../../assets/images/coach-login.png";

export default function LeftSideImage() {
  const { pathname } = useLocation();
  const { code } = useParams();

  return (
    <Fragment>
      <div className="col-lg-7 col-md-6 main_illustration">
        {pathname === "/student/login" ? (
          <img src={login} className="mt-0 mt-md-5" alt="menu icon" />
        ) : pathname === "/student/register" ? (
          <img src={register} className="mt-0 mt-md-5" alt="menu icon" />
        ) : pathname === "/forgot-password" ? (
          <img src={forgotPassword} alt="menu icon" />
        ) : pathname === `/reset-password/${code}` ? (
          <img src={forgotPassword} className="mt-0 mt-md-5" alt="menu icon" />
        ) : pathname === "/coach/login" ? (
          <img src={coachLogin} className="mt-0 mt-md-5" alt="menu icon" />
        ) : pathname === "/coach/register" ? (
          <img src={register} className="mt-0 mt-md-5" alt="menu icon" />
        ) : (
          ""
        )}
      </div>
    </Fragment>
  );
}
