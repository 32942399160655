import React, { Fragment, useEffect, useState } from "react";
import "./myEnrollments.css";
import { Modal, ModalBody } from "reactstrap";
import {
    addPaymentComment,
    getMyEnrollments,
} from "../../../actions/enrollmentsAction";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NoImageFound from "../../../assets/images/no-course-found.jpg";
import classnames from "classnames";
import MyAttendenceComponent from "./MyAttendenceComponent";
import { EnrollmentLoader } from "../../../components/ContentLoader/EnrollmentLoader";
import { toast, ToastContainer } from "react-toastify";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import { scrollToTop } from "../../../Helpers/ScrollToTop";
import { checkRequiredValidationWithMinMax } from "../../../Helpers/Validation";
import labels from "../../../labels.json";

const MyEnrollmentComponent = (routerProps) => {
    const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

    const { getMyEnrollments, enrollments, addPaymentComment } = routerProps;
    const { loading, myEnrollments, myEnrollmentList } = enrollments;

    useEffect(() => {
        scrollToTop();
        enrollments.myEnrollmentList = [];
        getMyEnrollments({ page: 1, limit: 10 });
    }, []);

    const [openStatusModal, setOpenStatusModal] = useState(false);
    const [paymentType, setPaymentType] = useState(1);
    const [paymentDate, setPaymentDate] = useState(null);
    const [paymentID, setPaymentID] = useState(null);
    const [comment, setComment] = useState("");
    const [commentError, setCommentError] = useState("");
    const [loader, setLoader] = useState(false);

    // ---------- toggle status details modal ----------
    const toggleStatusDetails = (paymentData) => {
        setOpenStatusModal(true);
        setPaymentDate(paymentData.createdAt);
        setPaymentID(paymentData._id);
        setComment(paymentData.comment);
        setPaymentType(paymentData.payment_type);
    };

    // -------- add comment on click --------
    const addComment = (e) => {
        let errComment = null;
        let commentLbl = "Comment ";
        if (lbl && lbl.comment) commentLbl = lbl.comment;

        errComment = checkRequiredValidationWithMinMax(comment, commentLbl, 2, 255);

        if (errComment) {
            setCommentError(errComment);
        } else {
            setCommentError("");
            setLoader(true);

            addPaymentComment({ id: paymentID, comment })
                .then((res) => {
                    enrollments.myEnrollmentList = [];
                    toast.success(res.data.message);
                    getMyEnrollments({ page: 1, limit: 10 }).then((res) => {
                        setLoader(false);
                        setOpenStatusModal(false);
                        setPaymentID(null);
                        setPaymentType(1);
                    });
                })
                .catch((err) => {
                    toast.error(err.response.data.message);
                    setLoader(false);
                });
        }
    };

    // ---------- add comment on enter -----------
    const addCommentOnEnter = (e) => {
        if (e.key === "Enter") {
            addComment();
        }
    };

    const onPageClick = (page) => {
        getMyEnrollments({ page: page, limit: 10 });
    };

    const viewMore = (data) => {
        const { hasNextPage, nextPage } = data;

        return (
            <div className="w-100 ">
                <button
                    hidden={hasNextPage === true ? false : true}
                    onClick={hasNextPage === true ? () => onPageClick(nextPage) : null}
                    className=" blue_fill_btn view-more-paginate-btn btn-sm "
                >
                    {lbl ? lbl.viewMore : "View more..."}
                </button>
            </div>
        );
    };
    // ---------- render status detail in modal ----------
    const renderStatusDetailsModal = () => {
        return (
            <Fragment>
                <Modal isOpen={openStatusModal}>
                    <ModalBody className="modal-body clos_icon modal-body-container  ">
                        <div className="col-md-12">
                            <h3 className="modal-label w-100">
                                {lbl ? lbl.paymentDate : "Payment Date"}

                                <span
                                    className="close_btn"
                                    onClick={() => {
                                        setOpenStatusModal(false);
                                        setPaymentType(1);
                                    }}
                                >
                                    x
                                </span>
                            </h3>
                            <p className="modal-description">
                                {paymentDate ? paymentDate : "No date found"}
                            </p>
                        </div>
                        <div className="col-md-12 mt-4">
                            <h3 className="modal-label w-100">
                                {lbl ? lbl.paymentMethod : "Payment method"}
                            </h3>
                            {paymentType === 1 ? (
                                <p className="modal-description">
                                    {lbl ? lbl.stripe : "Stripe"}
                                </p>
                            ) : (
                                <p className="modal-description">
                                    {lbl ? lbl.offline : "Offline"}
                                </p>
                            )}
                        </div>
                        <div className="col-md-12 mt-4 enrollment-comment">
                            <h3 className="modal-label w-100">
                                {lbl ? lbl.comment : " Comment"}
                            </h3>
                            <textarea
                                rows="5"
                                className={classnames(
                                    "form-control border-none",
                                    { invalid: commentError?.length > 0 },
                                    { valid: commentError?.length === 0 }
                                )}
                                onChange={(e) => setComment(e.target.value)}
                                value={comment}
                                keydown={(e) => addCommentOnEnter(e)}
                            ></textarea>
                            {commentError ? (
                                <span className="invalid-text">{commentError}</span>
                            ) : null}
                        </div>
                        <div className="w-100 mt-4">
                            <ButtonComponent
                                loading={loader}
                                title={lbl ? lbl.submit : "Submit"}
                                onBtnClick={(e) => addComment(e)}
                            />
                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>
        );
    };

    return (
        <Fragment>
            <div className="col-md-8 col-lg-9 px-2 mt-4 mt-sm-0 ">
                <ToastContainer />
                {myEnrollments?.docs?.length > 0 && !loading ? (
                    myEnrollmentList.map((enrollment, i) => (
                        <div className="stdnt_home_main_box mb-4" key={i}>
                            <div className="row mb-4">
                                <div className="col-md-12">
                                    <div className="feed_common_user_profile_txt mt-0">
                                        <p className="gnrl_para m-0 pl-8 font-18">
                                            {enrollment.title ? enrollment.title : "Title not Found"}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <div className="feed_img_main ">
                                        <img
                                            src={enrollment.image ? enrollment.image : NoImageFound}
                                            className="w-100 h-100"
                                            alt="course"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-4 ">
                                    <h3 className="bld_secnd_title pl-8">
                                        {lbl ? lbl.aboutTheCourse : "About the course"}
                                    </h3>
                                </div>
                                <div className="col-md-12 mt-2 ">
                                    <p className="color_gray gnrl_para mt-0 pl-8" style={{ whiteSpace: 'pre-wrap' }}>
                                        {enrollment.description
                                            ? enrollment.description
                                            : "Description not Found"}
                                    </p>
                                </div>
                                <div className="col-md-12 mt-4 ">
                                    <h3 className="bld_secnd_title  pl-8">
                                        {lbl ? lbl.classBalance : "Class Balance"}
                                    </h3>
                                    <p className="color_gray gnrl_para mt-2 pl-8">
                                        {
                                            enrollment.studentpaymentsData.payment_status === 1 ?
                                            (
                                                enrollment.leave > (enrollment.sick_leave + enrollment.absence_leave) ?
                                                (enrollment.studentenrolementData.no_of_classes - (enrollment.attendances_times || 0))
                                                :
                                                (
                                                    enrollment.studentenrolementData.no_of_classes
                                                    - (enrollment.attendances_times || 0)
                                                    - (enrollment.absence_leave || 0)
                                                )
                                            ) 
                                            :(enrollment.studentenrolementData.no_of_classes || '-')
                                        } 
                                        &nbsp;Classes
                                    </p>
                                </div>
                                <div className="col-md-12 mt-4 ">
                                    <h3 className="bld_secnd_title  pl-8">
                                        {lbl ? lbl.leaveBalance : "Leave Balance"}
                                    </h3>
                                    <p className="color_gray gnrl_para mt-2 pl-8">
                                        {/* {enrollment.leave ? enrollment.leave + " Leaves" : "-"} */}
                                        {
                                            enrollment.studentpaymentsData.payment_status === 1 ?
                                            (
                                                enrollment.leave > ((enrollment.sick_leave || 0) + (enrollment.absence_leave || 0)) ?
                                                (
                                                    enrollment.leave
                                                    - (enrollment.absence_leave || 0)
                                                    - (enrollment.sick_leave || 0) + ' Leaves'
                                                ) :
                                                '-'
                                            ) :
                                            (enrollment.leave || '-')
                                        }
                                        
                                    </p>
                                </div>
                                <div className="col-md-12 mt-3 ">
                                    <p className="bld_secnd_title pl-8">
                                        {lbl ? lbl.paymentStatus : "Payment Status :"}{" "}
                                    </p>

                                    {/* payment_type = 1=Stripe,2=Offline */}
                                    {/* payment_status = 1=Success,2=pending,3=cancel,4=declined */}

                                    {enrollment.studentpaymentsData.payment_status &&
                                        enrollment.studentpaymentsData.payment_status === 1 ? (
                                        <p className="status-paid ">{lbl ? lbl.paid : "Paid"}</p>
                                    ) : enrollment.studentpaymentsData.payment_status === 2 ? (
                                        <p className="status-pending ">
                                            {lbl ? lbl.pending : "Pending"}
                                        </p>
                                    ) : null}

                                    <button
                                        className={classnames(
                                            "cust_btn_pad",
                                            {
                                                "view-details-paid ":
                                                    enrollment.studentpaymentsData.payment_status &&
                                                    enrollment.studentpaymentsData.payment_status === 1,
                                            },
                                            {
                                                "view-details-pending ":
                                                    enrollment.studentpaymentsData.payment_status &&
                                                    enrollment.studentpaymentsData.payment_status === 2,
                                            }
                                        )}
                                        onClick={(e) =>
                                            toggleStatusDetails(enrollment.studentpaymentsData)
                                        }
                                    >
                                        {lbl ? lbl.viewDetails : "View Details"}
                                    </button>
                                </div>

                                <MyAttendenceComponent
                                    courseId={enrollment._id}
                                    type={enrollment.type}
                                />
                            </div>
                        </div>
                    ))
                ) : loading ? (
                    <EnrollmentLoader />
                ) : myEnrollments?.docs?.length === 0 && !loading ? (
                    <div className="stdnt_home_main_box no-enrollment">
                        <span>
                            {lbl
                                ? lbl.NoEnrollmentRecordsFound
                                : "No enrollment records found"}
                        </span>
                    </div>
                ) : null}

                {renderStatusDetailsModal()}
                <div className=" w-100 mt-2">{viewMore(myEnrollments)}</div>
            </div>
        </Fragment>
    );
};

MyEnrollmentComponent.propTypes = {
    getMyEnrollments: PropTypes.func.isRequired,
    enrollments: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    enrollments: state.enrollment,
});
export default connect(mapStateToProps, {
    getMyEnrollments,
    addPaymentComment,
})(MyEnrollmentComponent);
