import React, { Fragment, useEffect, useState } from "react";
import ProfileHeader from "../../CommonComponents/ProfileHeader/ProfileHeader";
import giftIcon from "../../../assets/images/gifts-white.svg";
import course from "../../../assets/images/course-black.svg";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import DefaultHeader from "../../../DefaultLayout/DefaultHeader";
import "./coachGifts.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  assignGift,
  getAllCourseList,
  getCoachGifts,
  giftsNotSend,
} from "../../../actions/coachGiftsAction";
import { scrollToTop } from "../../../Helpers/ScrollToTop";
import ToastifyContainer from "../../../components/Toastify/ToastContainer";
import { toast } from "react-toastify";
import { CoachGiftsLoader } from "../../../components/ContentLoader/CoachGiftsLoader";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import labels from "../../../labels.json";

const CoachGifts = (props) => {
  const lbl = labels[process.env.REACT_APP_LABEL_TYPE];
  const { getCoachGifts, getAllCourseList, gift, assignGift, giftsNotSend } = props;
  const { coachGifts, giftLoading } = gift;

  const [selectedCourse, setSelectedCourse] = useState(null);
  const [allCourses, setAllCourses] = useState([]);

  useEffect(() => {
    scrollToTop();
    getCourseList();
  }, []);

  // useEffect(() => {
  //   if (allCourses.length > 0) {
  //     getCoachGifts({
  //       course: allCourses[0]._id,
  //       page: 1,
  //       limit: 10,
  //     });
  //     setSelectedCourse(allCourses[0]);
  //   }
  // }, [allCourses]);

  const getCourseList = async() => {
    const res = await getAllCourseList()
    if(res.data.result.length > 0){
      setAllCourses(res.data.result)
      setSelectedCourse(res.data.result[0]);
      getCoachGifts({
        course: res.data.result[0]._id,
        page: 1,
        limit: 10,
      });
      getCoachGiftsNotSend(res.data.result)
    }
  }

  const getCoachGiftsNotSend = async(arr) => {
    const res = await giftsNotSend({ courses: arr.map(t => t._id) })
    if(res.data.result){
      res.data.result.docs.map(t => {
        if(t.gift_given == 2) toast.success(`${t.courseData.title} ${lbl.hasGiftSend}`)
      })
    }
  }

  const handleChange = (gift) => {
    let giftStatus = 2;
    if (gift.gift_given === 2) {
      giftStatus = 1;
    }
    const giftObj = {
      id: gift._id,
      gift_given: giftStatus,
    };

    assignGift(giftObj)
      .then((res) => {
        setTimeout(() => {
          getCoachGifts({
            course: selectedCourse._id,
            page: 1,
            limit: 10,
          }).then(() => {
            toast.success(res.data.message);
          });
        }, 1000);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleCourseChange = (e) => {
    const { value } = e.target;

    const course = JSON.parse(value);
    getCoachGifts({
      course: course._id,
      page: 1,
      limit: 10,
    })
      .then(() => {
        setSelectedCourse(course);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setSelectedCourse(course);
      });
  };

  const onPageClick = (page) => {
    getCoachGifts({
      course: selectedCourse._id,
      page: page,
      limit: 10,
    });
  };

  // ------------ Pagination Section ------------
  const renderPaginationSection = (data) => {
    const { page, totalPages, hasPrevPage, hasNextPage, prevPage, nextPage } =
      data;

    let Pages = [];
    let skipped = 0;

    for (var i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        (page < 4 && i <= 5) ||
        i === page - 1 ||
        i === page + 1 ||
        i === page ||
        i === totalPages ||
        (page >= totalPages - 3 && i >= totalPages - 4)
      ) {
        const test = i;
        const item = (
          <React.Fragment key={i}>
            {skipped === 1 ? (
              <PaginationItem>
                <PaginationLink disabled tag="button">
                  ...
                </PaginationLink>
              </PaginationItem>
            ) : null}
            <PaginationItem
              active={page === i ? true : false}
              onClick={page === i ? () => null : () => onPageClick(test)}
              key={i}
            >
              <PaginationLink tag="button">{i}</PaginationLink>
            </PaginationItem>
          </React.Fragment>
        );
        skipped = 0;
        Pages.push(item);
      } else {
        skipped = 1;
      }
    }

    return (
      <nav className="pagination-nav">
        <Pagination>
          {hasPrevPage && (
            <PaginationItem
              onClick={
                hasPrevPage === true ? () => onPageClick(prevPage) : null
              }
            >
              <PaginationLink
                previous
                disabled={hasPrevPage === true ? false : true}
                tag="button"
              >
                <i class="fas fa-chevron-left" />
              </PaginationLink>
            </PaginationItem>
          )}
          {Pages}
          {hasNextPage && (
            <PaginationItem
              onClick={
                hasNextPage === true ? () => onPageClick(nextPage) : null
              }
            >
              <PaginationLink
                next
                tag="button"
                disabled={hasNextPage === true ? false : true}
              >
                <i class="fas fa-chevron-right" />
              </PaginationLink>
            </PaginationItem>
          )}
        </Pagination>
      </nav>
    );
  };

  return (
    <Fragment>
      <DefaultHeader />
      <ProfileHeader />
      <section className="student_profile_main mar_top">
        <ToastifyContainer />
        <div className="container">
          <div className="row">
            <div className="col-md-6 mt-2 mt-4 mb-3">
              <Link to="/coach/my-courses">
                <button className=" my_course_btn tg_btn_toggle ">
                  <img src={course} alt="gifts" />
                  {lbl ? lbl.myCourse : "My Courses"}
                </button>
              </Link>
              <Link to="/coach/gifts">
                <button className=" my_btn_gift tg_gift_togl margin-0 my-active">
                  <img src={giftIcon} alt="gifts" /> {lbl ? lbl.gifts : "Gifts"}
                </button>
              </Link>
            </div>
            <div className="col-md-6 col-12 mt-2 mt-4 mb-3">
              <select
                className="form-select update_profile_select course-select "
                onChange={(e) => handleCourseChange(e)}
              >
                {allCourses.length > 0 ? (
                  allCourses.map((course, i) => (
                    <option value={JSON.stringify(course)} key={i}>
                      {course.title}
                    </option>
                  ))
                ) : (
                  <option value="">
                    {lbl ? lbl.noCoursesAvailable : "No Courses available"}
                  </option>
                )}
              </select>
              <div className="select-lbl">{lbl ? lbl.courses : "Course"}</div>
            </div>
            <div className="col-sm-12 mar_top">
              <div className="stdnt_home_main_box">
                <div className="row">
                  <div className="col-md-12">
                    <div className="enrolled_students_title ">
                      <p className="gnrl_para ml-5 Weight">
                        {lbl ? lbl.enrolledStudents : "Enrolled Students"}
                      </p>
                    </div>
                  </div>

                  <div className="container mt-4 mb-3">
                    {coachGifts.docs.length > 0 && !giftLoading ? (
                      coachGifts.docs.map((gift, i) => (
                        <div className="row px-2 mb-3" key={i}>
                          <div className="col-xs-12 col-sm-12  col-lg-3">
                            <div className="gifts_user_profile">
                              <div className="common_user_profile_img">
                                <img
                                  src={gift.studentData.profile_pic}
                                  alt="coach-gift"
                                />
                              </div>
                              <div className="user_name">
                                <p className="gnrl_para m-0">
                                  {gift.studentData.first_name +
                                    " " +
                                    gift.studentData.last_name}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-8 mt-2 col-lg-7 mb-2">
                            {/* <div className="gift_description"> */}
                            <div className="gifts_user_profile_txt text-color px-0 ">
                              {gift.gift_text}
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-2 ">
                            <div className="gift_given">
                              <p className="gnrl_para">
                                {lbl ? lbl.giftGiven : "Gift given?"}
                              </p>
                            </div>
                            <div className="gifts_switch">
                              <div className="custom-control custom-switch mt-1">
                                <Switch
                                  onChange={(e) => handleChange(gift)}
                                  offColor="#C5C5C5"
                                  onColor="#27AE60"
                                  checked={gift.gift_given === 1 ? true : false}
                                  height={25}
                                  width={55}
                                  uncheckedIcon={
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                        fontSize: 10,
                                        color: "#fff",
                                        paddingRight: 2,
                                      }}
                                    >
                                      {lbl ? lbl.no : "No"}
                                    </div>
                                  }
                                  checkedIcon={
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                        fontSize: 10,
                                        color: "#fff",
                                        paddingRight: 2,
                                      }}
                                    >
                                      {lbl ? lbl.yes : "Yes"}
                                    </div>
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : giftLoading ? (
                      <CoachGiftsLoader rows={3} />
                    ) : coachGifts.docs.length === 0 && !giftLoading ? (
                      <div className="w-100 text-center pt-1 pb-5 gnrl_para">
                        {lbl
                          ? lbl.noStudentsAreEnrolled
                          : "No students are enrolled in this course."}
                      </div>
                    ) : null}
                  </div>
                </div>

                {coachGifts.docs.length > 10 && (
                  <div className="row">
                    <div className="col-6">
                      {/* <select
                        className="form-select form-select-sm select-limit"
                        onChange={(e) => onHandleLimitChange(e)}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        <option value={50}>50</option>
                      </select> */}
                    </div>
                    <div className="col-6">
                      <div className="text-left">
                        {renderPaginationSection(coachGifts)}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

CoachGifts.propTypes = {
  getAllCourseList: PropTypes.func.isRequired,
  getCoachGifts: PropTypes.func.isRequired,
  giftsNotSend: PropTypes.func.isRequired,
  assignGift: PropTypes.func.isRequired,
  gift: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  gift: state.coachGifts,
});
export default connect(mapStateToProps, {
  getAllCourseList,
  getCoachGifts,
  assignGift,
  giftsNotSend,
})(CoachGifts);
