import { GET_NOTIFICATION_LIST } from "../actions/types";

const initialState = {
  notifications: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  notificationList: [],
  loading: true,
};

const notificationReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case GET_NOTIFICATION_LIST:
      return {
        ...state,
        notifications: payload,
        notificationList: state.notificationList.concat(payload.docs),
        loading: false,
      };
    default:
      return state;
  }
};
export default notificationReducer;
