import React, { useEffect, useState, Fragment } from "react";
import { Modal, ModalBody } from "reactstrap";
import Checkbox from "react-custom-checkbox";
import checkbox from "../../../assets/images/check.svg";
import { getCourseClassSchedule, getCourse_StudentList, studentCourseAttendance } from "../../../actions/coachCourseAction";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { PulseLoader } from "react-spinners";
import classNames from "classnames";
import { toast } from "react-toastify";
import ToastifyContainer from "../../../components/Toastify/ToastContainer";
import { useHistory } from "react-router-dom";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import labels from "../../../labels.json";
import { Radio } from 'antd';
import 'antd/dist/antd.css';

const AttendanceModal = (props) => {
    const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

    const {
        showModal,
        closeModal,
        getCourse_StudentList,
        editDate,
        fromTime,
        toTime,
        _id,
        getCourseClassSchedule,
        studentCourseAttendance,
        checkFromDate,
        checkToDate,
        type,
    } = props;

    const history = useHistory();

    const [loadingResponse, setLoadingResponse] = useState(false);
    const [loader, setLoader] = useState(false);
    const [classLoading, setClassLoading] = useState(true);

    const [errorMessage, setErrorMessage] = useState([]);
    const [studentArray, setStudentArray] = useState([]);
    const [studentList, setStudentList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [groupStudentList, setGroupStudentList] = useState([]);
    const [selectGroup, setSelectGroup] = useState(null);

    useEffect(() => {
        editDate && studentAttendance();
    }, [editDate, _id, getCourse_StudentList, showModal]);

    // 获取group，学生列表
    const studentAttendance = async() => {
        let date = moment(editDate).format("YYYY-MM-DD");
        setLoadingResponse(true);

        const res = await getCourse_StudentList(_id, date)
        if(res.data.result?.length >= 0) {
            let students = res.data.result
            setClassLoading(false);
            let StudentData = [];
            students.map((list, i) => {
                StudentData.push({
                    _id: list._id || '',
                    student: list?.studentData._id || '',
                    status: list.status || null,
                    leave_reason: list.leave_reason || '',
                });
            });
            setGroupList(res.data.groups);
            setStudentArray(StudentData);
            setStudentList(students);
            setLoadingResponse(false);
        }
    };

    // 关闭窗口，初始化数据
    const onCLoseAttendanceModal = () => {
        closeModal();
        setErrorMessage([]);
    };

    // 切换tab group
    const onSelectGroupChange = (e) => {
        let students = [ ...studentList ];
        let gArr = [];
        const StudentData = [];
        let gid = e.target.value;
        setSelectGroup(e.target.value)

        students.map((s, i) => {
            if (s?.studentData.group == gid) {
                const item = studentList.find(item => String(item.studentData._id) == String(s?.studentData._id))
                gArr.push(s)
                StudentData.push({
                    _id: s?._id || '',
                    student: s?.studentData._id || '',
                    status: item?.tag || s?.status,
                    leave_reason: item?.leave_reason || s?.leave_reason,
                });
            }
        })
        setStudentArray(StudentData);
        setGroupStudentList(gArr)
    }

    // 点击头像显示信息
    const showStudentMore = (idx) => {
        let students = [...groupStudentList];
        students[idx].studentData.show = students[idx].studentData.hasOwnProperty('show') ? !students[idx].studentData.show : true
        setGroupStudentList(students)
    }

    // 输入内容
    const onFieldChange = (e, id, status, index) => {
        e.preventDefault();
        let arr = [...studentArray];
        if (e.target.value !== "") {
            arr[index].student = id;
            arr[index].status = status;
            arr[index].leave_reason = e.target.value;
        } else {
            arr[index].leave_reason = "";
        }
        checkboxLog({ id, reason: e.target.value })
        setStudentArray(arr);
    };

    // 出席列
    const onSelectAttendancePresent = (isCheck, id, status, index) => {
        let arr = [...studentArray];
        if (isCheck) {
            arr[index].student = id;
            arr[index].status = status;
            arr[index].leave_reason = "";
        } else {
            arr[index].student = "";
            arr[index].status = null;
            arr[index].leave_reason = "";
        }

        checkboxLog({isCheck, id, status})
        setStudentArray(arr);
    };

    // 缺席列
    const onSelectAttendanceAbsent = (isCheck, id, status, index) => {
        let arr = [...studentArray];
        if (isCheck) {
            arr[index].student = id;
            arr[index].status = status;
            arr[index].leave_reason = "";
        } else {
            arr[index].student = "";
            arr[index].status = null;
            arr[index].leave_reason = "";
        }

        checkboxLog({isCheck, id, status})
        setStudentArray(arr);
    };

    // 备注列
    const onselectReason = (isCheck, id, status, index) => {
        let arr = [...studentArray];
        if (isCheck) {
            arr[index].student = id;
            arr[index].status = status;
            arr[index].leave_reason = "";
        } else {
            arr[index].student = "";
            arr[index].status = null;
            arr[index].leave_reason = "";
        }

        checkboxLog({isCheck, id, status})

        setStudentArray(arr);
    };

    // 全局courseList记录勾选
    const checkboxLog = ({ isCheck, id, status, reason }) => {
        console.log(isCheck,id,status,reason)
        let cArr = [ ...studentList ]
        cArr.map(item => {
            if(String(item.studentData._id) == String(id)) {
                item.checked = reason ? item.checked : isCheck
                // status: 1出席，2缺席，3备注
                item.tag = status || item.tag
                item.leave_reason = reason || item.leave_reason
            }
        })
        setStudentList(cArr)
    }

    const onSubmitAttendance = (e) => {

        let validationFlag = true;
        let data = [ ...studentList ];
        let text = ''
        let requestArr = []
        let errorMsg = data.map((classes, key) => {
            let error = {};
            if (!classes?.checked) {
                const item = groupList.find(item => String(classes.studentData?.group) === String(item._id))
                text = item && item.name
                validationFlag = false;
            }
        });
        if(!validationFlag) return toast.error(text + '組別還未完成勾選')

        if (validationFlag) {
            data.map(item => {
                requestArr.push({
                    _id: item._id,
                    student: item.studentData._id,
                    status: item.tag,
                    leave_reason: item.leave_reason
                })
            })
            
            setLoader(true);
            const studentData = {
                date: moment(editDate).format("YYYY-MM-DD"),
                fromTime: moment(editDate).format("YYYY-MM-DD") + ' ' + moment(fromTime).format('HH:mm:00'),
                toTime: moment(editDate).format("YYYY-MM-DD") + ' ' + moment(toTime).format('HH:mm:00'),
                course: _id,
                studentArray: requestArr,
            };
            studentCourseAttendance(studentData)
                .then((res) => {
                    toast.success(res.data.message);
                    getCourseClassSchedule(type, _id, checkFromDate, checkToDate);
                    setTimeout(() => {
                        history.push(`/coach/my-courses/details/${_id}`);
                        onCLoseAttendanceModal();
                        setLoader(false);
                    }, 2500);
                })
                .catch((error) => {
                    toast.error(error.response.data.message);
                    setLoader(false);
                });
        }
    };

    const onModalClose = () => {
        setStudentArray([])
        setStudentList([])
        setGroupList([])
        setGroupStudentList([])
        setSelectGroup([])
    }

    return (
        <Fragment>
            <ToastifyContainer />
            <Modal isOpen={showModal} size="xl" className="attendance_modal" onClosed={onModalClose} onExit={onModalClose}>
                <ModalBody className="modal-body w-100 modal_bgColor attendance_modal">
                    <div className="container stu_att_modal">
                        <span
                            className="close_btn mb-2"
                            onClick={() => onCLoseAttendanceModal()}
                        >
                            x
                        </span>
                        <div className="row">
                            <div className="col-lg-12 col-sm-12 col-12 p-0">
                                <div>
                                    <strong>
                                        {lbl ? lbl.attendanceDate : "Attendance Date"}
                                    </strong>
                                </div>
                                <div className="mt-2 mb-4 pb-2">{editDate} {moment(fromTime).format("LT")}&nbsp; - &nbsp;{moment(toTime).format("LT")}</div>
                                <div className="mb-2">
                                    <strong style={{ marginRight: "10px", display: groupList?.length > 0 ? "" : "none" }}>{lbl ? lbl.studentGroup : "Student Group"}</strong>
                                    {!loadingResponse &&
                                        groupList &&
                                        groupList?.length > 0 &&
                                        !classLoading ? (
                                        <Radio.Group defaultValue={selectGroup} onChange={onSelectGroupChange} buttonStyle="solid">
                                            {groupList.map((g, i) => {
                                                return <Radio.Button value={g._id} key={g._id}>{g.name} ({g.min_age}-{g.max_age}岁)</Radio.Button>
                                            })}
                                        </Radio.Group>
                                    ) : null}
                                </div>
                                <div className="mb-2">
                                    <strong>{lbl ? lbl.studentList : "Student List"}</strong>

                                </div>
                            </div>
                        </div>
                        {!loadingResponse &&
                            groupStudentList &&
                            groupStudentList?.length > 0 &&
                            !classLoading ? (
                            groupStudentList.map((student, index) => {
                                return (
                                    <div className="row mb-4 md-1" key={index}>
                                        <div className="col-lg-3 col-md-12 col-sm-12 mt-2 ">
                                            <div className="peers_common_user_profile w-100">
                                                <span className="stu_att_img" >
                                                    <img
                                                        src={student.studentData.profile_pic}
                                                        alt="student"
                                                        onClick={() => showStudentMore(index)}
                                                    />
                                                </span>
                                                <span className="stu_name" onClick={() => showStudentMore(index)}>
                                                    {student.studentData.first_name}&nbsp;
                                                    {student.studentData.last_name}
                                                </span>
                                                <div className={student.studentData.show ? 'row' : 'row hide'}>
                                                    <div className="w-100">
                                                        <div className="eme-conatiner">
                                                            <div className="stu_name">
                                                                <strong>
                                                                    {lbl ? lbl.phoneNumber : "Phone Number : "}
                                                                </strong>
                                                            </div>
                                                            <div className="stu_name">
                                                                {student.studentData.phone_number}
                                                            </div>
                                                            <div className="stu_name">
                                                                <strong>
                                                                    {lbl
                                                                        ? lbl.emergencyDetails
                                                                        : "Emergency details:"}
                                                                    &nbsp;
                                                                </strong>
                                                            </div>
                                                            <div className="stu_name">
                                                                {student.studentData.emergency_name}
                                                            </div>
                                                            <div className="stu_name">
                                                                {student.studentData.emergency_email}
                                                            </div>
                                                            <div className="stu_name">
                                                                {student.studentData.emergency_phone_number}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-12 col-sm-12 mt-3 ">
                                            <div className="custom-control custom-checkbox">
                                                <Checkbox
                                                    icon={<img src={checkbox} style={{ width: 16 }} alt="" />}
                                                    name="my-input"
                                                    borderColor="back"
                                                    style={{ cursor: "pointer", backgroundColor: "white" }}
                                                    labelStyle={{ marginLeft: 10, userSelect: "none" }}
                                                    borderRadius={5}
                                                    borderWidth={1}
                                                    size={22}
                                                    label={lbl ? lbl.present : "Present"}
                                                    checked={() => {
                                                        const item = studentList.find(item => String(student.studentData._id) == String(item.studentData._id))
                                                        return item && item?.tag === 1 && item?.checked
                                                        }
                                                    }
                                                    onChange={(e) =>
                                                        onSelectAttendancePresent(
                                                            e,
                                                            student.studentData._id,
                                                            1,
                                                            index
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-12 col-sm-12 mt-1 mt-md-3 ">
                                            <div className="custom-control custom-checkbox">
                                                <Checkbox
                                                    icon={
                                                        <img src={checkbox} style={{ width: 16 }} alt="" />
                                                    }
                                                    name="my-input"
                                                    borderColor="back"
                                                    style={{
                                                        cursor: "pointer",
                                                        backgroundColor: "white",
                                                    }}
                                                    labelStyle={{ marginLeft: 10, userSelect: "none" }}
                                                    borderRadius={5}
                                                    borderWidth={1}
                                                    size={22}
                                                    label={lbl ? lbl.absent : "Absent"}
                                                    checked={() => {
                                                        const item = studentList.find(item => String(student.studentData._id) == String(item.studentData._id))
                                                        return item && item?.tag === 2 && item?.checked
                                                        }
                                                    }
                                                    onChange={(e) =>
                                                        onSelectAttendanceAbsent(
                                                            e,
                                                            student.studentData._id,
                                                            2,
                                                            index
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-md-12 col-sm-12 mt-1 mt-md-3 ">
                                            <div className="custom-control custom-checkbox">
                                                <Checkbox
                                                    icon={
                                                        <img src={checkbox} style={{ width: 16 }} alt="" />
                                                    }
                                                    name="my-input"
                                                    borderColor="back"
                                                    style={{
                                                        cursor: "pointer",
                                                        backgroundColor: "white",
                                                    }}
                                                    labelStyle={{ marginLeft: 10, userSelect: "none" }}
                                                    borderRadius={5}
                                                    borderWidth={1}
                                                    size={22}
                                                    label={lbl ? lbl.leaveTaken : "Leave taken"}
                                                    checked={() => {
                                                        const item = studentList.find(item => String(student.studentData._id) == String(item.studentData._id))
                                                        return item && item?.tag === 3 && item?.checked
                                                        }
                                                    }
                                                    onChange={(e) =>
                                                        onselectReason(e, student.studentData._id, 3, index)
                                                    }
                                                />
                                            </div>
                                            <textarea
                                                key={student.studentData._id}
                                                name="comment"
                                                placeholder={ lbl ? lbl.reasonForTakenLeave : "Reason for taken leave" }
                                                defaultValue={
                                                    studentArray !== null &&
                                                        studentArray?.length > 0 &&
                                                        studentArray[index].student ===
                                                        student.studentData._id &&
                                                        studentArray[index].status === 3
                                                        ? studentArray[index].leave_reason
                                                        : ""
                                                }
                                                readOnly={
                                                    studentArray !== null &&
                                                        studentArray?.length > 0 &&
                                                        studentArray[index].student ===
                                                        student.studentData._id &&
                                                        studentArray[index].status === 3
                                                        ? false
                                                        : true
                                                }
                                                checked={() => {
                                                    const item = studentList.find(item => String(student.studentData._id) == String(item.studentData._id))
                                                    return item && item?.tag === 3 && item?.checked
                                                    }
                                                }
                                                className={classNames("form-control text_area")}
                                                onChange={(e) =>
                                                    onFieldChange(e, student.studentData._id, 3, index)
                                                }
                                            />
                                        </div>
                                        {/* <span className="error-box mt-2" key={student.studentData._id}>
                                            <span className="invalid-text">
                                                {errorMessage.length - 1 >= index && errorMessage[index].error_selected}
                                            </span>
                                        </span> */}
                                    </div>
                                );
                            })
                        ) : classLoading || loadingResponse ? (
                            <div className="col-xl-12 col-lg-12 col-md-12 mt-5">
                                <div className="col tg-display tg-content">
                                    <PulseLoader color="#005da1" size="15" />
                                </div>
                            </div>
                        ) : studentList?.length === 0 && !classLoading ? (
                            <div className="col-xl-12 col-lg-12 col-md-12 mt-5">
                                <div className="col tg-display tg-content">
                                    {lbl ? lbl.noStudentListFound : "No Student List found"}
                                </div>
                            </div>
                        ) : null}
                    </div>
                    {studentList?.length > 0 && !classLoading ? (
                        <div className="row justify-content-center mt-5">
                            <div className="col-lg-4">
                                <ButtonComponent
                                    loading={loader}
                                    title={lbl ? lbl.submit : "Submit"}
                                    onBtnClick={(e) => onSubmitAttendance(e)}
                                />
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </ModalBody>
            </Modal>
        </Fragment>
    );
};

AttendanceModal.propTypes = {
    getCourse_StudentList: PropTypes.func.isRequired,
    studentCourseAttendance: PropTypes.func.isRequired,
    coachClass: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    coachClass: state.coachClass,
});

export default connect(mapStateToProps, {
    getCourse_StudentList,
    getCourseClassSchedule,
    studentCourseAttendance,
})(AttendanceModal);
