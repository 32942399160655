import axios from "axios";
import { Get_Notifications } from "../api";
import { GET_NOTIFICATION_LIST } from "./types";

/**
 * Api method : Get API
 * Function called for getting the course details.
 */
export const getNotifications = (pagination) => async (dispatch) => {
  try {
    const res = await axios.get(Get_Notifications, { params: pagination });
    dispatch({
      type: GET_NOTIFICATION_LIST,
      payload: res.data.result,
    });
    return res;
  } catch (error) {
    return error;
  }
};
