import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import image1 from "../../../assets/images/event_01.jpg";
import BuyCourseComponent from "./BuyCourseComponent";
import "./courseDetails.css";
import { getStudentCourseDetails } from "../../../actions/studentCourseAction";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { scrollToTop } from "../../../Helpers/ScrollToTop";
import { StudnetCourseLoader } from "../../../components/ContentLoader/StudentCourseLoader";
import TableLoader from "../../../components/ContentLoader/TableLoader";
import labels from "../../../labels.json";
import { isEmpty } from '_utils'

const CourseDetailsComponent = (props) => {
    const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

    const { all_courseList, getStudentCourseDetails } = props;
    const { pathname } = useLocation();
    const { _id } = useParams();
    const [courseData, setCourseData] = useState([]);
    const [loadingResponse, setLoadingResponse] = useState(false);

    useEffect(() => {
        scrollToTop();
        getStudentCourse()
        // console.log(loadingResponse,courseDetails,Loading)
    }, []);

    const getStudentCourse = async() => {
        setLoadingResponse(true);
        const res = await getStudentCourseDetails(_id);
        const response = res.data?.result;
        if(!isEmpty(response)) {
            setCourseData(response?.courseData);
        }
        setLoadingResponse(false);
    }

    const { courseDetails, Loading } = all_courseList;

    return (
        <Fragment>
            <section className="stdnt_home_main  sec_mar_top">
                <div className="container">
                    {
                        !loadingResponse && courseDetails !== {} && !Loading ? (
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="stdnt_home_main_box px-3 px-md-4">
                                            <div className="row mb-5 ">
                                                <div className="col-md-12">
                                                    <div className="feed_common_user_profile_txt mt-0">
                                                        <p className="gnrl_para m-0 font-18 pl-8">
                                                            {pathname === "/coach/course/details"
                                                                ? "AIFF E Certificate Course"
                                                                : courseDetails.title}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-3">
                                                    <div className="course-details ">
                                                        <img
                                                            src={
                                                                courseDetails.image !== ""
                                                                    ? courseDetails.image
                                                                    : image1
                                                            }
                                                            alt="course"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-4 ">
                                                    <h3 className="bld_secnd_title pl-8">
                                                        {lbl ? lbl.aboutTheCourse : "About the course"}
                                                    </h3>
                                                </div>
                                                <div className="col-md-12 mt-2">
                                                    <p className="color_gray gnrl_para mt-0 pl-8 pr-8" style={{ whiteSpace: 'pre-wrap' }}>
                                                        {courseDetails.description}
                                                    </p>
                                                </div>
                                                <div className="col-md-12 mt-4">
                                                    <h3 className="bld_secnd_title pl-8">
                                                        {lbl ? lbl.courseSchedule : "Course Schedule"}
                                                    </h3>
                                                </div>
                                                <div className="col-md-12 mt-4">
                                                    <table
                                                        className={
                                                            courseData?.length < 5
                                                                ? "table tg-table-width"
                                                                : "table header-fixed"
                                                        }
                                                    >
                                                        <thead className="tg-head tg-colorhead">
                                                            <tr className="tg-head">
                                                                <th className="tg-th-width">
                                                                    {courseDetails.type === 2 ? (
                                                                        <span>{lbl ? lbl.day : "Day"}</span>
                                                                    ) : (
                                                                        <span>{lbl ? lbl.date : "Date"}</span>
                                                                    )}
                                                                </th>
                                                                <th className="tg-th-modal">
                                                                    {lbl ? lbl.time : "Time"}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="tg-body">
                                                            {courseData?.length > 0 ? (
                                                                courseData.map((course_data, i) => {
                                                                    return (
                                                                        <tr className="tg-head" key={i}>
                                                                            {courseDetails.type === 2 ? (
                                                                                <td>{course_data.day}</td>
                                                                            ) : (
                                                                                <td>
                                                                                    {course_data.date !== null || ""
                                                                                        ? moment(course_data.date).format(
                                                                                            "Do MMMM YYYY"
                                                                                        )
                                                                                        : "10 June 2010"}
                                                                                </td>
                                                                            )}
                                                                            <td>
                                                                                {moment(course_data.fromTime).format(
                                                                                    "LT"
                                                                                )}
                                                                                &nbsp; - &nbsp;
                                                                                {moment(course_data.toTime).format(
                                                                                    "LT"
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : courseData?.length === 0 ? (
                                                                <tr>
                                                                    <td colSpan={2}>
                                                                        {lbl
                                                                            ? lbl.noDayTimeFound
                                                                            : "No Day & Time found"}
                                                                    </td>
                                                                </tr>
                                                            ) : (
                                                                <tr>
                                                                    <TableLoader column={2} btnColumn={2} />
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <BuyCourseComponent
                                                    coachPackage={courseDetails.packageData}
                                                    boughtCourse={courseDetails.paymentPackageData}
                                                    message={courseDetails.packageMessage}
                                                    course_id={_id}
                                                    getStudentCourse={getStudentCourse}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : Loading || loadingResponse ? (
                        <div className="col-xl-12 col-lg-12 col-md-12 mt-5">
                            <StudnetCourseLoader />
                        </div>
                    ) : courseDetails === {} && !Loading ? (
                        <div className="col-xl-12 col-lg-12 col-md-12 mt-5">
                            <div className="col tg-display tg-content">
                                {lbl ? lbl.noCourseListFound : "No Course List found"}
                            </div>
                        </div>
                    ) : null}
                </div>
            </section>
            {/* <DefaultFooter /> */}
        </Fragment>
    );
};

CourseDetailsComponent.propTypes = {
    getStudentCourseDetails: PropTypes.func.isRequired,
    all_courseList: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    all_courseList: state.all_courseList,
});

export default connect(mapStateToProps, { getStudentCourseDetails })(
    CourseDetailsComponent
);
