import React, { Fragment, useEffect, useState } from "react";
import TableLoader from "../../../components/ContentLoader/TableLoader";
import { getLeaveHistory } from "../../../actions/leaveBalanceAction";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import labels from "../../../labels.json";

const LeaveHistoryTable = (props) => {
  const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

  const { getLeaveHistory, leaves } = props;
  const { leaveHistory, leaveHistoryLoading } = leaves;

  const [limit, setLimit] = useState(10);

  useEffect(() => {
    getLeaveHistory({ page: 1, limit: 10 });
  }, []);

  // -------- Handle limit change --------
  const onHandleLimitChange = (e) => {
    const { value } = e.target;

    setLimit(value);
    getLeaveHistory({ page: 1, limit: value });
  };

  // ------------ page number click ------------
  const onPageClick = (page) => {
    getLeaveHistory({ page: page, limit: limit });
  };

  // ------------ Pagination Section ------------
  const renderPaginationSection = (data) => {
    const { page, totalPages, hasPrevPage, hasNextPage, prevPage, nextPage } =
      data;

    let Pages = [];
    let skipped = 0;

    for (var i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        (page < 4 && i <= 5) ||
        i === page - 1 ||
        i === page + 1 ||
        i === page ||
        i === totalPages ||
        (page >= totalPages - 3 && i >= totalPages - 4)
      ) {
        const test = i;
        const item = (
          <React.Fragment key={i}>
            {skipped === 1 ? (
              <PaginationItem>
                <PaginationLink disabled tag="button">
                  ...
                </PaginationLink>
              </PaginationItem>
            ) : null}
            <PaginationItem
              active={page === i ? true : false}
              onClick={page === i ? () => null : () => onPageClick(test)}
              key={i}
            >
              <PaginationLink tag="button">{i}</PaginationLink>
            </PaginationItem>
          </React.Fragment>
        );
        skipped = 0;
        Pages.push(item);
      } else {
        skipped = 1;
      }
    }

    return (
      <nav className="pagination-nav">
        <Pagination>
          {hasPrevPage && (
            <PaginationItem
              onClick={
                hasPrevPage === true ? () => onPageClick(prevPage) : null
              }
            >
              <PaginationLink
                previous
                disabled={hasPrevPage === true ? false : true}
                tag="button"
              >
                <i className="fas fa-chevron-left" />
              </PaginationLink>
            </PaginationItem>
          )}
          {Pages}
          {hasNextPage && (
            <PaginationItem
              onClick={
                hasNextPage === true ? () => onPageClick(nextPage) : null
              }
            >
              <PaginationLink
                next
                tag="button"
                disabled={hasNextPage === true ? false : true}
              >
                <i className="fas fa-chevron-right" />
              </PaginationLink>
            </PaginationItem>
          )}
        </Pagination>
      </nav>
    );
  };

  return (
    <Fragment>
      <div className="my_leaves_components  mt-1">
        <h3 className="bld_secnd_title w-100 ">
          {lbl ? lbl.leaveHistory : "Leave History"}
        </h3>
      </div>

      <div className="leave-table-container">
        <table className="leave-table ">
          <thead>
            <tr>
              <th>{lbl ? lbl.dateOfApplication : "Date of Application"}</th>
              <th>{lbl ? lbl.leaveAppliedDate : "Leave Date"}</th>
              <th className="border-right-white border-left-white">
                {lbl ? lbl.courses : "Courses"}
              </th>
              <th>{lbl ? lbl.leaveState : "State"}</th>
              <th className="border-right-white border-left-white">
                {lbl ? lbl.comment : "Comment"}
              </th>
              {/* <th>{lbl ? lbl.balance : "Balance"}</th> */}
            </tr>
          </thead>
          <tbody>
            {leaveHistory.docs.length > 0 && !leaveHistoryLoading ? (
              leaveHistory.docs.map((leave, i) => (
                <tr className="pt-5" key={i}>
                  <td>{leave.createdAt ? leave.createdAt : "-"}</td>
                  <td>{leave.leaveDate ? leave.leaveDate : "-"}</td>
                  <td className="border-left-blue border-right-blue">
                    {leave.title ? leave.title : "-"}
                  </td>
                  <td>{leave.leave_text ? leave.leave_text : "-"}</td>
                  <td className="border-left-blue border-right-blue">
                    {leave.comment ? leave.comment : "-"}
                  </td>
                  {/* <td className="w-12">
                    {leave.leaveBalance}{" "}
                    {leave.leaveBalance === 1 ? (
                      <span>{lbl ? lbl.leave : "Leave"}</span>
                    ) : (
                      <span>{lbl ? lbl.leaves : "Leaves"}</span>
                    )}
                  </td> */}
                </tr>
              ))
            ) : leaveHistoryLoading ? (
              <TableLoader column={5} />
            ) : leaveHistory.docs.length === 0 && !leaveHistoryLoading ? (
              <tr>
                <td colSpan="5" className="py-5">
                  {lbl ? lbl.noLeaveHistoryFound : "No leave history found"}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>

        {leaveHistory.docs.length > 10 && (
          <div className="row">
            <div className="col-6 mt-3">
              <select
                className="form-select form-select-sm select-limit"
                onChange={(e) => onHandleLimitChange(e)}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={50}>50</option>
              </select>
            </div>
            <div className="col-6 mt-3">
              <div className="text-left">
                {renderPaginationSection(leaveHistory)}
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

LeaveHistoryTable.propTypes = {
  getLeaveHistory: PropTypes.func.isRequired,
  leaves: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  leaves: state.leave,
});
export default connect(mapStateToProps, {
  getLeaveHistory,
})(LeaveHistoryTable);
