import React from "react";
import "./loader.css";

const StudentGiftLoader = (props) => (
  <div className="ph-item ">
    <div className="ph-col-12 gift-item-box">
      <div className="ph-row ">
        <div className="ph-col-3 ph-icon color"></div>
        <div className="ph-col-1  empty big tg-Adsde"></div>
        <div className="ph-col-4 big color my-2 mt-4"></div>
        <div className="ph-col-2  empty big tg-Adsde"></div>
        <div className="ph-col-2  color description mb-3 mt-4">
          <div className="ph-col-2  color description mb-3 mt-4"></div>
        </div>
      </div>
    </div>
    <div className="ph-col-12 gift-item-box">
      <div className="ph-row ">
        <div className="ph-col-3 ph-icon color"></div>
        <div className="ph-col-1  empty big tg-Adsde"></div>
        <div className="ph-col-4 big color my-2 mt-4"></div>
        <div className="ph-col-2  empty big tg-Adsde"></div>
        <div className="ph-col-2  color description mb-3 mt-4">
          <div className="ph-col-2  color description mb-3 mt-4"></div>
        </div>
      </div>
    </div>
    <div className="ph-col-12 gift-item-box">
      <div className="ph-row ">
        <div className="ph-col-3 ph-icon color"></div>
        <div className="ph-col-1  empty big tg-Adsde"></div>
        <div className="ph-col-4 big color my-2 mt-4"></div>
        <div className="ph-col-2  empty big tg-Adsde"></div>
        <div className="ph-col-2  color description mb-3 mt-4">
          <div className="ph-col-2  color description mb-3 mt-4"></div>
        </div>
      </div>
    </div>
    <div className="ph-col-12 gift-item-box">
      <div className="ph-row ">
        <div className="ph-col-3 ph-icon color"></div>
        <div className="ph-col-1  empty big tg-Adsde"></div>
        <div className="ph-col-4 big color my-2 mt-4"></div>
        <div className="ph-col-2  empty big tg-Adsde"></div>
        <div className="ph-col-2  color description mb-3 mt-4">
          <div className="ph-col-2  color description mb-3 mt-4"></div>
        </div>
      </div>
    </div>
  </div>
);

export default StudentGiftLoader;
