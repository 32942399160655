import firebase from "firebase/app";
import "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyDhW4ol8oC367AmWZtdRT1YY204rJeWaDA",
  authDomain: "inter-academy-4b84b.firebaseapp.com",
  projectId: "inter-academy-4b84b",
  storageBucket: "inter-academy-4b84b.appspot.com",
  messagingSenderId: "659779974273",
  appId: "1:659779974273:web:80c402e4973177f32cc980",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();

export default firebase;
