import React, { Fragment, useEffect, useState } from "react";
import contactUsPhoto from "../../assets/images/contact_us_illustration.png";
import call from "../../assets/images/contact_call.png";
import mail from "../../assets/images/contact_mail.png";
import location from "../../assets/images/contact_location.png";
import DefaultFooter from "../../DefaultLayout/DefaultFooter";
import { connect } from "react-redux";
import { sendContactInquiry } from "../../actions/cmsAction";
import {
  checkEmailValidation,
  checkEmptyValidation,
} from "../../Helpers/Validation";
import "ladda/dist/ladda-themeless.min.css";
import classnames from "classnames";
import { toast } from "react-toastify";
import ToastifyContainer from "../../components/Toastify/ToastContainer";
import DefaultHeader from "../../DefaultLayout/DefaultHeader";
import "./aboutus.css";
import ButtonComponent from "../../components/Button/ButtonComponent";
import labels from "../../labels.json";

const ContactUs = (props) => {
  const lbl = labels[process.env.REACT_APP_LABEL_TYPE];
  console.log("🚀 ~ file: ContactUs.js ~ line 24 ~ ContactUs ~ lbl", lbl);

  const [state, setstate] = useState({ phone: "", email: "", message: "" });
  const [errors, seterrors] = useState({
    error_phone: "",
    error_email: "",
    error_message: "",
  });

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    setstate({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const onSendInquiryClick = (e) => {
    let { phone, email, message } = state;
    let validationFlag = true;
    let errPhone = null;
    let errEmail = null;
    let errMessage = null;
    let phoneLbl = "Phone number ";
    let messageLbl = "Message ";

    if (lbl && lbl.phoneNumber) phoneLbl = lbl.phoneNumber;
    if (lbl && lbl.message) messageLbl = lbl.message;
    errPhone = checkEmptyValidation(phone, phoneLbl);
    errEmail = checkEmailValidation(email, 1, 50);
    errMessage = checkEmptyValidation(message, messageLbl);

    if (
      errPhone === "Phone number is required" ||
      errPhone === "電話號碼：需要"
    ) {
      errPhone = lbl.enterCorrectPhone;
    }
    if (
      errEmail === "Please enter a valid email address" ||
      errEmail === "請輸入正確電郵地址"
    ) {
      errEmail = lbl.enterCorrectEmail;
    }
    console.log(
      "🚀 ~ file: ContactUs.js ~ line 65 ~ onSendInquiryClick ~ errPhone",
      errMessage
    );
    if (errMessage === "Message is required" || errMessage === "訊息需要") {
      errMessage = lbl.enterCorrectMessage;
    }
    if (errPhone || errMessage || errMessage) {
      validationFlag = false;
      seterrors({
        error_phone: errPhone,
        error_email: errEmail,
        error_message: errMessage,
      });
    } else {
      setLoading(true);
      validationFlag = true;
      seterrors({
        error_phone: "",
        error_email: "",
        error_message: "",
      });
    }
    if (validationFlag === true) {
      const inquiryObj = {
        phone_number: phone,
        email,
        message,
      };
      props
        .sendContactInquiry(inquiryObj)
        .then((result) => {
          setLoading(false);
          toast.success(result.data.message);

          setstate({
            phone: "",
            email: "",
            message: "",
          });
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response.data.message);
        });
      seterrors({
        error_phone: "",
        error_email: "",
        error_message: "",
      });
    }
  };

  return (
    <Fragment>
      <ToastifyContainer />
      <DefaultHeader />

      <section className="contact_us_main_sec ">
        <div className="container">
          <div className="row pt-5">
            <div className="col-lg-6 col-md-6 text-center">
              <img src={contactUsPhoto} alt="" />
            </div>
            <div className="col-lg-6 col-md-6 mt-3">
              <h2 className="main_title">
                {lbl ? lbl.contactUs : "Contact Us"}
              </h2>
              <p className="title_para">
                {lbl
                  ? lbl.contactUsDesc
                  : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="contact_us_scnd_sec mt-5">
        <div className="container">
          <div className="row">
            <div className="col-3" />

            <div className="col-lg-6 col-md-6 mt-3">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="main_title">
                    {lbl ? lbl.letsTalk : "Let’s talk"}
                  </h2>
                  <p className="title_para">
                    {lbl
                      ? lbl.letsTalkDesc
                      : "Please fill in the form below and we will contact you as soon as possible."}
                  </p>
                </div>
                <div className="col-md-12 mt-4">
                  <h3 className="secnd_title" />
                </div>
                <div className="col-md-12 mt-4">
                  <input
                    type="number"
                    name="phone"
                    placeholder={lbl ? lbl.oPhone : "Phone number"}
                    onChange={(e) => handleInputChange(e)}
                    value={state.phone}
                    className={classnames(
                      { invalid: errors.error_phone.length > 0 },
                      { valid: errors.error_phone.length === 0 }
                    )}
                  />
                  {errors.error_phone ? (
                    <span className="invalid-text">{errors.error_phone}</span>
                  ) : null}
                </div>
                <div className="col-md-12 mt-4">
                  <input
                    type="email"
                    name="email"
                    placeholder={lbl ? lbl.oEmail : "Email"}
                    onChange={(e) => handleInputChange(e)}
                    value={state.email}
                    className={classnames(
                      { invalid: errors.error_email.length > 0 },
                      { valid: errors.error_email.length === 0 }
                    )}
                  />
                  {errors.error_email ? (
                    <span className="invalid-text">{errors.error_email}</span>
                  ) : null}
                </div>
                <div className="col-md-12 mt-4">
                  <textarea
                    name="message"
                    onChange={(e) => handleInputChange(e)}
                    placeholder={lbl ? lbl.message : "Message"}
                    value={state.message}
                    className={classnames(
                      { invalid: errors.error_message.length > 0 },
                      { valid: errors.error_message.length === 0 }
                    )}
                  />
                  {errors.error_message ? (
                    <span className="invalid-text">{errors.error_message}</span>
                  ) : null}
                </div>
                <div className="col-md-12 mt-4">
                  <ButtonComponent
                    loading={loading}
                    title={lbl ? lbl.submit : "Submit"}
                    onBtnClick={(e) => onSendInquiryClick(e)}
                  />
                </div>
              </div>
            </div>
            <div className="col-3" />
          </div>
        </div>
      </section>
      <section className="contact_us_third_sec mt-5 sec_mar_bottom pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 text-center mt-5">
              <img src={location} alt="" />
              <h3 className="bld_secnd_title mt-3 w-100">
                {lbl ? lbl.location : "Location"}
              </h3>
              <p className="title_para mt-1">
                {lbl
                  ? lbl.contactLocation
                  : " Smalriemseweg 21, 4112 NA Beusichem, Netherlands"}
              </p>
            </div>
            <div className="col-lg-4 col-md-4 text-center mt-5">
              <img src={mail} alt="" />
              <h3 className="bld_secnd_title mt-3 w-100">
                {lbl ? lbl.oEmail : "Email"}
              </h3>
              <p className="title_para mt-1">
                {lbl ? lbl.contactEmail : "loremipsum@gmail.com"}
              </p>
            </div>
            <div className="col-lg-4 col-md-4 text-center mt-5">
              <img src={call} alt="" />
              <h3 className="bld_secnd_title mt-3 w-100">
                {lbl ? lbl.phn : "Phone"}
              </h3>
              <p className="title_para mt-1">
                {lbl ? lbl.contactPhone : "+1 9876543210"}
              </p>
            </div>
          </div>
        </div>
      </section>
      <DefaultFooter />
    </Fragment>
  );
};

export default connect(null, { sendContactInquiry })(ContactUs);
