import axios from "axios";
import {
  all_course_list_coach,
  class_scheduled,
  class_scheduled_checkin,
  config,
  course_student_req_list,
  course_student_attendance,
} from "../api/index";
import {
  ALL_COURSE_LIST,
  ALL_COURSE_DETAILS,
  CLASS_SCHEDULED,
  CLASS_TIME_SCHEDULE,
  COURSE_STUDENT_REQ_LIST,
} from "./types";

/**
 * Api method : Get API
 * Function called for getting the course list.
 */
export const getCoachCourseList = (pagination) => async (dispatch) => {
  try {
    const res = await axios.get(all_course_list_coach, { params: pagination });
    dispatch({
      type: ALL_COURSE_LIST,
      payload: res.data.result,
    });
    return res;
  } catch (error) {
    return error;
  }
};

/**
 * Api method : Get API
 * Function called for getting the course details.
 */
export const getCoachCourseDetails = (_id) => async (dispatch) => {
  try {
    const res = await axios.patch(all_course_list_coach + `/${_id}`);
    dispatch({
      type: ALL_COURSE_DETAILS,
      payload: res.data.result,
    });
    return res;
  } catch (error) {
    return error;
  }
};

/**
 * Api method : Get API
 * Function called for getting the course class schedule.
 */
export const getCourseClassSchedule =
  (type, id, fromDate, toDate) => async (dispatch) => {
    try {
      if (type === 1) {
        const res = await axios.get(
          class_scheduled +
            "?type=" +
            type +
            "&course=" +
            id +
            "&fromDate=" +
            fromDate +
            "&toDate=" +
            toDate
        );
        dispatch({
          type: CLASS_SCHEDULED,
          payload: res.data.result,
        });
        return res;
      } else {
        const res = await axios.get(
          class_scheduled +
            "?type=" +
            type +
            "&course=" +
            id +
            "&fromDate=" +
            fromDate +
            "&toDate=" +
            toDate
        );
        dispatch({
          type: CLASS_TIME_SCHEDULE,
          payload: res.data.result,
        });
        return res;
      }
    } catch (error) {
      return error;
    }
  };

//update coach class schedule check in details
export const createCheckinAttendanceCoachClass = (data) => async (dispatch) => {
  const body = JSON.stringify(data);
  const res = await axios.post(class_scheduled_checkin, body, config);
  try {
    return res;
  } catch (error) {
    return error;
  }
};

/**
 * Api method : Get API
 * Function called for getting the course student req list for attendance.
 */
export const getCourse_StudentList = (_id, date) => async (dispatch) => {
  try {
    const res = await axios.get(
      course_student_req_list + "?course=" + _id + "&date=" + date
    );
    dispatch({
      type: COURSE_STUDENT_REQ_LIST,
      payload: res.data.result,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const studentCourseAttendance = (data) => async (dispatch) => {
  const body = JSON.stringify(data);
  const res = await axios.post(course_student_attendance, body, config);
  try {
    return res;
  } catch (error) {
    return error;
  }
};
