import React, { Fragment } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Footer from "./DefaultFooter";
import routes from "../routes/routes";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const DefaultLayout = (props) => {
  const { auth } = props;
  const { isAuthenticated, user } = auth;
  return (
    <Fragment>
      <Switch>
        {routes.map((route, idx) => {
          return route.component ? (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              routeType={route.routeType}
              render={(path) =>
                isAuthenticated === true && user.role === route.routeType ? (
                  <route.component {...path} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
          ) : null;
        })}
        <Redirect to="/" />
      </Switch>
      <Footer />
    </Fragment>
  );
};

DefaultLayout.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(DefaultLayout);
