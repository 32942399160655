import React, { Fragment, useEffect } from "react";
import { loadUser } from "../../../actions/authActions";
import DefaultHeader from "../../../DefaultLayout/DefaultHeader";
import CourseList from "../../CommonComponents/HomePage/CourseList";
import PropTypes from "prop-types";
import "./homePage.css";
import { connect } from "react-redux";
import { scrollToTop } from "../../../Helpers/ScrollToTop";
const HomePage = (routerProps) => {
  const { loadUser } = routerProps;

  useEffect(() => {
    scrollToTop();
    loadUser();
  }, []);
  return (
    <Fragment>
      <DefaultHeader />
      <section className="stdnt_home_main ">
        <div className="container">
          <div className="row">
            <CourseList />
          </div>
        </div>
      </section>
    </Fragment>
  );
};

HomePage.propTypes = {
  loadUser: PropTypes.func.isRequired,
};

export default connect(null, { loadUser })(HomePage);
