import React from "react";
import "./loader.css";

export const EnrollmentLoader = (props) => (
  <div className="ph-item">
    <div className="ph-col-12">
      <div className="ph-row ">
        <div className="ph-col-4 big color my-2 mt-3"></div>
      </div>
      <div className="ph-picture color"></div>
      <div className="ph-row">
        <div className="ph-col-4  color about "></div>
        <div className="ph-col-8  empty big"></div>
        <div className="ph-col-12 color description  "></div>
        <div className="ph-col-12 color description  "></div>
        <div className="ph-col-8  color description mb-3"></div>
        <div className="ph-col-4  empty"></div>
        <div className="ph-col-4  color about mt-4 mb-2"></div>
        <div className="ph-col-8  empty  dscription mt-4 "></div>
        <div className="ph-col-2  color description mb-2"></div>
        <div className="ph-col-8  empty "></div>
        <div className="ph-col-6  color about mt-4 mb-2"></div>
        <div className="ph-col-2  empty mt-4 mb-2 "></div>
        <div className="ph-col-2  empty mt-4 mb-2 "></div>
        <div className="ph-col-2  color details-btn mt-4 mb-2 "></div>
      </div>
    </div>
  </div>
);
