import React, { Fragment } from "react";
import { ColumnLoader, ButtonLoader } from "./Loader";

export default function TableLoader({ column, btnColumn }) {
  const renderColumns = () => {
    const data = Array.from(Array(column).keys(""));

    return data.map((c, i) => (
      <td key={i}>
        {i === btnColumn - 1 ? <ButtonLoader /> : <ColumnLoader />}
      </td>
    ));
  };
  return <Fragment>{renderColumns()}</Fragment>;
}
