import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER_FAIL,
  USER_LOADING_SUCCESS,
  USER_LOGOUT,
  USER_LOADING_ERROR,
} from '../actions/types'

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  loading: true,
  user: {},
  verify: null,
  email: null,
}

const authReducer = (state = initialState, action = {}) => {
  const { type, payload } = action

  switch (type) {
    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.token)
      return {
        ...state,
        token: payload.token,
        isAuthenticated: true,
        loading: false,
        user: payload.user,
      }
    case USER_LOADING_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload.user,
      }
    case REGISTER_FAIL:
    case LOGIN_FAIL:
    case USER_LOADING_ERROR:
    case USER_LOGOUT:
      localStorage.removeItem('token')
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: {},
      }

    default:
      return state
  }
}

export default authReducer
