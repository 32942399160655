import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Link, Redirect, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { userLogin } from "../../../actions/authActions";
import ButtonComponent from "../../../components/Button/ButtonComponent";
import InputField from "../../../components/InputField/InputField";
import ToastifyContainer from "../../../components/Toastify/ToastContainer";
import {
  checkEmailValidation,
  checkRequiredValidationWithMinMax,
} from "../../../Helpers/Validation";
import labels from "../../../labels.json";

const LoginInput = (routerProps) => {
  const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

  const { userLogin, auth, userRole } = routerProps;

  const { pathname } = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error_Email, set_Error_Email] = useState("");
  const [error_Password, set_Error_Password] = useState("");
  const [loader, setLoader] = useState(false);

  // ---------- Login button click -----------
  const onLoginClick = (e) => {
    let errEmail = null;
    let errPassword = null;
    let validationFlag = true;

    let emailLbl = "Email ";
    let passwordLbl = "Password ";

    if (lbl && lbl.oEmail) emailLbl = lbl.oEmail;
    if (lbl && lbl.password) passwordLbl = lbl.password;

    errEmail = checkEmailValidation(email, emailLbl);
    errPassword = checkRequiredValidationWithMinMax(
      password,
      passwordLbl,
      6,
      12
    );

    if (errEmail || errPassword) {
      validationFlag = false;
      set_Error_Email(errEmail);
      set_Error_Password(errPassword);
    } else {
      validationFlag = true;
      set_Error_Email("");
      set_Error_Password("");
    }

    if (validationFlag) {
      setLoader(true);
      const userObj = {
        email,
        password,
        role: userRole,
      };
      userLogin(userObj)
        .then((res) => {
          toast.success(res.data.message);
          setLoader(false);
          setEmail("");
          setPassword("");
        })
        .catch((error) => {
          setLoader(false);
          toast.error(error.response.data.message);
        });
    }
  };

  // ---------- Login button click on enter -----------
  const loginClickOnEnter = (e) => {
    if (e.key === "Enter") {
      onLoginClick();
    }
  };

  const { isAuthenticated, loading, user } = auth;

  if (isAuthenticated && !loading && user.role === 1) {
    return <Redirect to="/student/course" />;
  }
  if (isAuthenticated && !loading && user.role === 2) {
    return <Redirect to="/coach/course-list" />;
  }

  return (
    <Fragment>
      <div className="col-lg-5 col-md-6 mt-3">
        <div className="row">
          <ToastifyContainer />
          <div className="col-md-12">
            <h2 className="main_title">
              {pathname === "/coach/login" ? (
                <span>{lbl ? lbl.loginAsCoach : "Login as coach"}</span>
              ) : (
                <span>{lbl ? lbl.loginAsStudent : "Login as student"}</span>
              )}
            </h2>
            <p className="title_para">
              {lbl
                ? lbl.loginDesc
                : " It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."}
            </p>
          </div>
          <div className="col-md-12 mt-4">
            <h3 className="secnd_title title_without_border">
              {lbl ? lbl.login : "Login"}
            </h3>
          </div>
          <div className="w-100">
            <span className="blue_border"></span>
            <span className="gray_border"></span>
          </div>
          <div className="col-md-12 mt-4">
            <InputField
              inputType="email"
              placeHolder={lbl ? lbl.enterEmail : "Enter your email"}
              errorValue={error_Email}
              disable={loader}
              onInputChange={(e) => setEmail(e.target.value)}
              value={email}
              keyDown={(e) => loginClickOnEnter(e)}
            />
          </div>
          <div className="col-md-12 mt-4">
            <InputField
              inputType="password"
              placeHolder={lbl ? lbl.enterPassword : "Enter your password"}
              errorValue={error_Password}
              disable={loader}
              onInputChange={(e) => setPassword(e.target.value)}
              value={password}
              keyDown={(e) => loginClickOnEnter(e)}
            />
          </div>
          <div className="col-md-12 mt-3 text-right">
            <Link to="/forgot-password" style={{ float: "right" }}>
              {lbl ? lbl.forgotPassword + "?" : "Forgot password?"}
            </Link>
          </div>
          <div className="col-md-12 mt-4">
            <ButtonComponent
              loading={loader}
              title={lbl ? lbl.login : "Login"}
              onBtnClick={(e) => onLoginClick(e)}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

LoginInput.propTypes = {
  userLogin: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { userLogin })(LoginInput);
