import React from "react";
import "./ProfileHeader.css";
import noProfile from "../../../assets/images/no-profile.png";
import updateIcon from "../../../assets/images/update.svg";
import { useLocation, Redirect, Link } from "react-router-dom";
import { logout } from "../../../actions/authActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import labels from "../../../labels.json";

function ProfileHeader(routerProps) {
  const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

  const { logout, auth } = routerProps;

  const { pathname } = useLocation();
  const initialPath = pathname.split("/")[1];

  const { user } = auth;

  const onLogoutClick = (e) => {
    if (initialPath === "student") {
      logout();
      <Redirect to="/student/login" />;
    } else {
      logout();
      <Redirect to="/coach/login" />;
    }
  };
  return (
    <section className="student_profile_main sec_mar_top">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="student_profile_top_part">
              <span className="student_profile_picture">
                <img
                  src={
                    auth.user && auth.user.profile_pic
                      ? auth.user.profile_pic
                      : noProfile
                  }
                  alt="coach"
                />
              </span>
              <span className="student_profile_name">
                <p className="gnrl_para Weight m-0">
                  {auth.user && user.role === 1 && user.first_name !== null
                    ? user.first_name + " " + user.last_name
                    : auth.user && user.role === 2 && user.name !== null
                    ? user.name
                    : "Not Added"}
                </p>
                <p className="gnrl_para m-0">
                  {auth.user && user.email !== "" ? user.email : "Not Added"}
                </p>
              </span>
              <span className="student_profile_btn">
                <Link
                  className="update_profile"
                  to={
                    initialPath === "student"
                      ? "/student/update-profile"
                      : "/coach/update-profile"
                  }
                >
                  <span className="edit-icon">
                    <img src={updateIcon} alt="" />
                    {/* <i className="fa fa-edit"></i> */}
                  </span>
                  {lbl ? lbl.updateProfileDetails : "Update profile details"}
                </Link>
                <Link
                  // to={
                  //   initialPath === "student"
                  //     ? "/student/login"
                  //     : "/coach/login"
                  // }
                  to="#"
                  role="button"
                  className="brder_btn btn_logout text-center w-50 mt-1"
                  onClick={(e) => onLogoutClick()}
                >
                  {lbl ? lbl.logout : "Logout"}
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

ProfileHeader.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(ProfileHeader);
