import axios from "axios";
import { Assign_Gift, config, Get_All_Courses, Get_Coach_Gifts, Get_NotSend_Gifts } from "../api";
import { GET_ALL_COURSES, GET_COACH_GIFTS } from "./types";

// Get Gifts of student
export const getCoachGifts = (pagination) => async (dispatch) => {
  try {
    const res = await axios.get(Get_Coach_Gifts, {
      params: pagination,
    });
    dispatch({
      type: GET_COACH_GIFTS,
      payload: res.data.result,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const giftsNotSend = (giftObj) => async (dispatch) => {
  try {
  const body = JSON.stringify(giftObj);
    return await axios.post(Get_NotSend_Gifts, body, config);
  } catch (error) {
    return error;
  }
};

// Get All course list without pagination
export const getAllCourseList = () => async (dispatch) => {
  try {
    const res = await axios.get(Get_All_Courses);
    dispatch({
      type: GET_ALL_COURSES,
      payload: res.data.result,
    });
    return res;
  } catch (error) {
    return error;
  }
};

// Get All course list without pagination
export const assignGift = (giftObj) => async (dispatch) => {
  const body = JSON.stringify(giftObj);
  const res = await axios.post(Assign_Gift, body, config);
  try {
    return res;
  } catch (error) {
    return error;
  }
};
