import React, { useState } from "react";
import { Fragment } from "react";
import { push as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import "../../DefaultLayout/defaultLayout.css";
import labels from "../../labels.json";

export default function Index(props) {
  const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

  const [openMenu, setOpenMenu] = useState(false);
  const toggle = (e) => {
    setOpenMenu(!openMenu);
  };

  return (
    <Fragment>
      <span
        className="mobile_menu_btn"
        name="myNav"
        style={{ fontSize: "30px", cursor: "pointer", zIndex: "9999" }}
        // onClick={() => {
        //   !openMenu && setOpenMenu(true);
        // }}
        onClick={(e) => toggle(e)}
      >
        ☰
      </span>
      {openMenu && (
        <Menu style={{ background: "red" }} isOpen={openMenu}>
          <Link
            id="home"
            style={{
              display: "block",
              float: "right",
              width: "100%",
              height: "46px",
              textAlign: "right",
              color: "black",
              fontSize: "30px",
              paddingRight: "17px",
            }}
            to="/"
            // onClick={() => {
            //   openMenu && setOpenMenu(false);
            // }}
          >
            <i className="fas fa-times" onClick={(e) => toggle(e)} />
          </Link>
          <Link id="home" className="menu-item" to="/">
            {lbl ? lbl.home : "Home"}
          </Link>
          <Link className="menu-item" to="/about-us">
            {lbl ? lbl.aboutUs : "About Us"}
          </Link>
          <Link className="menu-item" to="/faq">
            {lbl ? lbl.faq : "FAQ"}
          </Link>
          <Link className="menu-item" to="/privacy-policy">
            {lbl ? lbl.privacyPolicy : " Privacy policy"}
          </Link>
          <Link className="menu-item" to="/contact-us">
            {lbl ? lbl.contactUs : "Contact us"}
          </Link>
        </Menu>
      )}
    </Fragment>
  );
}
