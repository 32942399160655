import StudentEnrollments from "../views/student/MyEnrollments/MyEnrollments";
import StudentHomePage from "../views/student/Homepage/HomePage";
import StudentCourseDetails from "../views/student/Course/CourseDetails";
import CoachProfile from "../views/coach/CoachProfile/CoachProfile";
import CoachGifts from "../views/coach/CoachGifts/CoachGifts";
import CoachDetails from "../views/coach/CoachDetails/CoachDetails";
import UpdateprofileDetails from "../views/coach/UpdateProfileDetails/UpdateprofileDetails";
import Notifications from "../views/CommonComponents/Notification/Notification";
import StudentGifts from "../views/student/MyGifts/MyGifts";
import StudentLeaves from "../views/student/MyLeaves/MyLeaves";
import StudentPaymentHistory from "../views/student/MyPaymentHistory/MyPaymentHistory";

const routes = [
  {
    path: "/student/course",
    exact: true,
    routeType: 1,
    component: StudentHomePage,
  },
  {
    path: "/student/course/details/:_id",
    exact: true,
    routeType: 1,
    component: StudentCourseDetails,
  },

  /******** coach ******/
  {
    path: "/student/my-enrollments",
    exact: true,
    routeType: 1,
    component: StudentEnrollments,
  },
  {
    path: "/student/my-gifts",
    exact: true,
    routeType: 1,
    component: StudentGifts,
  },
  {
    path: "/student/my-leaves",
    exact: true,
    routeType: 1,
    component: StudentLeaves,
  },
  {
    path: "/student/payment-history",
    exact: true,
    routeType: 1,
    component: StudentPaymentHistory,
  },
  {
    path: "/student/update-profile",
    exact: true,
    routeType: 1,
    component: UpdateprofileDetails,
  },
  {
    path: "/student/notification",
    exact: true,
    routeType: 1,
    component: Notifications,
  },

  {
    path: "/coach/course-list",
    exact: true,
    routeType: 2,
    component: StudentHomePage,
  },
  {
    path: "/coach/course-details/:_id", // path for landing page when login from coach. no profile header
    exact: true,
    routeType: 2,
    component: CoachDetails,
  },
  {
    path: "/coach/gifts",
    exact: true,
    routeType: 2,
    component: CoachGifts,
  },
  {
    path: "/coach/my-courses",
    exact: true,
    routeType: 2,
    component: CoachProfile,
  },
  {
    path: "/coach/my-courses/details/:_id", // path for coach course.
    exact: true,
    routeType: 2,
    component: CoachDetails,
  },
  {
    path: "/coach/details",
    exact: true,
    routeType: 2,
    component: CoachDetails,
  },
  {
    path: "/coach/details/regular", // with profile header
    exact: true,
    routeType: 2,
    component: CoachDetails,
  },
  {
    path: "/coach/update-profile",
    exact: true,
    routeType: 2,
    component: UpdateprofileDetails,
  },
  {
    path: "/coach/notification",
    exact: true,
    routeType: 2,
    component: Notifications,
  },
];
export default routes;
