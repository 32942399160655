import axios from "axios";
import {
  Add_Payment_Comment,
  config,
  Get_My_Enrollments,
  Get_Student_Attendence,
} from "../api";
import { GET_MY_ENROLLMENTS } from "./types";

/**
 * Api method : Get API
 * Function called for getting the course list.
 */
export const getMyEnrollments = (pagination) => async (dispatch) => {
  const res = await axios.get(Get_My_Enrollments, {
    params: pagination,
  });
  try {
    dispatch({
      type: GET_MY_ENROLLMENTS,
      payload: res.data.result,
    });

    return res;
  } catch (error) {
    return error;
  }
};

export const getMyAttendence = (pagination) => async (dispatch) => {
  const res = await axios.get(Get_Student_Attendence, {
    params: pagination,
  });
  try {
    return res;
  } catch (error) {
    return error;
  }
};

export const addPaymentComment = (paymebtObj) => async (dispatch) => {
  const body = JSON.stringify(paymebtObj);
  const res = await axios.post(Add_Payment_Comment, body, config);
  try {
    return res;
  } catch (error) {
    return error;
  }
};
