import React, { Fragment, useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import {
  createCheckinAttendanceCoachClass,
  getCourseClassSchedule,
} from "../../../actions/coachCourseAction";
import "./common.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import InputField from "../../../components/InputField/InputField";
import {
  checkDropdownValidation,
  checkEmptyValidation,
} from "../../../Helpers/Validation";
import { toast } from "react-toastify";
import ToastifyContainer from "../../../components/Toastify/ToastContainer";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import moment from "moment";
import labels from "../../../labels.json";
const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

const CoachModal = (props) => {
  const {
    showModal,
    closeModal,
    coursetype,
    coachtype,
    checkIndate,
    createCheckinAttendanceCoachClass,
    getCourseClassSchedule,
    checkFromDate,
    checkToDate,
    _id,
  } = props;

  const history = useHistory();

  const [showAttentionModal, setShowAttentionModal] = useState(false);
  const [checkDate, setcheckDate] = useState("");
  const [comment, setComment] = useState("");
  const [Hours, setHours] = useState(1);
  const [loader, setLoader] = useState(false);

  // --------- Error fields ---------
  const [error_checkDate, setError_checkDate] = useState("");
  const [error_Hours, setError_Hours] = useState("");

  useEffect(() => {
    setcheckDate(checkIndate);
  }, [checkIndate]);

  const onSelectHours = (e) => {
    setHours(e.target.value);
  };
  const onFieldChange = (e) => {
    setComment(e.target.value);
  };

  const SubmitAttetion = (e) => {
    setShowAttentionModal(false);
  };

  const confirmCheckinData = (e) => {
    let validationFlag = true;
    let errCheckInDate = "";
    let errHours = "";
    let dateLbl = "Date";
    let hoursLbl = "Hours";

    if (lbl && lbl.date) dateLbl = lbl.date;
    if (lbl && lbl.hours) hoursLbl = lbl.hours;

    errCheckInDate = checkEmptyValidation(checkDate, dateLbl);
    errHours = checkDropdownValidation(Hours, hoursLbl);
    if (errHours || errCheckInDate) {
      validationFlag = false;
      setError_Hours(errHours);
      setError_checkDate(errCheckInDate);
    } else {
      validationFlag = true;
      clearAllErrorFields();
    }
    if (validationFlag) {
      setShowAttentionModal(true);
    }
  };

  const onSubmitData = (e) => {
    setLoader(true);
    if (coursetype === 2) {
      const studentData = {
        date: moment(checkDate).format("YYYY-MM-DD"),
        course: _id,
        comment: comment,
      };
      createCheckinAttendanceCoachClass(studentData)
        .then((res) => {
          toast.success(res.data.message);
          getCourseClassSchedule(2, _id, checkFromDate, checkToDate);
          setLoader(false);
          setShowAttentionModal(false);
          closeModal();
          setTimeout(() => {
            history.push(`/coach/my-courses/details/${_id}`);
          }, 4000);
        })
        .catch((error) => {
          setLoader(false);
          toast.error(error.response.data.message);
        });
    } else {
      const studentData = {
        date: moment(checkDate).format("YYYY-MM-DD"),
        course: _id,
        hours: Hours,
        comment: comment,
      };
      createCheckinAttendanceCoachClass(studentData)
        .then((res) => {
          toast.success(res.data.message);
          setLoader(false);
          setComment("");
          getCourseClassSchedule(1, _id, "", "");
          setShowAttentionModal(false);
          closeModal();
          setTimeout(() => {
            history.push(`/coach/my-courses/details/${_id}`);
          }, 4000);
        })
        .catch((error) => {
          setLoader(false);
          toast.error(error.response.data.message);
        });
    }
  };

  const clearAllErrorFields = (e) => {
    setError_checkDate("");
    setError_Hours("");
  };

  const closeDataModal = (e) => {
    setComment("");
    closeModal();
  };

  const renderRegisterSuccessModal = (e) => {
    return (
      <Modal
        isOpen={showAttentionModal}
        className="modal-dialog-centered modal-dialog_custome attendance_modal "
      >
        <ModalBody className="modal-body w-100  text-center mb-3 ">
          <p className="bld_secnd_title mt-3">
            {lbl
              ? lbl.checkInConfirmation
              : "Are you sure you want to check-in your attention for class on"}{" "}
            <span>{checkDate}</span>
          </p>

          <div className="row pt-5 pb-2 w-100">
            <div className="col-6 float-right">
              <button
                type="button"
                className="btn btn-no"
                onClick={SubmitAttetion}
              >
                {lbl ? lbl.no : "No"}
              </button>
            </div>
            <div className="col-6 float-left">
              <button
                type="button"
                className="btn btn-yes"
                onClick={() => onSubmitData(e)}
              >
                {lbl ? lbl.yes : "Yes"}
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  };
  const AttendanceModal = () => {
    return (
      <Modal
        isOpen={showModal}
        className="modal-dialog-centered attendance_modal_custome"
      >
        <ModalBody className="modal-body w-100 text-center modal_bgColor attendance_modal ">
          <span
            className="close_btn cust_mr"
            onClick={(e) => closeDataModal(e)}
          >
            x
          </span>
          <section className="setup_edu_detail">
            {coachtype === 2 ? (
              <div className="container">
                <div className="row">
                  <div className="col-md-12 mt-4">
                    <InputField
                      inputType="text"
                      placeholder={lbl ? lbl.eventDate : "Event date"}
                      errorValue={error_checkDate}
                      disable={loader}
                      defaultValue={checkDate || ""}
                      readOnly
                    />
                    <i className="far fa-calendar Tg_calander color12"></i>
                  </div>
                  <div className="col-md-12 mt-5">
                    <textarea
                      placeholder={
                        lbl ? lbl.writeComment : "write a comment here"
                      }
                      rows="5"
                      onChange={(e) => onFieldChange(e)}
                      defaultValue={comment}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12 mt-4 mb-3">
                  <button
                    className="blue_fill_btn w-100 float-left"
                    onClick={(e) => setShowAttentionModal(true)}
                  >
                    {lbl ? lbl.submit : "Submit"}
                  </button>
                </div>
              </div>
            ) : (
              <div className="container">
                <div className="row">
                  <div className="col-md-12 mt-4">
                    <div className="col-md-12 mt-4">
                      <InputField
                        inputType="text"
                        placeholder={lbl ? lbl.eventDate : "Event date"}
                        errorValue={error_checkDate}
                        disable={loader}
                        defaultValue={checkDate || ""}
                        readOnly={true}
                      />
                      <i className="far fa-calendar Tg_calander color12"></i>
                    </div>
                  </div>
                  <div className="col-md-12 mt-4">
                    <select
                      className={classNames("form-select", {
                        "is-invalid":
                          error_Hours !== "" || error_Hours !== null,
                      })}
                      onChange={(e) => onSelectHours(e)}
                      value={Hours}
                    >
                      <option value={1}>1{lbl ? lbl.hour : "Hour"}</option>
                      <option value={2}>2 {lbl ? lbl.hours : "Hours"}</option>
                      <option value={3}>3 {lbl ? lbl.hours : "Hours"}</option>
                      <option value={4}>4 {lbl ? lbl.hours : "Hours"}</option>
                      <option value={5}>5 {lbl ? lbl.hours : "Hours"}</option>
                      <option value={6}>6 {lbl ? lbl.hours : "Hours"}</option>
                      <option value={7}>7 {lbl ? lbl.hours : "Hours"}</option>
                      <option value={8}>8 {lbl ? lbl.hours : "Hours"}</option>
                    </select>
                    {error_Hours !== "" || error_Hours !== null ? (
                      <span className="invalid-text">{error_Hours}</span>
                    ) : null}
                  </div>
                  <div className="col-md-12 mt-5">
                    <textarea
                      placeholder={
                        lbl ? lbl.writeComment : "write a comment here"
                      }
                      rows="5"
                      onChange={(e) => onFieldChange(e)}
                      defaultValue={comment}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12 mt-4 mb-3">
                  <button
                    className="blue_fill_btn w-100 float-left"
                    onClick={(e) => confirmCheckinData(e)}
                  >
                    {lbl ? lbl.submit : "Submit"}
                  </button>
                </div>
              </div>
            )}
          </section>
        </ModalBody>
      </Modal>
    );
  };

  return (
    <Fragment>
      <ToastifyContainer />
      {/* --------------- register success modal --------------- */}
      {renderRegisterSuccessModal()}
      {AttendanceModal()}
    </Fragment>
  );
};

CoachModal.propTypes = {
  createCheckinAttendanceCoachClass: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  createCheckinAttendanceCoachClass,
  getCourseClassSchedule,
})(CoachModal);
