import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "./defaultLayout.css";
import labels from "../labels.json";

const DefaultFooter = () => {
  const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

  const handleLink = () => {
    window.location.href = 'https://www.manlapinternational.com/'
  }

  const handleMailto = () => {
    window.location.href = "mailto:info@inter-academyhk.com"
  }

  return (
    <Fragment>
      <footer className="sec_mar_top pt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <ul>
                <li>
                  <Link to="/">{lbl ? lbl.home : "Home"}</Link>
                </li>
                <li>
                  <Link to="/about-us">{lbl ? lbl.aboutUs : "About Us"}</Link>
                </li>
                <li>
                  <Link to="/faq">{lbl ? lbl.faq : "FAQ"}</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">
                    {lbl ? lbl.privacyPolicy : "Privacy Policy"}
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us">
                    {lbl ? lbl.contactUs : "Contact Us"}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul>
                <li>
                  <Link to="#" onClick={handleLink}>
                    {lbl ? lbl.mainTitle : "INTER Academy HongKong"}
                  </Link>
                </li>
                <li>
                  <Link to="#" onClick={handleMailto}>
                    {lbl ? (
                      <Fragment>
                        <span className="me-1">{lbl.support}</span>
                        <span>:</span>
                      </Fragment>
                    ) : (
                      "Support "
                    )}{" "}
                    info@inter-academyhk.com
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    {lbl ? (
                      <Fragment>
                        <span className="">{lbl.questions}</span>
                        <span>:</span>
                      </Fragment>
                    ) : (
                      " Questions "
                    )}{" "}
                    +852 97026622
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <p className="footer_desc">
                {lbl
                  ? lbl.footerDesc
                  : "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."}
              </p>
            </div>
          </div>
        </div>
      </footer>

      <div className="scnd_footer text-center">
        <p>
          {/* {lbl
            ? lbl.allRightsReserved
            : "All rights reserved @ INTER Academy Hong Kong 2021"} */}
            {/* 版權所有 @ 香港國際米蘭青训学院 2023 | Man Lap International Sports Group Co.Limited */}
            版權所有 @ 香港國際米蘭青訓學院 2024 | Man Lap International Sports Group Co.Limited
        </p>
      </div>
    </Fragment>
  );
};
export default DefaultFooter;
