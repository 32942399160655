import React from "react";
import ContentLoader from "react-content-loader";

export const ColumnLoader = (props) => (
  <ContentLoader
    speed={1.5}
    width={80}
    height={300}
    viewBox="0 0 90 300"
    backgroundColor="#d0d5d8"
    foregroundColor="#fafafa"
    {...props}
  >
    <rect x="5" y="0" rx="3" ry="3" width="80" height="15" />
    <rect x="5" y="60" rx="3" ry="3" width="80" height="15" />
    <rect x="5" y="120" rx="3" ry="3" width="80" height="15" />
    <rect x="5" y="180" rx="3" ry="3" width="80" height="15" />
    <rect x="5" y="240" rx="3" ry="3" width="80" height="15" />
  </ContentLoader>
);

export const ButtonLoader = (props) => (
  <ContentLoader
    speed={2}
    width={80}
    height={300}
    viewBox="0 0 80 300"
    backgroundColor="#d0d5d8"
    foregroundColor="#fafafa"
    {...props}
  >
    <rect x="5" y="0" rx="3" ry="3" width="80" height="30" />
    <rect x="5" y="60" rx="3" ry="3" width="80" height="30" />
    <rect x="5" y="120" rx="3" ry="3" width="80" height="30" />
    <rect x="5" y="180" rx="3" ry="3" width="80" height="30" />
    <rect x="5" y="240" rx="3" ry="3" width="80" height="30" />
  </ContentLoader>
);

export const AvatarWithText = (props) => (
  <ContentLoader
    viewBox="0 0 400 100"
    backgroundColor="#d0d5d8"
    foregroundColor="#fafafa"
    height={160}
    width={400}
    {...props}
  >
    <rect x="70" y="21" rx="4" ry="4" width="100" height="15" />
    <circle cx="28" cy="28" r="28" />
  </ContentLoader>
);

export const InstaStories = (props) => {
  return (
    <ContentLoader
      viewBox="0 0 800 300"
      foregroundColor="#fafafa"
      backgroundColor="#d0d5d8"
      height={400}
      width={1100}
      {...props}
    >
      <rect x="80" y="0" rx="5" ry="5" width="200" height="450" />
      <rect x="350" y="0" rx="5" ry="5" width="200" height="450" />
    </ContentLoader>
  );
};