import React, { Fragment, useEffect } from "react";
import { getNotifications } from "../../../actions/notificationAction";
import DefaultHeader from "../../../DefaultLayout/DefaultHeader";
import { scrollToTop } from "../../../Helpers/ScrollToTop";
import "./notifications.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NotificationsLoader from "../../../components/ContentLoader/NotificationsLoader";
import labels from "../../../labels.json";

const Notification = (props) => {
  const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

  const { getNotifications, notification } = props;
  let { notifications, loading, notificationList } = notification;

  useEffect(() => {
    notification.notificationList = [];
    scrollToTop();
    getNotifications({ page: 1, limit: 10 });
  }, []);

  const onPageClick = (page) => {
    getNotifications({ page: page, limit: 10 });
  };

  const viewMore = (data) => {
    const { hasNextPage, nextPage } = data;

    return (
      <div className="w-100 ">
        <button
          hidden={hasNextPage === true ? false : true}
          onClick={hasNextPage === true ? () => onPageClick(nextPage) : null}
          className=" blue_fill_btn view-more-paginate-btn btn-sm "
        >
          {lbl ? lbl.viewMore : "View more..."}
        </button>
      </div>
    );
  };
  return (
    <Fragment>
      <DefaultHeader />
      <section className="edit_student_profile_main ">
        <div className="container firefox-display">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-9 mt-5 ">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="main_title">
                    {lbl ? lbl.notification : "Notifications"}
                  </h2>
                  <p className="title_para">
                    {lbl
                      ? lbl.notificationDesc
                      : "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."}
                  </p>
                </div>
                <div className="col-md-12 mt-4 notification_area">
                  <div className="stdnt_home_main_box mb-3">
                    {notifications.docs.length > 0 && !loading ? (
                      notificationList.map((notification, i) => (
                        <div className="row mb-2 mb-md-0" key={i}>
                          <div className="col-md-8 col-lg-9 mt-2 mb-1 mb-md-4">
                            <div class="peers_common_user_profile">
                              <span class="common_user_profile_img">
                                <img
                                  src={notification.image}
                                  alt="user"
                                  className="notification-img"
                                />
                              </span>
                            </div>

                            <div className="peers_common_user_profile_txt">
                              <p className="gnrl_para m-0">
                                {notification.message}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4 col-lg-3  notification_time">
                            At {notification.time ? notification.time : "-"} on{" "}
                            {notification.date ? notification.date : "-"}
                          </div>
                        </div>
                      ))
                    ) : loading ? (
                      <div>
                        <NotificationsLoader />
                      </div>
                    ) : notifications.docs.length === 0 && !loading ? (
                      <div className="row mb-2 mb-md-0">{lbl
                          ? lbl.notNotifications
                          : "There is no latest news at the moment."}
                      </div>
                    ) : null}
                  </div>
                  {viewMore(notifications)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

Notification.propTypes = {
  getNotifications: PropTypes.func.isRequired,
  notification: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  notification: state.notification,
});
export default connect(mapStateToProps, {
  getNotifications,
})(Notification);
