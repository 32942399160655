import {
  GET_AVAILABLE_LEAVE_BALANCE,
  GET_CLASSES_FROM_COURSE,
  GET_MY_LEAVE_HISTORY,
} from "../actions/types";

const initialState = {
  leaveHistory: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  leaveHistoryLoading: true,
  leaveBalance: [],
  leaveBalanceLoading: true,
  classList: [],
  classListLoading: true,
};

/**
 * Reducer Called for state management for the Course List.
 * @param {*} [state=initialState] initail state of the list.
 * @param {*} action State Update.
 * @return {*}
 */
const myLeavesReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case GET_MY_LEAVE_HISTORY:
      return {
        ...state,
        leaveHistory: payload,
        leaveHistoryLoading: false,
      };

    case GET_AVAILABLE_LEAVE_BALANCE:
      return {
        ...state,
        leaveBalance: payload,
        leaveBalanceLoading: false,
      };

    case GET_CLASSES_FROM_COURSE:
      return {
        ...state,
        classList: payload,
        classListLoading: false,
      };

    default:
      return state;
  }
};
export default myLeavesReducer;
