import {
  ALL_COURSE_LIST,
  ALL_COURSE_DETAILS,
  COURSE_STUDENT_GIFT,
} from "../actions/types";

const initialState = {
  getAllCourseList: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  myListedCourses: [],
  getStudentGiftList: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  myGiftListing:[],
  Loading: true,
  courseDetails: {},
};

/**
 * Reducer Called for state management for the Course List.
 * @param {*} [state=initialState] initail state of the list.
 * @param {*} action State Update.
 * @return {*}
 */
const all_courseList = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case ALL_COURSE_LIST:
      return {
        ...state,
        getAllCourseList: payload,
        myListedCourses: state.myListedCourses.concat(payload.docs),
        Loading: false,
      };
    case ALL_COURSE_DETAILS:
      return {
        ...state,
        courseDetails: payload,
        Loading: false,
      };
    case COURSE_STUDENT_GIFT:
      return {
        ...state,
        getStudentGiftList: payload,
        myGiftListing: state.myGiftListing.concat(payload.docs),
        Loading: false,
      };
    default:
      return state;
  }
};
export default all_courseList;
