import { GET_ALL_COURSES, GET_COACH_GIFTS } from "../actions/types";

const initialState = {
  coachGifts: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  giftLoading: true,
  allCourses: [],
  allCourseLoading: true,
};

/**
 * Reducer Called for state management for the Course List.
 * @param {*} [state=initialState] initail state of the list.
 * @param {*} action State Update.
 * @return {*}
 */
const coachGiftsReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case GET_COACH_GIFTS:
      return {
        ...state,
        coachGifts: payload,
        giftLoading: false,
      };
    case GET_ALL_COURSES:
      return {
        ...state,
        allCourses: payload,
        allCourseLoading: false,
      };
    default:
      return state;
  }
};
export default coachGiftsReducer;
