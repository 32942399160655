const BaseURL = process.env.REACT_APP_API_URL + "api/";

export const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const User_Registration = `${BaseURL}user/signup`; // enter user details.
export const User_Login = `${BaseURL}user/login`; // email,password, role.
export const Load_User = `${BaseURL}user/auth`; // pass token.
export const reset_password = `${BaseURL}user/change-password`; // email token,new pass and conf new pass.
export const forgot_password = `${BaseURL}user/reset-password`; // enter email.
export const verify_password_reset = `${BaseURL}user/verifyToken`; // verify reset-password-email-token.
export const verify_registered_email = `${BaseURL}user/verify-email`; // verify email on registration.
export const Update_User_Profile = `${BaseURL}user/`; // profile details object.
export const Verify_Mobile_Number = `${BaseURL}user/verify/mobile/number`; // Mobile number and token.
export const Update_Mobile_Number = `${BaseURL}user/mobile/number/update`; // Mobile number and token.
export const get_banner_img = `${BaseURL}course/website/banner`; // homepage banner img.
export const all_course_list = `${BaseURL}course/website`; // course list for student.
export const Change_User_Password = `${BaseURL}user/new-password`;
export const all_course_list_coach = `${BaseURL}coach/website/course`;
export const Student_Payment_History = `${BaseURL}user/payment/history`;
export const Create_Payment_History_PDF = `${BaseURL}user/payment/history/pdf`;
export const course_payment = `${BaseURL}course/website/booking`; // course payment for student.
export const Get_My_Enrollments = `${BaseURL}course/website/student/enrollment`; // course payment for student.
export const Get_Student_Attendence = `${BaseURL}course/website/student/attendance`;
export const Add_Payment_Comment = `${BaseURL}course/website/student/payment/comment`;
export const class_scheduled = `${BaseURL}coach/website/course/class/schedule`; // course class scheduled.
export const Available_Leave_Balance = `${BaseURL}course/website/student/course`; // course class scheduled.
export const Get_My_Leave_History = `${BaseURL}course/website/leave/history`;
export const Apply_For_Leave = `${BaseURL}course/website/apply/leave`; // course class scheduled.
export const Course_Class_List = `${BaseURL}course/website/class/list?course=`; // course class scheduled.
export const class_scheduled_checkin = `${BaseURL}coach/website/course/coach/attendance`; // course class scheduled check in.
export const course_student_req_list = `${BaseURL}coach/website/course/student/list`; // course student req list for attendnace.
export const course_student_attendance = `${BaseURL}coach/website/course/student/attendance`; // course student attendnace.
export const course_student_gifts = `${BaseURL}course/website/student/gift`; // course student gifts assigned.
export const Get_All_Courses = `${BaseURL}coach/website/course?type=2`; // Get All courses without pagination.
export const Get_Coach_Gifts = `${BaseURL}coach/website/course/student/gift`; // Get All courses without pagination.
export const Get_NotSend_Gifts = `${BaseURL}coach/website/course/student/notSendGifts`; // 获取未派送的礼物
export const Assign_Gift = `${BaseURL}coach/website/course/student/gift`; // Get All courses without pagination.
export const Get_Notifications = `${BaseURL}user/notification`; // Get Notifications.
export const Get_Cms_Details = `${BaseURL}cms/website/`; // Get CMS details.
export const Get_Faq_Details = `${BaseURL}faq/website/get`; // Get FAQ details.
export const Send_Inquiry = `${BaseURL}user/send/inquiry`; // Get FAQ details.
