import React, { Fragment } from "react";
import classnames from "classnames";

const InputField = (inputProps) => {
  const {
    inputType,
    placeHolder,
    errorValue,
    disable,
    onInputChange,
    value,
    keyDown,
    defaultValue,
    readOnly,
    click,
    inputName
  } = inputProps;

  return (
    <Fragment>
      <input
        type={inputType}
        placeholder={placeHolder}
        name={inputName}
        className={classnames(
          { invalid: errorValue.length > 0 },
          { valid: errorValue.length === 0 }
        )}
        disabled={disable}
        onChange={onInputChange}
        value={value}
        defaultValue={defaultValue}
        onKeyDown={keyDown}
        readOnly={readOnly}
        onClick={click}
      />
      {errorValue ? <span className="invalid-text">{errorValue}</span> : null}
    </Fragment>
  );
};
export default InputField;
