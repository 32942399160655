import labels from "../labels.json";
const lbl = labels[process.env.REACT_APP_LABEL_TYPE];

export const checkRequiredValidationWithMinMax = (
  text,
  fieldName,
  min,
  max,
  required = true
) => {
  let error = "";
  if (required === true) {
    if (text === "") {
      if (lbl && lbl.isRequired) {
        error = fieldName + lbl.isRequired;
      } else {
        error = fieldName + " is required";
      }
      return error;
    }
  }
  if (text.length < min) {
    if (lbl && lbl.mustMinimum && lbl.characters) {
      error = fieldName + lbl.mustMinimum + min + lbl.characters;
    } else {
      error = fieldName + "must be minimum " + min + " characters";
    }
  }
  if (text.length > max) {
    if (lbl && lbl.mustMaximum && lbl.characters) {
      error = fieldName + lbl.mustMaximum + min + lbl.characters;
    } else {
      error = fieldName + "must be maximum " + max + " characters";
    }
  }
  return error;
};

export const checkEmptyValidation = (field, fieldName) => {
  let error = "";
  if (field === "") {
    if (lbl && lbl.isRequired) {
      error = fieldName + lbl.isRequired;
    } else {
      error = fieldName + " is required";
    }
  }
  return error;
};

export const checkEmailValidation = (emailText, required = true) => {
  let error = "";
  if (required === true) {
    if (emailText === "") {
      if (lbl && lbl.emailIsRequired) {
        return (error = lbl.emailIsRequired);
      } else {
        return (error = "Email is required");
      }
    }
  }
  const pattern =
    /^[a-zA-Z0-9_\-]+(\.[a-zA-Z0-9_]+)*@[a-z0-9]+([a-z0-9]+)*(\.[a-z0-9]+([a-z0-9]+)*)*\.[a-z]{2,4}$/;

    console.log(pattern.test(emailText))
  if (pattern.test(emailText)) {
    return "";
  } else {
    if (lbl && lbl.enterValidEmail) {
      error = lbl.enterValidEmail;
    } else {
      error = "Please enter a valid email address";
    }
  }

  return error;
};

export const checkMultipleChekboxSelectionWithMinMax = (
  array,
  min = 0,
  max = 10000000
) => {
  let ids = [];
  array.forEach((element, index) => {
    if (element === true) {
      ids.push(index);
    }
  });
  let response = {};
  response.error = "";
  response.data = ids;
  if (ids.length < min) {
    response.error = "Minimum " + min + " item selections is required";
  }

  if (ids.length > max) {
    response.error = "Maximum item selections is " + min;
  }
  return response;
};

export const checkDropdownValidation = (field, fieldName) => {
  let error = "";

  if (field === "") {
    if (lbl && lbl.pleaseSelect) {
      error = lbl.pleaseSelect + fieldName;
    } else {
      error = "Please select " + fieldName;
    }
  }
  return error;
};

export const checkMobileNumberValidation = (field) => {
  let error = "";
  if (field === "") {
    if (lbl && lbl.fieldIsRequired) {
      error = lbl.fieldIsRequired;
    } else {
      error = "This field is required";
    }
  }
  field = parseInt(field);
  if (!Number.isInteger(field)) {
    if (lbl && lbl.mustBeNumber) {
      error = lbl.mustBeNumber;
    } else {
      error = "This field must be a number";
    }
  }
  return error;
};
