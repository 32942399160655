export const isEmpty = (data) => {
    if (
        JSON.stringify(data) === '{}'
        || JSON.stringify(data) === '[]'
        || data === null
        || data === undefined
        || data === ''
    ) {
        return true
    }
    return false
}