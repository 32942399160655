import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADING_SUCCESS,
  USER_LOADING_ERROR,
  USER_LOGOUT,
} from "../actions/types";
import axios from "axios";
import {
  config,
  forgot_password,
  reset_password,
  User_Login,
  Load_User,
  User_Registration,
  verify_password_reset,
  verify_registered_email,
  Update_User_Profile,
  get_banner_img,
  Change_User_Password,
  Verify_Mobile_Number,
  Update_Mobile_Number,
} from "../api/index";
import setAuthToken from "../Helpers/setAuthToken";

// User Login (student/coach)
export const userLogin = (userObj) => async (dispatch) => {
  const body = JSON.stringify(userObj);
  const res = await axios.post(User_Login, body, config);
  try {
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    return res;
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
    });
    return error;
  }
};

// very reset password
export const verifyResetPassword = (code) => async (dispatch) => {
  const body = JSON.stringify(code);
  return axios.post(verify_password_reset, body, config);
};

// very the email for reset password
export const verifyEmail = (code) => async (dispatch) => {
  const body = JSON.stringify(code);
  return axios.post(verify_registered_email, body, config);
};

// User Registration
export const userRegistration = (user_obj) => async (dispatch) => {
  const body = JSON.stringify(user_obj);
  return axios.post(User_Registration, body, config);
};

export const forgotPassword = (email) => async (dispatch) => {
  const body = JSON.stringify(email);
  return axios.post(forgot_password, body, config);
};

// reset password
export const resetPassword = (pass_obj) => async (dispatch) => {
  const body = JSON.stringify(pass_obj);
  return axios.post(reset_password, body, config);
};

// Load User details (student/coach)
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.patch(Load_User);
    dispatch({
      type: USER_LOADING_SUCCESS,
      payload: res.data,
    });
    return res;
  } catch (error) {
    dispatch({
      type: USER_LOADING_ERROR,
    });

    return error;
  }
};

// User Logout
export const logout = (history) => async (dispatch) => {
  dispatch({
    type: USER_LOGOUT,
  });
};

// Update User details (student/coach)
export const updateUserProfile = (_id, userObj) => async (dispatch) => {
  try {
    return axios.put(Update_User_Profile + _id, userObj);
  } catch (error) {
    return error;
  }
};

// verify user mobile number with token id (student/coach)
export const verifyUserMobileNumber = (data) => async (dispatch) => {
  const body = JSON.stringify(data);
  return axios.post(Verify_Mobile_Number, body, config);
};

// Update user mobile number with token id (student/coach)
export const updateUserMobileNumber = (data) => async (dispatch) => {
  const body = JSON.stringify(data);
  return axios.put(Update_Mobile_Number, body, config);
};

// Change User password (student/coach)
export const changePassword = (passwordObj) => async (dispatch) => {
  const body = JSON.stringify(passwordObj);
  try {
    return axios.post(Change_User_Password, body, config);
  } catch (error) {
    return error;
  }
};

// get banner images for homepage
export const getBannerImages = () => async (dispatch) => {
  try {
    return axios.get(get_banner_img);
  } catch (error) {
    return error;
  }
};
